import React, { Component } from 'react';
import MultiPlotsContainer from 'components/plots/container/MultiPlotsContainer';
import CancelableFetchPromise from 'js/CancelablePromise';
import PollutionLevelExplanation from 'components/info/pollution-level-explanation/PollutionLevelExplanation';
import Localization, { setLanguage } from 'languages/components/plots/PlotsLan';

import './PlotSwitcher.scss';

class PlotsSwitcherWeatherStationMunicipality extends Component {
  constructor(props) {
    super(props);

    this.language = setLanguage();
    this.state = {
      data2DaysIsLoading: true,
      show: '2d',
      data2Days: null,
      data14Days: null,
      data14DaysIsLoading: true,
      data7Days: null,
      data7DaysIsLoading: true,
    };

    this.taskFetch2Days = null;
    this.taskFetch14Days = null;
    this.taskFetch7Days = null;
  }

  componentDidMount() {
    this.loadPlots();
  }

  componentWillUnmount() {
    if (this.taskFetch2Days) {
      this.taskFetch2Days.cancel();
    }
    if (this.taskFetch14Days) {
      this.taskFetch14Days.cancel();
    }
    if (this.taskFetch7Days) {
      this.taskFetch7Days.cancel();
    }
  }

  async loadPlots() {
    try {
      this.taskFetch2Days = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/measuringstation/municipality/${this.props.municipality}/48h`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));

      const json2Days = (await this.taskFetch2Days.promise).body;

      this.setState({
        data2Days: json2Days,
        data2DaysIsLoading: false,
      });
    } catch (e) {
      // TBD handle
    }


    try {
      this.taskFetch14Days = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/measuringstation/municipality/${this.props.municipality}/14d`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));

      const json14Days = (await this.taskFetch14Days.promise).body;
      this.setState({
        data14Days: json14Days,
        data14DaysIsLoading: false,
      });
    } catch (e) {
      // TBD handle
    }
    try {
      this.taskFetch7Days = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/measuringstation/municipality/${this.props.municipality}/7d`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));

      const json7Days = (await this.taskFetch7Days.promise).body;
      this.setState({
        data7Days: json7Days,
        data7DaysIsLoading: false,
      });
    } catch (e) {
      // TBD handle
    }
  }

  render() {
    let isLoading = false;
    let plotsInfo;


    let type;
    if (this.state.show === '2d') {
      isLoading = this.state.data2DaysIsLoading;
      plotsInfo = this.state.data2Days;
      type = 'ws2';
    } else if (this.state.show === '14d') {
      isLoading = this.state.data14DaysIsLoading;
      plotsInfo = this.state.data14Days;
      type = 'ws14';
    } else if (this.state.show === '7d') {
      isLoading = this.state.data7DaysIsLoading;
      plotsInfo = this.state.data7Days;
      type = 'ws7';
    }

    const groupedComponents = {};

    if (plotsInfo) {
      const f = plotsInfo.map(p => p.componentValues.map(c => ({ ...c, station: p.station }))).reduce((a, b) => (a.concat(b)), []);
      f.map(c => (groupedComponents[c.component] ? groupedComponents[c.component]  : (groupedComponents[c.component] = [])).push(c));
      // groupedComponents = { 'PM2.5': groupedComponents['PM2.5'] };
      //      console.log(groupedComponents);
      //    groupedComponents.PM10[0].values[2].value = 232;
    }
    const twoDaysButton = <button type="button" className={`button ${this.state.show === '2d' ? 'active' : ''}`} onClick={() => this.setState({ show: '2d' })}>{Localization.last2Days}</button>;
    const sevenDaysButton = <button type="button" className={`button ${this.state.show === '7d' ? 'active' : ''}`} onClick={() => this.setState({ show: '7d' })}>{Localization.last7Days}</button>;
    const fourteenDaysButton = <button type="button" className={`button ${this.state.show === '14d' ? 'active' : ''}`} onClick={() => this.setState({ show: '14d' })}>{Localization.last14Days}</button>;


    return (
      <div className="c_plot-switcher">
        <div className="plot-switcher__buttons tab-buttons">
          {twoDaysButton}
          {sevenDaysButton}
          {fourteenDaysButton}
        </div>

        <div>
          <MultiPlotsContainer station={this.props.municipality} isLoading={isLoading} plotsInfo={groupedComponents} plotType="multiline" type={type} />
        </div>
      </div>
    );
  }
}

export default PlotsSwitcherWeatherStationMunicipality;
