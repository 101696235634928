import React from 'react';

import './ArticleList.scss';

const ArticleList = props => (
  <div className="c_article-list" itemScope itemType="http://schema.org/ItemList">
    <h2>{props.header}</h2>
    <ul>
      {props.children}
    </ul>
  </div>
);

export default ArticleList;
