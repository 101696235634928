import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    mapPlotSwitcherActivateButton: 'Aktiver kart',
    mapPlotSwitcherLockButton: 'Lås kart',
    mapPlotSwitcherPlot: 'GRAF - Varsel',
    mapPlotSwitcherMap: 'KART - Varsel',
    mapPlotSwitcherPlotSingleText: 'GRAF',
    mapPlotSwitcherMapSingleText: 'KART',
    mapPlotSwitcherMeasuringStationsMap: 'MÅLESTASJONER',
    mapPlotSwitcherMeasuringStationsMapSingleText: 'MÅLESTASJONER',
    mapPlotSwitcherScreenReader: 'Kart og graf over varslet luftforurensning',
    mapPlotSwitcherPollution: 'Luftforurensning',
    mapPlotSwitcherMapChoice: 'Flere kartvalg',
    filterMenuMeasureTitle: 'Varslet luftforurensning (beregnet)',
    filterMenuMeasurePollution: 'Målt luftforurensning (siste time)',
    filterMenuStations: 'Målinger siste time',
    FilterMenuNoWarnedWeather: 'For øyeblikket er ikke Varslet luftforurensning (beregnet) tilgjengelig. Vi beklager dette, prøv igjen senere.',
    filterMenuCameras: 'Kamera',
    mapHeaderBack: 'Tilbake',
    showExplanationErrorHeader: 'Feil',
    showExplanationErrorText: 'Kunne ikke hente forurensningsklasser.',
    showExplanationShowText: 'Vis forklaring',
    showExplanationPollutionClasses: 'Forurensningsklasser',
    showExplanationMeasuredConcentration: 'Beregnet konsentrasjon av',
    showExplanationNoData: 'Ingen data',
    showExplanationClose: 'Lukk forklaring',
    timeSliderSunday: 'Søndag',
    timeSliderMonday: 'Mandag',
    timeSliderTuesday: 'Tirsdag',
    timeSliderWednesday: 'Onsdag',
    timeSliderThursday: 'Torsdag',
    timeSliderFriday: 'Fredag',
    timeSliderSaturday: 'Lørdag',
    timesliderName: 'Tidslinje',
    mapErrorHeader: 'Feil',
    mapErrorMessage: 'Kunne ikke hente kartdata.',
    healthArticleText: 'Les mer om helseråd og forurensningsklasser',
    togglePanelsText: 'Paneler',
    togglePanelsShowText: 'Vis',
    togglePanelsHideText: 'Skjul',
  },
  en: {
    mapPlotSwitcherActivateButton: 'Activate map',
    mapPlotSwitcherLockButton: 'Lock map',
    mapPlotSwitcherPlot: 'GRAPH - Forecast',
    mapPlotSwitcherMap: 'MAP - Forecast',
    mapPlotSwitcherPlotSingleText: 'GRAPH',
    mapPlotSwitcherMapSingleText: 'MAP',
    mapPlotSwitcherMeasuringStationsMap: 'MEASUREMENT STATIONS',
    mapPlotSwitcherMeasuringStationsMapSingleText: 'STATIONS',
    mapPlotSwitcherScreenReader: 'Map and graph of pollution forecast',
    mapPlotSwitcherPollution: 'Air pollution',
    mapPlotSwitcherMapChoice: 'More maps',
    filterMenuMeasureTitle: 'Pollution Forecast (modelled)',
    filterMenuMeasurePollution: 'Measured Air Pollution (last hour)',
    filterMenuStations: 'Measurements last hour',
    FilterMenuNoWarnedWeather: 'There is no pollution forecast (modelled) available at the moment. Please try again later.',
    filterMenuCameras: 'Cameras',
    mapHeaderBack: 'Back',
    showExplanationErrorHeader: 'Error',
    showExplanationErrorText: 'Could not retrieve pollution classes.',
    showExplanationShowText: 'Show legend',
    showExplanationPollutionClasses: 'Pollution classes',
    showExplanationMeasuredConcentration: 'Modelled concentration of',
    showExplanationNoData: 'No data',
    showExplanationClose: 'Close explanation',
    timeSliderSunday: 'Sunday',
    timeSliderMonday: 'Monday',
    timeSliderTuesday: 'Tuesday',
    timeSliderWednesday: 'Wednesday',
    timeSliderThursday: 'Thursday',
    timeSliderFriday: 'Friday',
    timeSliderSaturday: 'Saturday',
    timesliderName: 'Timeslider',
    mapErrorHeader: 'Error',
    mapErrorMessage: 'Could not load map.',
    healthArticleText: 'Read more about health advice and pollution classes.',
    togglePanelsText: 'Panels',
    togglePanelsShowText: 'Show',
    togglePanelsHideText: 'Hide',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
