import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  "no-nb": {
    buttonBack: 'Tilbake',
    smallHeading: 'Målt luftforurensning for:',
    weatherStationTitle: '{0} målestasjon, {1}',
    measureTitle: 'Målt luftforurensning',
    stationsNearby: 'Målestasjoner i nærheten',
  },
  en: {
    buttonBack: 'Back',
    smallHeading: 'Measured pollution for:',
    weatherStationTitle: '{0} measuring station, {1}',
    measureTitle: 'Measured air pollution',
    stationsNearby: 'Measurement stations nearby',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
