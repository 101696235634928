import React from 'react';
// import { Link } from 'react-router-dom';
import LuftkvalitetLogo from 'components/elements/header/luftkvalitet-logo/LuftkvalitetLogo';
import Localization, { setLanguage } from 'languages/components/elements/ElementsLan';
import { setCookieLanguage, getCookieLanguage } from 'js/CookieHandler';
import MainMenu from 'components/elements/main-menu/MainMenu';
import './Header.scss';

const Header = (props) => {
  setLanguage();
  const lan = getCookieLanguage();
  return (
    <header className="c_header">
      <div className="g_center-content">
        <LuftkvalitetLogo />
        <MainMenu />
      </div>
    </header>
  );
};

export default Header;
