import React from 'react';
import ButtonLink from 'components/elements/button/button-link/ButtonLink';
import ButtonInfo from 'components/elements/button/button-info/ButtonInfo';
import './Buttons.scss';
import ButtonSmall from 'components/elements/button/button-small/ButtonSmall';
import ButtonPrimary from 'components/elements/button/button-primary/ButtonPrimary';
import ButtonReturn from 'components/elements/button/button-return/ButtonReturn';
import ButtonText from 'components/elements/button/button-text/ButtonText';
import ButtonDelete from 'components/elements/button/button-delete/ButtonDelete';
import ButtonCopy from 'components/elements/button/button-copy/ButtonCopy';
import ShowHidePanels from 'components/map/ol-controls/show-hide-panels/ShowHidePanels';

const Colors = () => (
  <div className="c_styleguide__item c_styleguide__item--buttons">
    <h2>Knapper</h2>
    <div className="buttons__button">
      <h3>Linkknapp</h3>
      <p>Brukes når linken tar bruker vekk fra nåværende sted.</p>
      <h4>Vanlig state</h4>
      <ButtonLink url="/">Gå til forsiden</ButtonLink>
      <h4>Focus state</h4>
      <ButtonLink extraClass="button--link--focus" url="/">Gå til forsiden</ButtonLink>
      <h4>Hover state</h4>
      <p>(I tillegg endres ikon på musepeker når han holder over knappen)</p>
      <ButtonLink extraClass="button--link--hover" url="/">Gå til forsiden</ButtonLink>
    </div>

    <div className="buttons__button">
      <h3>Hovedknapp</h3>
      <p>Brukes til hovedaktiviteter.</p>
      <h4>Vanlig state</h4>
      <ButtonPrimary>Lagre varsel</ButtonPrimary>
      <h4>Focus state</h4>
      <ButtonPrimary extraClass="button--primary--focus">Lagre varsel</ButtonPrimary>
      <h4>Hover state</h4>
      <p>(I tillegg endres ikon på musepeker når han holder over knappen)</p>
      <ButtonPrimary extraClass="button--primary--hover">Lagre varsel</ButtonPrimary>
    </div>

    <div className="buttons__button">
      <h3>Tilbakeknapp</h3>
      <p>Brukes for å ta bruker tilbake et steg.</p>
      <h4>Vanlig state</h4>
      <ButtonReturn>Tilbake</ButtonReturn>
      <h4>Focus state</h4>
      <ButtonReturn extraClass="button--return--focus">Tilbake</ButtonReturn>
      <h4>Hover state</h4>
      <p>(I tillegg endres ikon på musepeker når han holder over knappen)</p>
      <ButtonReturn extraClass="button--return--hover">Tilbake</ButtonReturn>
    </div>

    <div className="buttons__button">
      <h3>Fane-knapper</h3>
      <p>Brukes for å velge ulike faner, blant annet på landingsside for sted (velge mellom grafer og kart). Her har den første knappen active state. En av knappene vil alltid ha "valgt" status, og </p>
      <div className="small-buttons">
        <h4>Vanlig state</h4>
        <ButtonSmall
          active="true"
        >
          Valgt
        </ButtonSmall>
        <ButtonSmall>
          Vanlig
        </ButtonSmall>
      </div>

      <div className="small-buttons">
        <h4>Fokus state</h4>
        <ButtonSmall
          active="true"
          extraClass="button--small--focus"
        >
          Valgt
        </ButtonSmall>
        <ButtonSmall
          extraClass="button--small--focus"
        >
          Vanlig
        </ButtonSmall>
      </div>

      <div className="small-buttons">
        <h4>Hover state</h4>
        <ButtonSmall
          active="true"
          extraClass="button--small--hover"
        >
          Valgt
        </ButtonSmall>
        <ButtonSmall
          extraClass="button--small--hover"
        >
          Vanlig
        </ButtonSmall>
      </div>
    </div>

    <div className="buttons__button">
      <h3>Tekstknapp</h3>
      <p>Denne knappen ser ut som en vanlig tekst, og brukes blant annet til mindre funksjoner som "Les mer" på automatisk varsling.</p>
      <h4>Vanlig state</h4>
      <ButtonText>Tekstknapp</ButtonText>
      <h4>Focus state</h4>
      <ButtonText extraClass="button--text--focus">Tekstknapp med focus</ButtonText>
      <h4>Hover state</h4>
      <ButtonText extraClass="button--text--hover">Tekstknapp med hover</ButtonText>
    </div>

    <div className="buttons__button">
      <h3>Sletteknapp</h3>
      <p>Brukes blant annet for å slette radene i tabellen over automatisk varsel på adminsiden.</p>
      <h4>Vanlig state</h4>
      <ButtonDelete />
      <h4>Focus state</h4>
      <ButtonDelete extraClass="button--delete--focus" />
      <h4>Hover state</h4>
      <p>(I tillegg endres ikon på musepeker når han holder over knappen)</p>
      <ButtonDelete extraClass="button--delete--hover" />
    </div>

    <div className="buttons__button">
      <h3>Kopierknapp</h3>
      <p>Brukes for å kopiere en rad (et varsel) i listen over automatisk varsling (inne på admindelen).</p>
      <h4>Vanlig state</h4>
      <ButtonCopy />
      <h4>Focus state</h4>
      <ButtonCopy extraClass="button--copy--focus" />
      <h4>Hover state</h4>
      <p>(I tillegg endres ikon på musepeker når han holder over knappen)</p>
      <ButtonCopy extraClass="button--copy--hover" />
    </div>

    <div className="buttons__button">
      <h3>Toggle knapper</h3>
      <p>Brukes på fullskjermskartet for å vise og skjule panelene.</p>
      <ShowHidePanels />
    </div>

  </div>
);

export default Colors;
