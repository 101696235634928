import React from 'react';
import HeadingWithLine from 'components/elements/heading-with-line/HeadingWithLine';
import MapPlotSwitcherMeasuringStation from 'components/map/map-plot-switcher/MapPlotSwitcherMeasuringStation';
import PlotsSwitcherWeatherStation from 'components/plots/plots-switcher/PlotsSwitcherWeatherStation';
import NearbyWeatherStations from 'components/weatherstation/nearby-weather-stations/NearbyWeatherStations';
import MeasuredAirPollution from 'components/weatherstation/tables/measured-air-pollution/MeasuredAirPollution';
import MostVisitedWeatherStations from 'components/weatherstation/most-visited-weather-stations/MostVisitedWeatherStations';
import WeatherStationStatus from 'components/weatherstation/weather-station-status/WeatherStationStatus';
import WeatherStationSource from 'components/weatherstation/weather-station-source/WeatherStationSource';
import LazySuspense from 'components/lazy/LazySuspense';

const OLMapWeatherstation = React.lazy(() =>
  import('components/map/ol-map/ol-map-weatherstation/OLMapWeatherstation'));

const WeatherStationInformation = (props) => {
  let measuredAirPollution;

  // Conditional rendering if 24h data is missing for a station
  if (!props.error24h) {
    measuredAirPollution = (
      <div className="g_center-content c_white-box">
        <h2>{props.localization.measureTitle}</h2>
        <MeasuredAirPollution data={props.data} />
        <WeatherStationSource />
      </div>
    );
  }

  return (
    <div>
      <div className="g_center-content">
        <HeadingWithLine smallHeading={props.localization.smallHeading}>
          {props.localization.formatString(
            props.localization.weatherStationTitle,
            props.station,
            props.area,
          )}
          <span>
            {' '}
(
            {props.municipality}
)
          </span>
        </HeadingWithLine>
        <WeatherStationStatus
          owner={props.owner}
          status={props.status}
          description={props.description}
        />
      </div>
      <div className="g_center-content">
        <MapPlotSwitcherMeasuringStation
          hideExplanation
          layer="stations_aqi"
          latitude={props.longLat[1]}
          longitude={props.longLat[0]}
          history={props.history}
        >
          <PlotsSwitcherWeatherStation
            station={props.station}
            hasUpdatedData={props.hasUpdatedData}
          />
          <LazySuspense>
            <OLMapWeatherstation
              history={props.history}
              longitude={props.longLat[0]}
              latitude={props.longLat[1]}
            />
          </LazySuspense>
        </MapPlotSwitcherMeasuringStation>
      </div>
      {measuredAirPollution}
      <div className="g_center-content c_white-box c_table-and-list">
        <h2>{props.localization.stationsNearby}</h2>
        <NearbyWeatherStations data={props.nearbystations} />
        <MostVisitedWeatherStations
          mostVisitedStations={props.mostVisitedStations}
        />
      </div>
    </div>
  );
};

export default WeatherStationInformation;
