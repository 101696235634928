import React from 'react';
import LazySuspense from "components/lazy/LazySuspense";
const Dashboard = React.lazy(() =>
  import('./Dashboard')
);
const DashboardContainer = props => (
    <LazySuspense>
    <Dashboard/>
  </LazySuspense>
);

export default DashboardContainer;
