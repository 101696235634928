const initialState = {
  text: 'Hi Redux',
};

export default function exampleReducer(state = initialState, action) {
  switch (action.type) {
    case 'DISPATCH_EXAMPLE_ACTION_SAGA':
      return Object.assign({}, state, { text: action.text });
    case 'DISPATCH_EXAMPLE_ACTION_FAILED':
      return Object.assign({}, state, { text: action.message });
    default:
      return state;
  }
}
