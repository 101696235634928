import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './NearbySubPlacesTable.scss';
import Localization, { setLanguage } from 'languages/components/forecast/ForecastLan';
import RestOfDayLabel from 'components/forecast/tables/two-day-air-quality/RestOfDayLabel';

class NearbySubPlacesTable extends Component {
  static createPlaceLink(place) {
    let url = `/varsling/${encodeURIComponent(place.fylke)}/${encodeURIComponent(place.kommune)}`;
    if (place.delomrade) {
      url += `/${encodeURIComponent(place.delomrade)}`;

      if (place.grunnkrets) {
        url += `/${encodeURIComponent(place.grunnkrets)}`;
      }
    }

    return url;
  }

  constructor(props) {
    super(props);
    this.language = setLanguage();

    this.state = {
      showRestOfDay: this.props.data.areas[0].forecast.restOfDay.startHour > 0,
    };
  }


  render() {
    const { startHour, endHour } = this.props.data.areas[0].forecast.restOfDay;

    return (
      <div className="c_nearby-subplaces" itemScope itemType="http://schema.org/Table">
        <meta itemProp="about" content={Localization.twoDayForecast} />
        <table className="table table--nearby-subplaces table--status" cellSpacing="0">
          <thead>
            <tr>
              <th>{Localization.nearbySubplaceTablePlaces}</th>
              <th>{Localization.nearbySubplaceTableType}</th>
              <th>{Localization.nearbySubplaceTableNow}</th>
              {this.state.showRestOfDay
                ? (
                  <RestOfDayLabel
                    language={this.language}
                    startHour={startHour}
                    endOfDay={endHour}
                  />
                )
                : ''
              }
              <th>{Localization.nearbySubplaceTableTomorrow}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data ? this.props.data.areas.map(place => (
              <tr key={place.name}>
                <td itemProp="contentLocation" itemScope itemType="http://schema.org/AdministrativeArea">
                  <Link to={NearbySubPlacesTable.createPlaceLink(place)} itemProp="name" content={place.name}>{place.name}</Link>
                </td>
                <td>{place.descLocalized}</td>
                <td className="table__status-indicator">
                  <span style={{ backgroundColor: place.forecast.today.color }} />
                  {`${place.forecast.today.text}`}
                </td>

                {this.state.showRestOfDay
                  ? (
                    <td className="table__status-indicator">
                      <span style={{ backgroundColor: place.forecast.restOfDay.color }} />
                      {`${place.forecast.restOfDay.text}`}
                    </td>
                  )
                  : ''
                }
                <td className="table__status-indicator">
                  <span style={{ backgroundColor: place.forecast.tomorrow.color }} />
                  {`${place.forecast.tomorrow.text}`}
                </td>
              </tr>
            ))
              : <tr><td>{Localization.nearbySubplaceTableNoData}</td></tr>
            }
          </tbody>
        </table>
      </div>
    );
  }
}
export default NearbySubPlacesTable;
