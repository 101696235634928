import React, { Component } from 'react';
import './Spinner.scss';
import Localization, { setLanguage } from '../../../languages/components/elements/ElementsLan';

class Spinner extends Component {
  constructor(props) {
    super(props);
    setLanguage();
  }

  render() {
    return <div className="Loader e_loader">{Localization.spinner}</div>;
  }
}


export default Spinner;
