import React, { Component } from 'react';

import Localization, { setLanguage } from 'languages/components/elements/ElementsLan';
import { setCookieLanguage, getCookieLanguage } from 'js/CookieHandler';
import LocStatic, { setLogoLanguage } from './LocalizedLogo';
import mdirLogo from './mdir-logo.png';
import './Logos.scss';

class Logos extends Component {
  constructor(props) {
    super(props);
    this.state = { languagePickerOpen: false };


    setLanguage();
    setLogoLanguage();
    this.toggleLanguagePicker = this.toggleLanguagePicker.bind(this);
  }

  toggleLanguagePicker() {
    this.setState(prevState => ({ languagePickerOpen: !prevState.languagePickerOpen }));
  }


  render() {
    const lan = getCookieLanguage();

    return (
      <div className="c_footer__logos">
        <span className="c_footer__logos__delivered-by">{Localization.logoDelivered}</span>
        <div className={`c_main-menu__language-picker e_dropdown-menu c_footer_logos_language ${this.state.languagePickerOpen ? 'e_dropdown-menu--open' : ''}`}>
          <button onClick={() => this.toggleLanguagePicker()} type="button" className="e_dropdown-menu__toggle choose-language">{Localization.chooseLanguage}</button>
          <ul className="e_dropdown-menu__options">
            <li>
              <button
                className={lan === 'no-nb' ? 'active' : ''}
                type="button"
                onClick={() => {
                  setCookieLanguage('no-nb');
                  window.location.reload();
                }}
              >
                Norsk
              </button>
            </li>
            <li>
              <button
                type="button"
                className={lan === 'en' ? 'active' : ''}
                onClick={() => {
                  setCookieLanguage('en');
                  window.location.reload();
                }}
              >
                English
              </button>
            </li>
          </ul>
        </div>


        <ul className="logos">
          {this.props.includePublisherMicrodata
            ? (
              <li className="logos__item logos__item--mdir" itemProp="publisher" itemScope itemType="http://schema.org/Organization">
                <meta itemProp="name" content="Miljødirektoratet" />
                <a itemProp="url" href="http://www.miljodirektoratet.no/" rel="noopener noreferrer" target="_blank" title="Gå til Miljødirektoratet sine nettsider">
                  <span itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                    <meta itemProp="url" content={mdirLogo} />
                    <img src={LocStatic.mdirLogo} url={mdirLogo} alt="Logo for Miljødirektoratet" />
                  </span>
                </a>
              </li>
            )
            : (
              <li className="logos__item logos__item--mdir">
                <a href="http://www.miljodirektoratet.no/" rel="noopener noreferrer" target="_blank" title="Gå til Miljødirektoratet sine nettsider">
                  <img src={LocStatic.mdirLogo} alt="Logo for Miljødirektoratet" />
                </a>
              </li>
            )
          }
          <li className="logos__item logos__item--met">
            <a href="http://met.no/" rel="noopener noreferrer" target="_blank" title="Gå til Meteorologisk institutt sine nettsider">
              <img src={LocStatic.metLogo} alt="Logo for Meteorologisk institutt" />
            </a>
          </li>
          <li className="logos__item logos__item--fhi">
            <a href="http://fhi.no/" rel="noopener noreferrer" target="_blank" title="Gå til Folkehelseinstituttet sine nettsider">
              <img src={LocStatic.fhiLogo} alt="Logo for Folkehelseinstituttet" />
            </a>
          </li>
          <li className="logos__item logos__item--statens-vegvesen">
            <a href="https://www.vegvesen.no/" rel="noopener noreferrer" target="_blank" title="Gå til Statens Vegvesen sine nettsider">
              <img src={LocStatic.vegvesenLogo} alt="Logo for Statens Vegvesen" />
            </a>
          </li>
          <li className="logos__item logos__item--helsedirektoratet">
            <a href="https://helsedirektoratet.no/" rel="noopener noreferrer" target="_blank" title="Gå til Helsedirektoratet sine nettsider">
              <img src={LocStatic.helsedirLogo} alt="Logo for Helsedirektoratet" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Logos;
