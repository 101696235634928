import React from 'react';

const RestOfDayLabel = (props) => {
  const { startHour, endOfDay, language } = props;
  if (language === 'en') {
    return (
      <th>
        {startHour}
        {':00 - '}
        {endOfDay}
        {':00'}
      </th>

    );
  }

  return (
    <th>
      {'Kl. '}
      {startHour}
      {' - '}
      {endOfDay}
    </th>
  );
};

export default RestOfDayLabel;
