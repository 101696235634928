import React, { Component } from 'react';
import './Styleguide.scss';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import Colors from 'components/styleguide/colors/Colors';
import Buttons from 'components/styleguide/buttons/Buttons';
import Breakpoints from 'components/styleguide/breakpoints/Breakpoints';
import Font from 'components/styleguide/font/Font';
import { trackPageView } from 'js/TrackPage.js';

class Styleguide extends Component {
  componentDidMount() {
    trackPageView('Screen / Styleguide', this.props.location.pathname);
  }

  render() {
    return (
      <div className="t_page-wrapper t_styleguide">
        <Header />
        <div className="g_center-content">
          <div className="intro-text c_styleguide__item">
            <h1>Styleguide</h1>
            <p>Dette er et dynamisk minibibliotek som viser elementer brukt på luftkvalitet.miljodirektoratet.no.</p>
          </div>
          <Font />
          <Buttons />
          <Colors />
          <Breakpoints />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Styleguide;
