import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    betaText: 'Dette er en testversjon der feil og mangler kan forekomme.',
    aqi: 'Samlet luftforurensning',
    logoDelivered: 'Levert av',
    logoCooperation: 'I samarbeid med',
    helseDir: 'Helse-direktoratet',
    header: 'Luftkvalitet i Norge',
    headerSplit1: 'Luft',
    headerSplit2: 'kvalitet',
    headerSplit3: 'i Norge',
    headerHtml: 'Luft<b>kvalitet</b> i Norge',
    toggleMenu: 'Meny',
    airMenuItem: 'Varsel',
    mapMenuItem: 'Kart',
    articlesMenuItem: 'Lær mer',
    measuringStationMenu: 'Målestasjoner',
    aboutMenuItem: 'Om tjenesten',
    chooseLanguage: 'Language',
    spinner: 'Laster inn...',
    slogan: 'Offentlig informasjon om lokal luftkvalitet',
  },
  en: {
    betaText: 'This is a test version. Errors are to be expected and some components may be missing.',
    aqi: 'Total air pollution',
    logoDelivered: 'Delivered by',
    logoCooperation: 'In cooperation with',
    helseDir: 'The Norwegian Directorate of Health',
    header: 'Air quality in Norway',
    headerSplit1: 'Air',
    headerSplit2: 'quality',
    headerSplit3: 'in Norway',
    headerHtml: 'Air<b>Quality</b> in Norway',

    toggleMenu: 'Menu',
    airMenuItem: 'Forecast',
    mapMenuItem: 'Map',
    articlesMenuItem: 'Learn more',
    aboutMenuItem: 'About the service',
    measuringStationMenu: 'Measuring stations',
    chooseLanguage: 'Språk',
    spinner: 'Loading...',
    slogan: 'Public information about local air quality',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
