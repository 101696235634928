import React from 'react';
import { Link } from 'react-router-dom';

import './NearbyWeatherStations.scss';
import Localization, { setLanguage } from '../../../languages/components/weatherstation/WeatherStationLan';
import ScreenLocalization, { setLanguage as setScreenLanguage } from '../../../languages/screens/forecast/ForecastLan';

const NearbyWeatherStations = (props) => {
  setLanguage();
  setScreenLanguage();
  return (
    <div className="c_nearby-weather-stations" itemScope itemType="http://schema.org/Table">
      <meta itemProp="about" content={ScreenLocalization.stationsNearbyMicrotag} />
      <table className="table table--nearby-weather-stations table--status" cellSpacing="0">
        <thead>
          <tr>
            <th>{Localization.nearbyPlaces}</th>
            <th>{Localization.nearbyType}</th>
            <th>{Localization.nearbyNow}</th>
          </tr>
        </thead>
        <tbody>
          {props.data ? props.data.map(station => (
            <tr key={station.station}>
              <td itemProp="contentLocation" itemScope itemType="http://schema.org/Place">
                <Link to={`/maalestasjon/${station.station}`} itemProp="name" content={station.station}>{station.station}</Link>
              </td>
              <td>{station.type}</td>
              <td className="table__status-indicator">
                <span style={{ backgroundColor: station.latestAQI.color }} />
                {station.latestAQI.text}
              </td>
            </tr>
          ))
            : <tr><td>{Localization.nearbyNoData}</td></tr>
          }
        </tbody>
      </table>
    </div>
  );
};
export default NearbyWeatherStations;
