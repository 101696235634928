import React, { Component } from 'react';
import SearchField from 'components/places/search/SearchField';
import { trackSPAEvent } from 'js/TrackPage.js';

class Search extends Component {
  constructor(props) {
    super(props);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getAutoComplete = this.getAutoComplete.bind(this);
    this.state = { suggestions: [], municipalities: [] };
    this.autoLock = null;
    this.autoLockDebounce = 500;
    this.count = Date.now();
    this.searchText = '';
  }

  componentWillUnmount() {
    clearTimeout(this.autoLock);
  }

  onInputChange(value) {
    if (value.length < 2) {
      clearTimeout(this.autoLock);
      this.autoLock = null;
      this.setState({ suggestions: [] });
      this.searchText = '';
      return;
    }

    if (this.autoLock) {
      clearTimeout(this.autoLock);
      this.autoLock = null;
    }

    this.searchText = value;
    this.autoLock = setTimeout(
      () => this.getAutoComplete(value, Date.now()), this.autoLockDebounce,
    );
  }


  onSearchClick(value) {
    if (this.props.search && typeof (this.props.search) === 'function') {
      this.setState({ suggestions: [] });
      this.props.search(value);
      trackSPAEvent('Search', 'Place', value.name, value);
    }
  }

  async getAutoComplete(search, count) {
    try {
      let res = await fetch(`${process.env.REACT_APP_API}/api/places/search/${search}?top=5`);
      res = await res.json();

      const list = [];
      // Push places
      res[0].hits.forEach((hit) => {
        list.push({
          name: hit.navn,
          type: hit.type,
          latitude: hit.latitude,
          longitude: hit.longitude,
          zone: hit.metZoomLevel,
        });
      });

      const listMunicipalities = [];
      // Push municipalities
      res[1].hits.forEach((hit) => {
        listMunicipalities.push({
          name: hit.navn,
          county: hit.fylke,
          type: hit.type,
          latitude: hit.latitude,
          longitude: hit.longitude,
          zone: hit.metZoomLevel,
        });
      });

      if (list.length === 0) {
        list.push({
          name: 'Fant ingen resultater',
          type: '',
        });
      }
      if (this.count < count && this.searchText.length > 1) {
        this.count = count;
        this.setState({ suggestions: list, municipalities: listMunicipalities });
        this.autoLock = null;
      }
    } catch (err) {
      // We ignore errors for now
    }
  }

  render() {
    return (
      <SearchField
        onSearchClick={this.onSearchClick}
        onInputChange={this.onInputChange}
        suggestions={this.state.suggestions}
        municipalities={this.state.municipalities}
      />
    );
  }
}

export default Search;
