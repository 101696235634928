import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    somePlaces: '(enkelte steder)',
    infoBubbleNoComponentInfo: 'Fant ikke komponent info.',
    infoBubbleReadMore: 'Les mer her',
    airPollutionBoxHeading: 'Varslet luftforurensning nå',
    airPollutionBoxReason: 'På grunn av: ',
    airPollutionBoxSource: 'Kilde: ',
    airPollutionBoxToggleTextShow: 'Vis ',
    airPollutionBoxToggleTextHide: 'Skjul ',
    airPollutionBoxToggleText: 'detaljerte helseråd',
    airPollutionBoxAdvice: 'Helseråd',
    airPollutionBoxPopulace: 'Befolkning generelt',
    airPollutionBoxAstma: 'Astmatikere og luftveisplager',
    airPollutionBoxHeart: 'Hjerte- og karsykdommer *',
    airPollutionBoxElderly: 'Eldre',
    airPollutionBoxPregnant: 'Gravide og barn',
    airPollutionInfoBoxSource: 'Varslingen er basert på en beregening fra Meterologisk Institutt.',
    mostVisitedPlaces: 'Mine mest besøkte steder',
    nearbySubplaceTablePlaces: 'Steder',
    nearbySubplaceTableType: 'Type',
    nearbySubplaceTableNow: 'Nå',
    nearbySubplaceTableTomorrow: 'I morgen',
    nearbySubplaceTableNoData: 'Fant ingen data.',
    twoDayPlaces: 'Steder',
    twoDayNow: 'Nå',
    twoDayTomorrow: 'I morgen',
    twoDayForecast: 'Varslet luftforurensning',
    twoDayForecastErrorHeader: 'Feil',
    twoDayForecastErrorText: 'Kunne ikke hente topp 10 byer.',
    twoDayForecastMap: 'Kartvisning',
    twoDayForecastExplanation: 'Luftkvaliteten varierer lokalt, også innenfor kommuner.',
    healthArticleText: 'Les mer om helseråd og forurensningsklasser',
    airPollutionBoxHeartExplanation: '* Dette rådet gjelder særlig for svevestøv (PM2,5)',
    seasaltNoHealthEffect: 'Sjøsalt har ingen kjente helseeffekter.',
    ComponentInfoText: {
      PM10: {
        header: 'Svevestøv (PM10 og PM2,5)',
        info: 'Svevestøv (eller partikler) omtales gjerne som PM (particulate matter) etterfulgt av et tall som indikerer størrelse på partiklene i mikrometer. PM2,5 er alle partikler som er mindre enn 2,5 μm, og PM10 er alle partikler som er mindre enn 10 μm. Det betyr at svevestøv som inngår i PM2,5 også inngår i betegnelsen PM10.',
      },
      PM25: {
        header: 'Svevestøv (PM10 og PM2,5)',
        info: 'Svevestøv (eller partikler) omtales gjerne som PM (particulate matter) etterfulgt av et tall som indikerer størrelse på partiklene i mikrometer. PM2.5 er alle partikler som er mindre enn 2,5 μm, og PM10 er alle partikler som er mindre enn 10 μm. Det betyr at svevestøv som inngår i PM2,5 også inngår i betegnelsen PM10.',
      },
      none: {
        header: 'Ukjent komponent',
        info: 'Fant ikke komponent årsak.',
      },
    },
    1: {
      helsevirkningerHeader: 'Lav helserisiko',
      helsevirkninger: 'Liten eller ingen risiko for helseeffekter.',
      radGenerelt: 'Utendørs aktivitet anbefales',
      radAstmaLuft: 'Utendørs aktivitet anbefales',
      radHjerteKar: 'Utendørs aktivitet anbefales',
      radEldre: 'Utendørs aktivitet anbefales',
      radGravidBarn: 'Utendørs aktivitet anbefales',
    },
    2: {
      helsevirkningerHeader: 'Moderat helserisiko',
      helsevirkninger: 'Helseeffekter kan forekomme hos enkelte astmatikere og personer med andre luftveissykdommer eller alvorlige hjertekarsykdommer. Friske personer vil sannsynligvis ikke ha helseeffekter.',
      radGenerelt: 'Utendørs aktivitet anbefales',
      radAstmaLuft: 'Utendørs aktivitet anbefales for de fleste. De som opplever forverring av luftveissymptomer bør vurdere å redusere fysisk aktivitet i områder med mye trafikk eller høye andre utslipp.',
      radHjerteKar: 'Utendørs aktivitet anbefales for de fleste. De som opplever forverring av symptomer bør vurdere å redusere fysisk aktivitet i områder med mye trafikk eller høye andre utslipp.',
      radEldre: 'Utendørs aktivitet anbefales for de fleste.  De som opplever forverring av symptomer i luftveiene eller hjerte- og karsystemet bør vurdere å redusere fysisk aktivitet i områder med mye trafikk eller høye andre utslipp.',
      radGravidBarn: 'Utendørs aktivitet anbefales',
    },
    3: {
      helsevirkningerHeader: 'Betydelig helserisiko',
      helsevirkninger: 'Helseeffekter forekommer hos astmatikere og personer med andre luftveissykdommer eller  hjertekarsykdommer. Luftveisirritasjoner og ubehag kan forekomme hos friske personer.',
      radGenerelt: 'Utendørs aktivitet anbefales vanligvis. Hvis du har symptomer som hoste eller sår hals bør du vurdere å redusere utendørs fysisk aktivitet i de mest forurensede områdene.',
      radAstmaLuft: 'De som har alvorlig luftveissykdom eller opplever forverring av sin astma bør redusere utendørs fysisk aktivitet og begrense oppholdstiden i de mest forurensede områdene.',
      radHjerteKar: 'De som har alvorlig hjerte- og karsykdom bør redusere utendørs fysisk aktivitet og begrense oppholdstiden de mest forurensede områdene.',
      radEldre: 'Utendørs aktivitet anbefales for mange. De med luftveis- eller hjerte- og karsykdom  bør redusere utendørs fysisk aktivitet og begrense oppholdstiden i de mest forurensede områdene.',
      radGravidBarn: 'Utendørs aktivitet anbefales for mange. Gravide og barn med luftveissymptomer bør begrense oppholdstiden i de mest forurensede områdene.',
    },
    4: {
      helsevirkningerHeader: 'Alvorlig helserisiko',
      helsevirkninger: 'Sårbare grupper i befolkningen er svært utsatte for helseeffekter. Luftveisirritasjoner og ubehag forekommer hos friske personer.',
      radGenerelt: 'Reduser utendørs fysisk aktivitet og begrens oppholdstiden i de mest forurensede områdene, spesielt hvis du har symptomer som hoste ellers sår hals.',
      radAstmaLuft: 'De med astma og andre luftveissykdommer bør ikke oppholde seg i de mest forurensede områdene.',
      radHjerteKar: 'De med hjerte- og karsykdom bør ikke oppholde seg i de mest forurensede områdene.',
      radEldre: 'Reduser utendørs fysisk aktivitet og begrens oppholdstiden  i de mest forurensede områdene.',
      radGravidBarn: 'Reduser utendørs fysisk aktivitet og begrens oppholdstiden i de mest forurensede områdene.',
    },
    manualForecastUrlText: 'Les mer',
  },


  en: {
    somePlaces: '(some places)',
    infoBubbleNoComponentInfo: 'Could not find component info.',
    infoBubbleReadMore: 'Read more here',
    airPollutionBoxHeading: 'Air pollution forecast now',
    airPollutionBoxReason: 'Due to: ',
    airPollutionBoxSource: 'Source: ',
    airPollutionBoxToggleTextShow: 'Show ',
    airPollutionBoxToggleTextHide: 'Hide ',
    airPollutionBoxToggleText: 'detailed health advice',
    airPollutionBoxAdvice: 'Health advice',
    airPollutionBoxPopulace: 'General populace',
    airPollutionBoxAstma: 'Asthma and other respiratory diseases',
    airPollutionBoxHeart: 'Cardiovascular diseases and diabetes *',
    airPollutionBoxElderly: 'The elderly',
    airPollutionBoxPregnant: 'Pregnant and children',
    airPollutionInfoBoxSource: 'The forecast is based on a measurement by the Norwegian Meteorological Institute',
    mostVisitedPlaces: 'My most visited places',
    nearbySubplaceTablePlaces: 'Places',
    nearbySubplaceTableType: 'Type',
    nearbySubplaceTableNow: 'Now',
    nearbySubplaceTableTomorrow: 'Tomorrow',
    nearbySubplaceTableNoData: 'Could not load data.',
    twoDayPlaces: 'Places',
    twoDayNow: 'Now',
    twoDayTomorrow: 'Tomorrow',
    twoDayForecast: 'Forecast',
    twoDayForecastErrorHeader: 'Error',
    twoDayForecastErrorText: 'Could not load top ten cities.',
    twoDayForecastMap: 'Map view',
    twoDayForecastExplanation: 'The air quality varies locally, even within municipalities.',
    healthArticleText: 'Read more about health advice and pollution classes.',
    airPollutionBoxHeartExplanation: '* These health advisories are especially relevant for fine particulate matter (PM2.5) ',
    seasaltNoHealthEffect: 'Sea salt has no known health effects.',
    ComponentInfoText: {
      PM10: {
        header: 'Particulate matter (PM10 and PM2.5)',
        info: 'Particle matter is usually spoken of as PM followed by a number indicating the size of the particles in micrometer. PM2.5 is all particles less than 2.5 μm, and PM10 is all particles less than 10 μm. This means particles in PM2.5 are also a part of PM10.',
      },
      PM25: {
        header: 'Particulate matter (PM10 og PM2.5)',
        info: 'Particle matter is usually spoken of as PM followed by a number indicating the size of the particles in micrometer. PM2.5 is all particles less than 2.5 μm, and PM10 is all particles less than 10 μm. This means particles in PM2.5 are also a part of PM10.',
      },
      none: {
        header: 'Unknown component',
        info: 'Could not find component source.',
      },
    },
    1: {
      helsevirkningerHeader: 'Low health risk',
      helsevirkninger: 'Low or no risk of adverse effects.',
      radGenerelt: 'Outdoor activity is recommended',
      radAstmaLuft: 'Outdoor activity is recommended',
      radHjerteKar: 'Outdoor activity is recommended',
      radEldre: 'Outdoor activity is recommended',
      radGravidBarn: 'Outdoor activity is recommended',
    },
    2: {
      helsevirkningerHeader:

'Moderate health risk',
      helsevirkninger: 'Moderate risk of adverse effects. Adverse effects may occur in some individuals with asthma and other respiratory diseases and serious cardiovascular diseases. Most likely no risk to healthy individuals.',
      radGenerelt: 'Outdoor activity is recommended',
      radAstmaLuft: 'Outdoor activity is recommended for most individuals. Individuals with worsening of symptoms should consider reducing intensive physical activity in the most polluted outdoor areas.',
      radHjerteKar: 'Outdoor activity is recommended for most individuals. Individuals with worsening of symptoms should consider reducing intensive physical activity in the most polluted outdoor areas.',
      radEldre: 'Outdoor activity is recommended for most individuals. Individuals with worsening of respiratory and cardiovascular symptoms should consider reducing intensive physical activity in the most polluted outdoor areas.',
      radGravidBarn: 'Outdoor activity is recommended',
    },
    3: {
      helsevirkningerHeader: 'High health risk',
      helsevirkninger: 'Considerable risk of adverse effects. Adverse effects occur in some individuals with asthma and other respiratory diseases and cardiovascular disease. Respiratory irritation and discomfort may occur in healthy individuals.',
      radGenerelt: 'Outdoor activity is normally recommended. Individuals with respiratory symptoms such as a cough and sore throat, should consider reducing intensive physical activity in the most polluted outdoor areas.',
      radAstmaLuft: 'Individuals with serious respiratory disease or worsening of asthma should consider reducing intensive physical activity and the time spent in the most polluted outdoor areas.',
      radHjerteKar: 'Individuals with serious cardiovascular diseases should consider reducing intensive physical activity and the time spent in the most polluted outdoor areas.',
      radEldre: 'Outdoor activity is normally recommended for the healthy elderly. Individuals with respiratory and cardiovascular disease should reduce intensive physical activity and the time spent in the most polluted outdoor areas.',
      radGravidBarn: 'Outdoor activity is normally recommended. Pregnant women and children with respiratory symptoms should reduce the time spent in the most polluted outdoor areas.',
    },
    4: {
      helsevirkningerHeader: 'Serious health risk',
      helsevirkninger: 'Serious risk of adverse effects. Sensitive population groups are very susceptible to adverse effects. Respiratory irritation and discomfort occur in healthy individuals.',
      radGenerelt: 'Reduce physical activity and the time spent in the most polluted outdoor areas, particularly if you have respiratory symptoms like cough and sore throat.',
      radAstmaLuft: 'Individuals with asthma and other respiratory diseases should not stay in the most polluted outdoor areas.',
      radHjerteKar: 'Individuals with cardiovascular diseases should not stay in the most polluted outdoor areas.',
      radEldre: 'Reduce physical activity and the time spent in the most polluted outdoor areas.',
      radGravidBarn: 'Reduce physical activity and the time spent in the most polluted outdoor areas.',
    },
    manualForecastUrlText: 'Read more',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
