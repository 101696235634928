import React from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import ComponentNameConverter, { formatComponent } from 'components/elements/component-name-converter/ComponentNameConverter';
import './PlotsContainer.scss';
import Localization, { setLanguage } from 'languages/components/plots/PlotsLan';
import LazySuspense from 'components/lazy/LazySuspense';

const AirComponentPlot = React.lazy(() => import('components/plots/aircomponents/AirComponentPlot'));

const MultiPlotsContainer = props => (
  <div className="c_plots-container">
    {props.isLoading
      ? <Spinner />
      : (
        <div>
          {Object.keys(props.plotsInfo).map((component) => {
            const title = Localization.ComponentInfo[props.type === 'ws2' ? 'twoDays' : 'fourteenDays'][component.toLowerCase()];
            const filename = `${title} (${component}) ${props.station}`;


            return (
              <div key={component.toLowerCase()}>
                <h3>
                  <ComponentNameConverter>{component}</ComponentNameConverter>
                  {title}
                </h3>
                <LazySuspense>
                  <AirComponentPlot
                    header={formatComponent(component, true, true)}
                    subHeader={title}
                    plotFileName={filename}
                    plotType={props.plotType}
                    componentData={props.plotsInfo[component]}
                    isLoading={props.isLoading}
                    type={props.type ? props.type : ''}
                  />
                </LazySuspense>
                {/* {(props.type === 'ws14' || props.type === 'ws7') && data.aqiRange && !data.aqiRange.ranges
                  && (
                    <div>
                      {Localization.formatString(Localization.NoDayMean, <ComponentNameConverter>{data.component}</ComponentNameConverter>)}
                    </div>
                  )} */}
              </div>
            );
          })}
        </div>
      )
      }
  </div>

);

export default MultiPlotsContainer;
