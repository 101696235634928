import { call, put, takeEvery } from 'redux-saga/effects';

function* getWMTSCapabilities() {
  try {
    const resp = yield call(fetch, 'http://uemep-wms.met.no/uEMEP/2018/05/16/00/Norway/wmts/1.0.0/WMTSCapabilities.xml');
    const res = yield call([resp, 'text']);

    yield put({ type: 'DISPATCH_GET_WMTSLAYER_SUCCESS_SAGA', wmts: res });
  } catch (err) {
    yield put({ type: 'DISPATCH_GET_WMTSLAYER_FAILED_SAGA', message: err.message });
  }
}

function* olSaga() {
  yield takeEvery('DISPATCH_GET_WMTSLAYER', getWMTSCapabilities);
}

export default olSaga;
