
import React from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import Localization, { setLanguage } from 'languages/components/places/PlacesLan';

const MyLocationSuggestionItem = (props) => {
  setLanguage();
  if (props.myLocation.error) {
    return <li className={`results__my-position--error${props.suggestionFocus ? ' arrow-focus' : ''}`}>{props.myLocation.errorMessage}</li>;
  }

  return (
    <li className={`results__my-position${props.suggestionFocus ? ' arrow-focus' : ''}`}>
      <button onClick={props.onClick} type="button">{Localization.myPosition}</button>
      {props.isLoading && <Spinner />}
    </li>
  );
};

export default MyLocationSuggestionItem;
