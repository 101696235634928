import React, { Component } from 'react';
import CancelableFetchPromise from 'js/CancelablePromise';
import Select from 'react-select';
import './AreaPicker.scss';
import Spinner from 'components/elements/spinner/Spinner';

class AreaPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: false,
      counties: [],
      selectedCounty: null,
      municipalities: [],
      selectedMunicipality: null,
      subareas: [],
      selectedSubarea: null,
      units: [],
      selectedUnit: null,
    };
    this.loadCounties = this.loadCounties.bind(this);
    this.loadMunicipalities = this.loadMunicipalities.bind(this);
    this.loadSubareas = this.loadSubareas.bind(this);
    this.loadUnits = this.loadUnits.bind(this);
    this.getSelections = this.getSelections.bind(this);
    this.returnSelection = this.returnSelection.bind(this);
    this.loadDefaultValues = this.loadDefaultValues.bind(this);
    this.taskFetchCounties = null;
    this.taskFetchMunicipalities = null;
    this.taskFetchSubareas = null;
    this.taskFetchUnits = null;
  }

  componentDidMount() {
    this.loadCounties();

    const def = this.props.defaultValues;

    if (def) {
      this.loadDefaultValues(def);
    }
  }

  componentWillUnmount() {
    if (this.taskFetchCounties) this.taskFetchCounties.cancel();
    if (this.taskFetchMunicipalities) this.taskFetchMunicipalities.cancel();
    if (this.taskFetchSubareas) this.taskFetchSubareas.cancel();
    if (this.taskFetchUnits) this.taskFetchUnits.cancel();
  }

  getSelections() {
    return {
      county: this.state.selectedCounty ? this.state.selectedCounty.value : null,
      municipality: this.state.selectedMunicipality ? this.state.selectedMunicipality.value : null,
      subarea: this.state.selectedSubarea ? this.state.selectedSubarea.value : null,
      unit: this.state.selectedUnit ? this.state.selectedUnit.value : null,
    };
  }

  returnSelection() {
    if (this.props.onChange) {
      this.props.onChange(
        {
          county: this.state.selectedCounty && this.state.selectedCounty.value,
          municipality: this.state.selectedMunicipality && this.state.selectedMunicipality.value,
          subarea: this.state.selectedSubarea && this.state.selectedSubarea.value,
          unit: this.state.selectedUnit && this.state.selectedUnit.value,
        },
      );
    }
  }

  async loadDefaultValues(def) {
    if (def.county) {
      await this.loadMunicipalities({ label: def.county, value: def.county });

      if (def.municipality) {
        await this.loadSubareas({ label: def.municipality, value: def.municipality });

        if (def.part) {
          await this.loadUnits({ label: def.part, value: def.part });

          if (def.area) {
            this.setState(
              {
                selectedUnit: {
                  label: def.area,
                  value: def.area,
                },
              },
            );
          }
        }
      }
    }
  }

  async loadCounties() {
    this.taskFetchCounties = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/places/hierarchy`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));

    let json = null;
    try {
      json = (await this.taskFetchCounties.promise).body;
    } catch (e) {
      return;
    }
    const counties = [];
    json.forEach(e => counties.push({ value: e.fylkeName, label: e.fylkeName }));

    this.setState({ isLoading: false, error: false, counties });
  }


  async loadMunicipalities(county) {
    this.setState({ selectedCounty: county });
    this.taskFetchMunicipalities = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/places/hierarchy?fylke=${encodeURIComponent(county.value)}`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));

    let json = null;
    try {
      json = (await this.taskFetchMunicipalities.promise).body;
    } catch (e) {
      return;
    }

    const municipalities = [];
    json.forEach(e => municipalities.push({ value: e.kommuneName, label: e.kommuneName }));

    this.setState({
      isLoading: false,
      error: false,
      municipalities,
      selectedMunicipality: null,
      subareas: [],
      selectedSubarea: null,
      units: [],
      selectedUnit: null,
    }, () => this.returnSelection());
  }

  async loadSubareas(municipality) {
    this.setState({ selectedMunicipality: municipality });
    this.taskFetchSubareas = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/places/hierarchy?fylke=${encodeURIComponent(this.state.selectedCounty.value)}&kommune=${encodeURIComponent(municipality.value)}`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));

    let json = null;
    try {
      json = (await this.taskFetchSubareas.promise).body;
    } catch (e) {
      return;
    }
    const subareas = [];
    json.forEach(e => subareas.push({ value: e.delomradeName, label: e.delomradeName }));

    this.setState({
      isLoading: false,
      error: false,
      subareas,
      selectedSubarea: null,
      units: [],
      selectedUnit: null,
    }, () => this.returnSelection());
  }

  async loadUnits(subarea) {
    this.setState({ selectedSubarea: subarea });
    this.taskFetchUnits = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/places/hierarchy?fylke=${encodeURIComponent(this.state.selectedCounty.value)}&kommune=${encodeURIComponent(this.state.selectedMunicipality.value)}&delomrade=${encodeURIComponent(subarea.value)}`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));

    let json = null;
    try {
      json = (await this.taskFetchUnits.promise).body;
    } catch (e) {
      return;
    }
    const units = [];
    json.forEach(e => units.push({ value: e.grunnkretsName, label: e.grunnkretsName }));

    this.setState({
      isLoading: false,
      error: false,
      units,
      selectedUnit: null,
    }, () => this.returnSelection());
  }

  render() {
    let muns = '';
    let subs = '';
    let units = '';

    if (this.state.isLoading) {
      return <Spinner />;
    }
    if (this.state.error) {
      return 'Error';
    }

    if (this.state.selectedCounty) {
      if (this.state.municipalities.length > 0) {
        muns = (
          <div className="choose-place__list choose-place__list--required">
            <h3>Kommune</h3>
            <Select
              onChange={e => this.loadSubareas(e)}
              value={this.state.selectedMunicipality}
              options={this.state.municipalities}
            />
          </div>
        );
      } else {
        muns = <Spinner />;
      }
    }

    if (this.state.selectedMunicipality) {
      if (this.state.subareas.length > 0) {
        subs = (
          <div className="choose-place__list">
            <h3>Delområde (valgfritt)</h3>
            <Select
              onChange={e => this.loadUnits(e)}
              value={this.state.selectedSubarea}
              options={this.state.subareas}
            />
          </div>
        );
      } else {
        subs = <Spinner />;
      }
    }

    if (this.state.selectedSubarea) {
      if (this.state.units.length > 0) {
        units = (
          <div className="choose-place__list">
            <h3>Grunnkrets (valgfritt)</h3>
            <Select
              onChange={e => this.setState({ selectedUnit: e }, () => this.returnSelection())}
              value={this.state.selectedUnit}
              options={this.state.units}
            />
          </div>
        );
      } else {
        units = <Spinner />;
      }
    }

    return (
      <div className="choose-place">
        <div className="choose-place__list choose-place__list--required">
          <h3>Fylke</h3>
          <Select
            onChange={e => this.loadMunicipalities(e)}
            value={this.state.selectedCounty}
            options={this.state.counties}
          />
        </div>
        {muns}
        {subs}
        {units}
      </div>
    );
  }
}

export default AreaPicker;
