const initialState = {
  isLoading: true,
  error: false,
  location: {
    place: null,
    subplaces: null,
    placeName: {
      name: '',
      municipality: '',
    },
  },
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case 'DISPATCH_LOCATION_SEARCH_STARTED':
      return Object.assign({}, state, { isLoading: true, error: false });
    case 'DISPATCH_LOCATION_SEARCH_DONE':
      return Object.assign({}, state,
        {
          location: action.location,
          isLoading: false,
          error: false,
        });
    case 'DISPATCH_LOCATION_SEARCH_FALIED':
      return Object.assign({}, state, { isLoading: false, error: true });
    default:
      return state;
  }
}
