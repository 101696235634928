import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    name: 'Målstasjonsnavn',
    measuring: 'Komponenter som måles',
    area: 'Områdetype',
    station: 'Stasjontype',
    measurement: 'Seneste måling',
    time: 'Tid',
    areaType: {
      'rural-regional': 'Regional',
      'rural-nearcity': 'Bynær',
      'rural-remote': 'Global',
      rural: 'Regional',
      suburban: 'Forstad',
      urban: 'By',
    },
    stationType: {
      background: 'Bakgrunn',
      traffic: 'Veinær',
      industrial: 'Industripåvirket',
    },

  },
  en: {
    name: 'Measuring station name',
    measuring: 'Components that are measured',
    area: 'Area type',
    station: 'Station type',
    measurement: 'Last measurement',
    time: 'Time',
    areaType: {
      'rural-regional': 'Regional',
      'rural-nearcity': 'Rural near city',
      'rural-remote': 'Global',
      rural: 'Regional',
      suburban: 'Suburban',
      urban: 'Urban',
    },
    stationType: {
      background: 'Background',
      traffic: 'Traffic',
      industrial: 'Industrial',
    },

  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
