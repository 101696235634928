import React from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumbs.scss';

const Breadcrumbs = props => (
  <div className="c_breadcrumbs">
    <span className="heading__small">{props.smallHeading}</span>
    <ul className="c_breadcrumbs__links">
      {props.breadcrumbs.fylke && (
        <li>
          <span>{props.breadcrumbs.fylke}</span>
        </li>
      )}
      {props.breadcrumbs.kommune && (
        <li>
          <Link to={`/varsling/${encodeURIComponent(props.breadcrumbs.fylke)}/${encodeURIComponent(props.breadcrumbs.kommune)}`}>
            {props.breadcrumbs.delomrade ? props.breadcrumbs.kommune : <h1>{props.breadcrumbs.kommune}</h1>}
          </Link>
        </li>
      )}
      {props.breadcrumbs.delomrade && (
        <li>
          <Link to={`/varsling/${encodeURIComponent(props.breadcrumbs.fylke)}/${encodeURIComponent(props.breadcrumbs.kommune)}/${encodeURIComponent(props.breadcrumbs.delomrade)}`}>
           {props.breadcrumbs.grunnkrets ? props.breadcrumbs.delomrade : <h1>{props.breadcrumbs.delomrade}</h1>}
          </Link>
        </li>
      )}
      {props.breadcrumbs.grunnkrets && (
        <li>
          <h1>{props.breadcrumbs.grunnkrets}</h1>
        </li>
      )}
    </ul>
  </div>
);

export default Breadcrumbs;
