import React, { Component } from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import CancelableFetchPromise from 'js/CancelablePromise';
import Localization, { setLanguage } from 'languages/components/articles/ArticlesLan';
import { trackSPAException } from 'js/TrackPage.js';
import ArticleListItem from './article-list-item/ArticleListItem';
import ArticleList from './article-list/ArticleList';

class ArticleRootList extends Component {
  static convertChildrenToRelatedLinks(children) {
    let result = [];

    if (Array.isArray(children)) {
      result = children.map(child => ({
        title: child.name,
        id: child.id,
        url: `/artikkel/${child.id}`,
      }));
    }

    return result;
  }

  constructor(props) {
    super(props);
    this.language = setLanguage();
    this.state = { articles: [], error: false };
    this.taskFetchArticles = null;
    // this.category = this.props.category;
  }

  async componentDidMount() {
    try {
      this.taskFetchArticles = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/cms/${this.props.category}`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));
      const res = (await this.taskFetchArticles.promise).body;

      const articles = res.map(a => ({
        href: `/artikkel${a.url}`,
        header: a.name,
        id: a.id,
        desc: a.ingress,
        type: Array.isArray(a.children) ? 'expand' : 'directLink',
        icon: a.icon,
        relatedLinks: ArticleRootList.convertChildrenToRelatedLinks(a.children),
      }));

      this.setState({ articles, error: false });
    } catch (e) {
      this.setState({ articles: [], error: true });
      trackSPAException('Article Root List', e,
        {
          url: `${process.env.REACT_APP_API}/api/cms/${this.props.category}`,
          status: e.status,
          body: e.body,
          message: e.message,
        });
    }
  }

  componentWillUnmount() {
    if (this.taskFetchArticles) {
      this.taskFetchArticles.cancel();
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorMessage header={Localization.errorMessageHeader} text={Localization.errorMessageText} />;
    }

    if (this.state.articles.length === 0) {
      return (<Spinner />);
    }

    return (
      <ArticleList header={this.props.header}>
        {this.state.articles.map((article, index) => <ArticleListItem index={index + 1} key={article.id} article={article} />)}
      </ArticleList>
    );
  }
}

export default ArticleRootList;
