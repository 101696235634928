const initialState = {
  activeNavigationArea: '',
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case 'DISPATCH_NAVIGATION_AREA_CHANGED':
      return Object.assign({}, state, { activeNavigationArea: action.area });
    default:
      return state;
  }
}
