import { AppInsights } from 'applicationinsights-js';

window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }

export function trackPageView(title, location, obj = null) {
  // Google analytics
  if (obj) {
    gtag('config', 'UA-11617192-29', {
      page_title: title || '',
      page_path: location || '',
      page_vars: JSON.stringify(obj),
    });
  } else {
    gtag('config', 'UA-11617192-29', {
      page_title: title || '',
      page_path: location || '',
    });
  }

  // Azure App Insights
  if (obj) {
    AppInsights.trackPageView(title, location, obj);
  } else {
    AppInsights.trackPageView(title, location);
  }
}

export function trackSPAException(place, ex, obj) {
  gtag('event', 'exception', {
    description: JSON.stringify(ex),
    place,
  });
  AppInsights.trackException(JSON.stringify(ex), place, obj);
}

export function trackSPAEvent(category, subcategory, label, detailsObj) {
  gtag('event', subcategory, {
    event_category: category,
    event_label: label,
    value: JSON.stringify(detailsObj),
  });
  AppInsights.trackEvent(`${category}/${subcategory}`, detailsObj);
}
