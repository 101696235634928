import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './ArticleListItem.scss';

class ArticleListItem extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.onExpandClick = this.onExpandClick.bind(this);
    this.state = { expanded: false };
  }

  onExpandClick() {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  }

  render() {
    return (
      <li
        className="article-list__item"
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem"
      >
        {/* Om article har tilhørende lenker */}
        {this.props.article.type === 'expand'
        && (
          <div className={`item__expand ${this.state.expanded ? 'clicked' : ''}`}>
            <button className="open-child-articles" type="button" onClick={this.onExpandClick}>
              <span className="item__icon">
                <img src={this.props.article.icon} alt="" />
              </span>
              <span className="item__text">
                <span role="heading" aria-level="3" className="header-3">
                  {this.props.article.header}
                </span>
                <span>{this.props.article.desc}</span>
              </span>
            </button>
            <div className="expand__hidden-content">
              <ul itemScope itemType="http://schema.org/ItemList">
                {this.props.article.relatedLinks.map((article, index) => (
                  <li key={article.id}>
                    <Link to={article.url}>
                      {article.title}
                      <meta itemProp="url" content={article.ur} />
                      <meta itemProp="position" content={index} />
                    </Link>
                  </li>
                ))
                }
              </ul>
            </div>
          </div>

        ) }

        {/* Om den IKKE har ekstra lenker */}
        {this.props.article.type === 'directLink'
      && (
      <Link to={this.props.article.href} className="item__direct-link e_white-box" title={this.props.article.title}>
        <meta itemProp="url" content={this.props.article.href} />
        <meta itemProp="position" content={this.props.index} />
        <span className="item__icon">
          <img src={this.props.article.icon} alt="" />
        </span>
        <span className="item__text">
          <h3>{this.props.article.header}</h3>
          <p>{this.props.article.desc}</p>
        </span>
      </Link>
      ) }
      </li>
    );
  }
}

export default ArticleListItem;
