const initialState = {
  myLocation: {
    error: false,
    errorMessage: '',
    coordinates: {},
    initialized: false,
  },
};

export default function myLocationReducer(state = initialState, action) {
  switch (action.type) {
    case 'DISPATCH_MYLOCATION_SUCCESS':
      return Object.assign(
        {},
        state,
        {
          myLocation: {
            error: false,
            errorMessage: '',
            coordinates: action.coordinates,
            initialized: true,
          },
        },
      );
    case 'DISPATCH_MYLOCATION_ERROR':
      return Object.assign(
        {},
        state,
        {
          myLocation: {
            error: true,
            errorMessage: action.errorMessage,
            coordinates: {},
            initialized: true,
          },
        },
      );
    default:
      return state;
  }
}
