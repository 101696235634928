import React, { Component } from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import CancelableFetchPromise from 'js/CancelablePromise';
import {
  getMostVisitedWeatherStations,
  addWeatherStationVisit,
} from 'js/CookieHandler';
import ButtonReturn from 'components/elements/button/button-return/ButtonReturn';
import { trackPageView } from 'js/TrackPage.js';
import Localization, {
  setLanguage,
} from 'languages/screens/weatherstation/WeatherstationLan';
import { Helmet } from 'react-helmet';
import  MetaDescription  from 'js/MetaDescription';
import WeatherStationInformation from './WeatherStationInformation';
import StationNotFound from './StationNotFound';
import './WeatherStation.scss';

class WeatherStation extends Component {
  constructor(props) {
    super(props);
    this.language = setLanguage();
    this.history = props.history;
    this.onBackClick = this.onBackClick.bind(this);
    this.state = {
      niluStationDetails: null,
      niluComponentInfoResponse: null,
      niluDataResponse: null,
      isLoading: true,
      niluNearby: null,
      error: false,
      mostVisitedStations: [],
      error24h: false,
    };
    this.taskStationDetailsData = null;
    this.taskPlaceData = null;
    this.taskNearbyData = null;
  }

  componentDidMount() {
    this.getAirComponentTableData(this.props.match.params.stasjon);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.stasjon !== prevProps.match.params.stasjon) {
      setTimeout(() => {
        window && window.scrollTo(0, 0);
        this.getAirComponentTableData(this.props.match.params.stasjon);
      }, 50);
    }
  }

  componentWillUnmount() {
    if (this.taskStationDetailsData) {
      this.taskStationDetailsData.cancel();
    }
    if (this.taskPlaceData) {
      this.taskPlaceData.cancel();
    }
    if (this.taskNearbyData) {
      this.taskNearbyData.cancel();
    }
  }

  onBackClick() {
    this.history.goBack();
  }

  async getAirComponentTableData(station) {
    this.setState({
      isLoading: true,
      niluStationDetails: null,
      niluComponentInfoResponse: null,
      niluDataResponse: null,
      niluNearby: null,
      error: false,
    });

    // Get station details
    try {
      this.taskStationDetailsData = CancelableFetchPromise(
        fetch(
          `${process.env.REACT_APP_API}/api/measuringstation/${station}/details`,
          {
            headers: {
              'Accept-Language': this.language,
            },
          },
        ),
      );
      const jsonStationDetails = (await this.taskStationDetailsData.promise)
        .body;

      this.setState({
        niluStationDetails: jsonStationDetails,
      });

      // Set cookies
      const cookieObj = {
        station: jsonStationDetails.station,
        stationArea: jsonStationDetails.area,
        stationMunicipality: jsonStationDetails.municipality,
      };
      addWeatherStationVisit(cookieObj);
    } catch (e) {
      this.setState({
        isLoading: false,
        niluStationDetails: null,
        error: true,
      });
    }

    // Get 24h data for station
    try {
      this.taskPlaceData = CancelableFetchPromise(
        fetch(
          `${process.env.REACT_APP_API}/api/measuringstation/${station}/24h`,
          {
            headers: {
              'Accept-Language': this.language,
            },
          },
        ),
      );
      const json = (await this.taskPlaceData.promise).body;

      this.setState({
        niluComponentInfoResponse: json,
        niluDataResponse: json.values,
        isLoading: false,
        error: false,
      });

      trackPageView('Screen / Weatherstation', this.props.location.pathname, {
        station: json.station,
        area: json.area,
        municipality: json.municipality,
      });

      this.getNearbyTableData(station);
    } catch (e) {
      this.getNearbyTableData(station);
      this.setState({
        isLoading: false,
        niluComponentInfoResponse: null,
        niluDataResponse: null,
        error: true,
        error24h: true,
      });
    }

    this.setState({ mostVisitedStations: getMostVisitedWeatherStations() });
  }

  async getNearbyTableData(station) {
    try {
      this.taskNearbyData = CancelableFetchPromise(
        fetch(
          `${process.env.REACT_APP_API}/api/measuringstation/${station}/nearbystations/latest`,
          {
            headers: {
              'Accept-Language': this.language,
            },
          },
        ),
      );
      const nearbyJson = (await this.taskNearbyData.promise).body;
      this.setState({
        niluNearby: nearbyJson,
        isLoading: false,
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    let page;
    let title = ' – målt luftkvalitet';
    if (this.state.isLoading) {
      title = this.props.match.params.stasjon + title;
      // Loading page
      page = (
        <div className="g_center-content">
          <Spinner />
        </div>
      );
    } else {
      title = `${this.props.match.params.stasjon}, ${this.state.niluStationDetails.municipality}${title}`;
      // Weatherstation information page
      page = (
        <>
        <Helmet>
          <meta name="description" content={MetaDescription(this.state.niluStationDetails.description)} />
        </Helmet>
        <WeatherStationInformation
          localization={Localization}
          station={this.props.match.params.stasjon}
          area={this.state.niluStationDetails.area}
          municipality={this.state.niluStationDetails.municipality}
          owner={this.state.niluStationDetails.owner}
          status={this.state.niluStationDetails.status}
          description={this.state.niluStationDetails.description}
          data={this.state.niluDataResponse}
          nearbystations={this.state.niluNearby}
          longLat={[
            this.state.niluStationDetails.longitude,
            this.state.niluStationDetails.latitude,
          ]}
          history={this.history}
          mostVisitedStations={this.state.mostVisitedStations}
          weatherStationStatus={this.state.weatherStationStatus}
          hasUpdatedData={this.state.niluStationDetails.hasUpdatedData}
          error24h={this.state.error24h}
        />
        </>
      );
    }

    return (
      <div className="t_page-wrapper t_weather-station">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Header />
        <div className="g_main-content">
          <div className="g_center-content">
            <ButtonReturn onClick={this.onBackClick}>
              {Localization.buttonBack}
            </ButtonReturn>
          </div>

          {page}
        </div>
        <Footer />
      </div>
    );
  }
}

export default WeatherStation;
