import React, { Component } from 'react';

import './AirPollutionBox.scss';
import ExpertQuote from 'components/forecast/expert-quote/ExpertQuote';
import ButtonText from 'components/elements/button/button-text/ButtonText';
import ComponentNameConverter from 'components/elements/component-name-converter/ComponentNameConverter';
import { Link } from 'react-router-dom';
import ArticleInfo from 'json/Artikler.json';
import Localization, { setLanguage } from 'languages/components/forecast/ForecastLan';

class AirPollutionBox extends Component {
  static chooseBoxLevel(aqi) {
    let level = 'low';

    switch (aqi) {
      case 2: level = 'moderate'; break;
      case 3: level = 'high'; break;
      case 4: level = 'very-high'; break;
      default: level = 'low';
    }
    return level;
  }

  constructor(props) {
    super(props);

    setLanguage();
    this.toggleHealthAdvice = this.toggleHealthAdvice.bind(this);
    this.state = {
      detailsOpen: false,
    };
    this.boxClassLevel = AirPollutionBox.chooseBoxLevel(props.alert.aqi);
    this.jsonRad = Localization[props.alert.aqi];
  }

  toggleHealthAdvice() {
    this.setState(prevState => ({ detailsOpen: !prevState.detailsOpen }));
  }

  render() {
    const boxClass = `c_air-pollution__box pollution-${this.boxClassLevel}`;

    const seaSaltIsSource = this.props.alert.sources.some(s => s.name.toLowerCase() == 'sjøsalt' || s.name.toLowerCase() == 'seasalt');
    const seaSaltIsOnlySource = seaSaltIsSource && this.props.alert.sources.length == 1;

    let healthAdvice = <p>{this.jsonRad.helsevirkninger}</p>;
    if (seaSaltIsSource) {
      if (seaSaltIsOnlySource) {
        healthAdvice = <p>{Localization.seasaltNoHealthEffect}</p>;
      }
      else {
        healthAdvice = <p>{Localization.seasaltNoHealthEffect}<br/><br/>{this.jsonRad.helsevirkninger}</p>
      }
    }
    
    return (
      <div className={this.state.detailsOpen ? `${boxClass} show-details` : boxClass}>
        <div itemProp="mainEntity" itemScope itemType="http://schema.org/WebPage" className="box__left">
          <h2>
            <span className="heading__small">{Localization.airPollutionBoxHeading}</span>
            {' '}
            {this.props.areaClass === 'kommune' ? `${this.props.alert.level}${this.props.alert.aqi > 1 ? ` ${Localization.somePlaces}` : ''}` : this.props.alert.level}
          </h2>
          <p>
            {this.props.alert.components.length > 0 && Localization.airPollutionBoxReason}
            {this.props.alert.components.map((c, i) => (
              this.props.alert.components.length - 1 > i
                ? (
                  <span>
                    <ComponentNameConverter>{c}</ComponentNameConverter>
,
                    {' '}
                  </span>
                )
                : <ComponentNameConverter>{c}</ComponentNameConverter>
            ))}
          </p>
          <p>
            {this.props.alert.sources.length > 0 && Localization.airPollutionBoxSource}
            {this.props.alert.sources.map((c, i) => (
              this.props.alert.sources.length - 1 > i
                ? (
                  <span>
                    {c.name}
                    {' '}
                    (
                    <ComponentNameConverter>{c.component}</ComponentNameConverter>
                    ),
                    {' '}
                  </span>
                )
                : (
                  <span>
                    {c.name}
                    {' '}
                    (
                    <ComponentNameConverter>{c.component}</ComponentNameConverter>
                    )
                  </span>
                )
            ))}
          </p>
          <ExpertQuote
            municipality={this.props.municipality}
            county={this.props.county}
          />
        </div>

        <ButtonText onClick={() => this.toggleHealthAdvice()}>
          {this.state.detailsOpen ? Localization.airPollutionBoxToggleTextHide : Localization.airPollutionBoxToggleTextShow}
          {Localization.airPollutionBoxToggleText}
        </ButtonText>

        <div className="box__right">
          <h3>
            {this.jsonRad.helsevirkningerHeader}
          </h3>
          {healthAdvice}
        </div>

        <div className="box__hidden-content">
          <h2>{Localization.airPollutionBoxAdvice}</h2>
          <h4>{Localization.airPollutionBoxPopulace}</h4>
          <p>{this.jsonRad.radGenerelt}</p>
          <h4>{Localization.airPollutionBoxAstma}</h4>
          <p>{this.jsonRad.radAstmaLuft}</p>
          <h4>{Localization.airPollutionBoxHeart}</h4>
          <p>{this.jsonRad.radHjerteKar}</p>
          <h4>{Localization.airPollutionBoxElderly}</h4>
          <p>{this.jsonRad.radEldre}</p>
          <h4>{Localization.airPollutionBoxPregnant}</h4>
          <p>{this.jsonRad.radGravidBarn}</p>
          <div className="asterix-text">
            {Localization.airPollutionBoxHeartExplanation}
          </div>
          <Link to={ArticleInfo.helserad}>{Localization.healthArticleText}</Link>
        </div>
      </div>
    );
  }
}

export default AirPollutionBox;
