import React from 'react';
import './About.scss';

import Spinner from 'components/elements/spinner/Spinner';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import ArticleRootList from 'components/articles/ArticleRootList';
import Localization, {
  setLanguage,
} from 'languages/components/articles/ArticlesLan';
import { trackPageView } from 'js/TrackPage.js';

class About extends React.Component {
  constructor(props) {
    super(props);

    this.language = setLanguage();
  }

  componentDidMount() {
    trackPageView('Screen / About', this.props.location.pathname);
  }

  render() {
    let page;
    if (this.props.isLoading) {
      page = (
        <div className="g_center-content">
          <Spinner />
        </div>
      );
    } else {
      page = (
        <ArticleRootList
          category="about"
          header={Localization.aboutListHeader}
        />
      );
    }
    return (
      <div className="t_page-wrapper t_frontpage t_no-location">
        <Header about />
        <div className="g_center-content g_main-content">{page}</div>
        <Footer />
      </div>
    );
  }
}

export default About;
