import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    municipality: 'Kommune',
    allmesuringstations: 'Alle Målestasjoner',
    select: '— Velg —',
    loading: 'Laster..',
    selectAll: 'Velg alle',
  },
  en: {
    municipality: 'Municipality',
    allmesuringstations: 'All Measuring Stations',
    select: '— Select —',
    loading: 'Loading..',
    selectAll: 'Select all',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
