import React, { Component } from 'react';
import ButtonReturn from 'components/elements/button/button-return/ButtonReturn';
import Localization, { setLanguage } from 'languages/components/map/MapLan';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './MapHeader.scss';

class MapHeader extends Component {
  constructor(props) {
    super(props);
    this.history = props.history;
    setLanguage();
  }

  render() {
    return (
      <div className="c_map-header">
        <ButtonReturn
          extraClass="button--return--mini"
          onClick={() => setTimeout(() => {
            if (this.history.length > this.props.historyLength) {
              this.history.goBack();
            } else {
              this.history.push('/');
            }
          }, 50)}
        >
          {Localization.mapHeaderBack}
        </ButtonReturn>
        <div
          role="button"
          tabIndex={0}
          onClick={this.props.toggleFilterMenu}
          className={this.props.filterMenuOpen ? 'e_hamburger e_hamburger--clicked' : 'e_hamburger'}
        >
          <span />
          <span />
          <span />
        </div>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    historyLength: state.historyReducer.initialLength,
  };
}

function mapDispatchToProps() {
  return ({
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapHeader));
