import React, { Component } from 'react';
import './AirPollutionSource.scss';
import CreativeCommons from 'components/info/creative-commons/CreativeCommons';
import configJSON from 'json/Artikler.json';
import Localization, { setLanguage } from '../../../languages/components/info/InfoLan';

class AirPollutionSource extends Component {
  static chooseBoxLevel(aqi) {
    let level = 'low';

    switch (aqi) {
      case 2: level = 'moderate'; break;
      case 3: level = 'high'; break;
      case 4: level = 'very-high'; break;
      default: level = 'low';
    }
    return level;
  }

  constructor(props) {
    super(props);
    setLanguage();
    this.boxClassLevel = props.alert ? AirPollutionSource.chooseBoxLevel(props.alert.aqi) : 0;
  }

  render() {
    const boxClass = `c_air-pollution-source pollution-${this.boxClassLevel}`;

    return (
      <div className={boxClass}>
        <div className="c_air-pollution-source__warning-text">
          {Localization.formatString(Localization.pollutionSourceText, <a href={configJSON.pollutionsource}>{Localization.pollutionSource}</a>)}
        </div>
      </div>
    );
  }
}

export default AirPollutionSource;
