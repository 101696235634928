import React from 'react';
import Localization, { setLanguage } from '../../../languages/components/weatherstation/WeatherStationLan';

import './WeatherStationStatus.scss';

const WeatherStationStatus = (props) => {
  setLanguage();
  return (
    <div className="c_weather-station-status">
      {props.description ? <div className="c_weather-station-status__text" dangerouslySetInnerHTML={{ __html: props.description }} /> : <div className="c_weather-station-status__text">Mangler informasjon</div>}
      <dl className="c_weather-station-status__list">
        {props.status && <dt>{Localization.statusStatus}</dt>}
        {props.status && <dd>{props.status}</dd>}
        {props.owner && <dt>{Localization.statusOwner}</dt>}
        {props.owner && <dd>{props.owner}</dd>}
        <dt>{Localization.statusSource}</dt>
        <dd>{Localization.statusSources}</dd>
        <dt>{Localization.statusLicense}</dt>
        <dd>{Localization.statusLicensed}</dd>
      </dl>
    </div>
  );
};

export default WeatherStationStatus;
