import React from 'react';
import ButtonLink from 'components/elements/button/button-link/ButtonLink';
import Localization, { setLanguage } from 'languages/components/info/InfoLan';
import icon from './icon-warning.png';
import './NoWarnedWeather.scss';

const NoWarnedWeather = (props) => {
  setLanguage();
  return (
    <div className="c_no-warned-weather c_white-box">
      <p>
        <img src={icon} alt="Varselikon" />
        {Localization.noWarnedWeatherText}
      </p>
      <ButtonLink url={`/kart/${props.lat ? props.lat : 59}/${props.long ? props.long : 10}/${props.zoom ? props.zoom : 5}/aqi`}>{Localization.noWarnedWeatherMap}</ButtonLink>
    </div>
  );
};

export default NoWarnedWeather;
