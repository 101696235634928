const CancelableFetchPromise = (promise, json = true, text = false) => {
  let cancel = false;
  const obj = {
    status: null,
    body: null,
  };

  function convertToJSON(resolved, resolve, reject) {
    obj.status = resolved.status;
    resolved.json()
      .then(
        (val) => {
          if (!cancel) {
            obj.body = val;
            resolve(obj);
          }
        },
      )
      .catch((err) => {
        if (!cancel) {
          obj.status = err.status || 500;
          obj.body = '';
          reject(obj);
        }
      });
  }

  function convertToText(resolved, resolve, reject) {
    obj.status = resolved.status;
    resolved.text()
      .then(
        (val) => {
          if (!cancel) {
            obj.body = val;
            resolve(obj);
          }
        },
      )
      .catch((err) => {
        if (!cancel) {
          obj.status = err.status || 500;
          obj.body = err;
          reject(obj);
        }
      });
  }

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        (val) => {
          if (val.status === 401) {
            obj.status = val.status;
            obj.body = '';
            resolve(obj);
            return;
          }
          if (!cancel) {
            if (json) {
              convertToJSON(val, resolve, reject);
            } else if (text) {
              convertToText(val, resolve, reject);
            } else {
              obj.status = val.status;
              obj.body = '';
              resolve(obj);
            }
          }
        },
      )
      .catch((err) => {
        if (!cancel) {
          obj.stauts = err.status;
          obj.body = err;
          reject(obj);
        }
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      cancel = true;
    },
  };
};

export default CancelableFetchPromise;
