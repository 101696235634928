/* eslint react/jsx-one-expression-per-line: 0 */
import React, { Component } from 'react';
import Localization, { setLanguage } from 'languages/components/elements/ElementsLan';


function formatSubscript(text, subscript, asHtml) {
  if (asHtml) {
    return `<span>${text}<sub>${subscript}</sub></span>`;
  }

  return <span>{text}<sub>{subscript}</sub></span>;
}

export function formatComponent(component, asHtml, callSetLanguage) {
  if (callSetLanguage) {
    setLanguage();
  }
  let p = asHtml ? `<span>${component}</span>` : <span>{component}</span>;

  switch (component.toLowerCase()) {
    case 'aqi': p = asHtml ? `<span>${Localization.aqi}</span>` : <span>{Localization.aqi}</span>; break;
    case 'pm25': p = formatSubscript('PM', 2.5, asHtml); break;
    case 'pm2.5': p = formatSubscript('PM', 2.5, asHtml); break;
    case 'pm10': p = formatSubscript('PM', 10, asHtml); break;
    case 'no2': p = formatSubscript('NO', 2, asHtml); break;
    case 'o3': p = formatSubscript('O', 3, asHtml); break;
    case 'so2': p = formatSubscript('SO', 2, asHtml); break;
    default: break;
  }

  return p;
}

class ComponentNameConverter extends Component {
  constructor(props) {
    super(props);
    setLanguage();
  }


  render() {
    return formatComponent(this.props.children);
  }
}

export default ComponentNameConverter;
