import React from 'react';
import { Link } from 'react-router-dom';

import Localization, { setLanguage } from 'languages/components/weatherstationlist/WeatherStationListLan';
import MeasuringStation from './MeasuringStation';

const MeasuringStationsTable = ({ stations, municipality }) => {
  setLanguage();
  return (
    <>

      <h2 style={{ marginTop: '2em' }}>
        <Link to={`/maalestasjon/kommune/${municipality}`} content={municipality}>{municipality}</Link>
      </h2>
      <table style={{ marginTop: '1em' }} className=" table table--status">
        <thead>
          <tr>
            <th>{Localization.name}</th>
            <th>{Localization.measurement}</th>
            <th>{Localization.time}</th>
            <th>{Localization.area}</th>
            <th>{Localization.station}</th>
            <th>{Localization.measuring}</th>
          </tr>
        </thead>
        {stations.map(station => (
          <MeasuringStation
            name={station.station}
            latestAQI={station.latestAQI}
            areaType={station.stationArea}
            stationType={station.stationType}
            latestAQIComponents={station.latestAQIComponents}
          />
        ))}
      </table>
    </>
  );
};

export default MeasuringStationsTable;
