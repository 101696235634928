import React, { Component } from 'react';
import './CreativeCommons.scss';
import Localization, { setLanguage } from 'languages/components/info/InfoLan';
// import icon from './icons-cc-grey.png';
import { getCookieLanguage } from 'js/CookieHandler';
import { ReactComponent as CcPersonIcon } from './cc_person_icon.svg';
import { ReactComponent as CcIcon } from './cc_icon.svg';

class CreativeCommons extends Component {
  constructor(props) {
    super(props);
    setLanguage();
  }

  render() {
    return (
      <div className={`c_creative-commons ${this.props.extraClass ? this.props.extraClass : ''}`}>
        <div className="c_creative-commons__inner">
          {this.props.privacyLink && (
            <div className="c_creative-commons__privacy">
              <a
                href={Localization.creativeCommonsPrivacyLink}
                rel="noopener noreferrer"
                target="_blank"
                title={Localization.creativeCommonsPrivacy}
              >
                {Localization.creativeCommonsPrivacy}
              </a>
              {getCookieLanguage() === 'no-nb' && (
                <a
                  href="https://uustatus.no/nb/erklaringer/publisert/a58baad8-8fe6-4414-8423-b1e6d2688298"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Tilgjengelighetserklaring"
                >
                  Tilgjengelighetserklaring
                </a>
              )}
            </div>
          ) }
          <span className="c_creative-commons__text">
            <span>
              {Localization.formatString(Localization.creativeCommonsText, <a href="https://creativecommons.org/licenses/by/4.0/" rel="noopener noreferrer" target="_blank" title={Localization.CreativeCommonsTitle}>{Localization.creativeCommonsLinkText}</a>)}
            </span>
            <span className="c_creative-commons__icons">
              <CcPersonIcon className="c_creative-commons__icons__person" />
              <CcIcon className="c_creative-commons__icons__cc" />
            </span>
          </span>

        </div>
      </div>
    );
  }
}

export default CreativeCommons;
