/* Eslint hints: */
/* eslint no-underscore-dangle: "off" */
/* global window  */

import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'redux/reducers/rootReducer';
import createSagaMiddleware from 'redux-saga';
import runSagas from 'redux/sagas/runSagas';

export default function configureStore() {
  const saga = createSagaMiddleware();

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(saga)),
  );
  runSagas(saga);
  return store;
}
