import React from 'react';

import './ErrorMessage.scss';

const ErrorMessage = props => (
  <div className={props.extraClass ? `c_error-message ${props.extraClass}` : 'c_error-message'}>
    <h3>{props.header}</h3>
    <p>{props.text}</p>
  </div>
);

export default ErrorMessage;
