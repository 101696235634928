import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ButtonPosition.scss';
import GetMyPosition from 'js/GetMyPosition';

class ButtonPosition extends Component {
  constructor(props) {
    super(props);
    this.buttonClick = this.buttonClick.bind(this);
    this.myLocationSuccess = this.myLocationSuccess.bind(this);
    this.myLocationError = this.myLocationError.bind(this);
    this.state = { isLoading: false };
  }

  buttonClick() {
    this.setState({ isLoading: true });
    if (this.props.myLocation.initialized && !this.props.myLocation.error) {
      const search = this.props.myLocation.coordinates;
      this.props.search(search);
      this.setState({ isLoading: false });
    } else {
      GetMyPosition(this.myLocationSuccess, this.myLocationError);
    }
  }

  async myLocationSuccess(res) {
    this.props.dispatchSuccess(res);
    const search = this.props.myLocation.coordinates;
    this.props.search(search);
    this.setState({ isLoading: false });
  }

  async myLocationError(res) {
    this.props.dispatchError(res);
    this.setState({ isLoading: false });
  }

  render() {
    return <button type="button" className={`button button--position button--round-icon ${this.props.myLocation.error ? 'button--position--inactive' : ''}${this.state.isLoading ? ' loading' : ''}`} onClick={() => this.buttonClick()} />;
  }
}

function mapStateToProps(state) {
  return {
    myLocation: state.myLocationReducer.myLocation,
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    dispatchSuccess: (coordinates) => { dispatch({ type: 'DISPATCH_MYLOCATION_SUCCESS', coordinates }); },
    dispatchError: (errorMessage) => { dispatch({ type: 'DISPATCH_MYLOCATION_ERROR', errorMessage }); },
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonPosition);
