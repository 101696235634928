import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import Localization, { setLanguage } from 'languages/components/elements/ElementsLan';
import { setCookieLanguage, getCookieLanguage } from 'js/CookieHandler';
import './MainMenu.scss';

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { mainMenuOpen: false, languagePickerOpen: false };
    this.toggleMainMenu = this.toggleMainMenu.bind(this);
    this.toggleLanguagePicker = this.toggleLanguagePicker.bind(this);
  }

  toggleMainMenu() {
    this.setState(prevState => ({ mainMenuOpen: !prevState.mainMenuOpen }));
  }

  toggleLanguagePicker() {
    this.setState(prevState => ({ languagePickerOpen: !prevState.languagePickerOpen }));
  }

  render() {
    setLanguage();
    const lan = getCookieLanguage();
    // const isActive = this.context.route.location.pathname === this.props.to;
    return (
      <nav className={this.state.mainMenuOpen ? 'c_main-menu c_main-menu--open' : 'c_main-menu'}>
        <button onClick={() => this.toggleMainMenu()} type="button" className="c_main-menu__toggle-menu">{Localization.toggleMenu}</button>
        <ul>
          <li>
            <NavLink to="/" exact activeClassName="active">{Localization.airMenuItem}</NavLink>
          </li>
          <li>
            <NavLink to="/maalestasjoner" exact activeClassName="active">{Localization.measuringStationMenu}</NavLink>
          </li>
          <li>
            <NavLink to="/kart/59/10/5/aqi" exact activeClassName="active">{Localization.mapMenuItem}</NavLink>
          </li>
          <li>
            <NavLink isActive={match => match || this.props.activeNavigationArea === 'articles'} to="/laer-mer" exact activeClassName="active">{Localization.articlesMenuItem}</NavLink>
          </li>
          <li>
            <NavLink isActive={match => match || this.props.activeNavigationArea === 'about'} to="/om-tjenesten" exact activeClassName="active">{Localization.aboutMenuItem}</NavLink>
          </li>
        </ul>
        {/* <div className={`c_main-menu__language-picker e_dropdown-menu ${this.state.languagePickerOpen ? 'e_dropdown-menu--open' : ''}`}>
          <button onClick={() => this.toggleLanguagePicker()} type="button" className="e_dropdown-menu__toggle choose-language">{Localization.chooseLanguage}</button>
          <span className="mobile-only">Language</span>
          <ul className="e_dropdown-menu__options">
            <li>
              <button
                className={lan === 'no-nb' ? 'active' : ''}
                type="button"
                onClick={() => {
                  setCookieLanguage('no-nb');
                  window.location.reload();
                }}
              >
                Norsk
              </button>
            </li>
            <li>
              <button
                type="button"
                className={lan === 'en' ? 'active' : ''}
                onClick={() => {
                  setCookieLanguage('en');
                  window.location.reload();
                }}
              >
                English
              </button>
            </li>
          </ul>
        </div> */}
      </nav>
    );
  }
}
function mapStateToProps(state) {
  return {
    activeNavigationArea: state.navigationReducer.activeNavigationArea,

  };
}


export default connect(mapStateToProps)(MainMenu);
