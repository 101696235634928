import React, { Component } from 'react';
import Header from 'components/elements/header/Header';
import 'screens/forecast/SpecifiedLocation.scss';
import './Demoscreen.scss';
import { trackPageView } from 'js/TrackPage.js';

class Demoscreen extends Component {
  componentDidMount() {
    trackPageView('Screen / Demo iframe', this.props.location.pathname);
  }

  render() {
    return (
      <div className="t_page-wrapper t_demoscreen">
        <Header />
        <div className="g_center-content">
          <h1 className="page-heading">Iframe-eksempler</h1>
          <p>Obs! Selve iframen er det som er innenfor de grå kantene.</p>

          <div className="demo-iframe">
            <h2>Oslo > Oslo</h2>
            <h3>Automatisk varsling</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,pollution/Oslo/Oslo`} height="700" width="100%" />
          </div>

          <div className="demo-iframe">
            <h2>Oppland > Skjåk > Nordberg</h2>
            <h3>Automatisk varsling og kart</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,pollution,map/Oppland/Skj%C3%A5k/Nordberg`} height="1100" width="100%" />
          </div>

          <div className="demo-iframe">
            <h2>Buskerud > Kongsberg > Kongsberg nord-østre > Skarpåsskogen</h2>
            <h3>Automatisk varsling</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,pollution/Buskerud/Kongsberg/Kongsberg%20nord-%C3%B8stre/Skarp%C3%A5sskogen`} height="900" width="100%" />
          </div>

          <div className="demo-iframe">
            <h2>Oslo > Oslo > St.hanshaugen > St.hanshaugen rode 10</h2>
            <h3>Grafer</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,plots/Oslo/Oslo/St.hanshaugen/St.hanshaugen%20rode%2010`} height="900" width="100%" />
          </div>

          <div className="demo-iframe">
            <h2>Rogaland > Sandnes > Malmheim/åse > Folkvord</h2>
            <h3>Automatisk varsling</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,pollution/Rogaland/Sandnes/Malmheim%2F%C3%A5se/Folkvord`} height="650" width="100%" />
          </div>

          <div className="demo-iframe">
            <h2>Oppland > Dovre</h2>
            <h3>Automatisk varsling, grafer og kart</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,pollution,plots,map/Oppland/Dovre`} height="1800" width="100%" />
          </div>

          <div className="demo-iframe">
            <h2>Finnmark Finnmárku  > Kárá?johka - Karasjok > Karasjok</h2>
            <h3>Automatisk varsling og grafer</h3>
            <iframe title="Innebygget innhold fra luftkvalitet.miljodirektoratet.no" src={`${window.location.origin}/module/heading,pollution,plots/Finnmark%20Finnm%C3%A1rku/K%C3%A1r%C3%A1%3Fjohka%20-%20Karasjok/Karasjok`} height="1400" width="100%" />
          </div>

        </div>
      </div>
    );
  }
}

export default Demoscreen;
