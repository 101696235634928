import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  "no-nb": {
    notFoundTitle: 'Beklager, denne artikkelen finnes ikke',
    notFoundLink: 'Gå til startsiden',
    buttonBackText: 'Tilbake',
  },
  en: {
    notFoundTitle: 'Sorry, This page does not exist',
    notFoundLink: 'Go to front page',
    buttonBackText: 'Back',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
