import React, { Component } from 'react';

import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import CancelableFetchPromise from 'js/CancelablePromise';
import Localization, { setLanguage } from 'languages/screens/weatherstationlist/WeatherStationListLan';
import MeasuringStationsTable from '../../components/measuringstation/MeasuringStationsTable';
import './WeatherStationList.scss';

export class WeatherstationList extends Component {
  state = {
    stations: [],
    municipalities: [],
    isLoading: false,
    selectValue: this.props.match.params.stasjon,
    error: false,
  };

  componentDidMount() {
    setLanguage();
    this.setState({ isLoading: true });
    this.getMeasuringStationsData();
  }

  componentDidUpdate(prevProps) {
    const oldParams = prevProps.match.params;
    const newParams = this.props.match.params;
    if (oldParams.stasjon != newParams.stasjon) {
      this.setState({ selectValue: newParams.stasjon });
    }
  }

  handleChange = (m) => {
    this.props.history.push(`/maalestasjoner/${m}`);
  };

  compareStation(a, b) {
    if (a.station < b.station) {
      return -1;
    }
    if (a.station > b.station) {
      return 1;
    }
    return 0;
  }

  getMeasuringStationsData = async () => {
    try {
      const data = CancelableFetchPromise(
        fetch(`${process.env.REACT_APP_API}/api/measuringstation/all`, {
          headers: {
            'Accept-Language': this.language,
          },
        }),
      );
      const res = await data.promise;

      if (res.status !== 200) {
        this.setState({ stations: [], isLoading: false, error: true });
        return;
      }

      const response = res.body;
      const municipalitySet = Array.from(
        new Set(response.map(data => data.municipality)),
      );

      this.setState({
        stations: res.body,
        municipalities: municipalitySet.sort(),
        error: false,
        isLoading: false,
      });
    } catch (e) {
      this.setState({ stations: [], isLoading: false, error: true });
    }
  };

  render() {
    const {
      stations, municipalities, selectValue,
    } = this.state;

    return (
      <>
        <Header />
        <div className="g_center-content c_white-box">
          <h2>{Localization.allmesuringstations}</h2>
          <span>
            {Localization.municipality}
          </span>
          <div className="e_dropdown-menu w_dropdown-menu">
            <button
              className="e_dropdown-menu__toggle w_dropdown-menu__toggle"
              type="button"
            >
              {selectValue === '' ? Localization.select : selectValue}
            </button>
            <ul className="e_dropdown-menu__options w_dropdown-menu__options">
              {municipalities.size === 0 ? (
                <option>{Localization.loading}</option>
              ) : (
                [<li><button type="button" onClick={() => this.handleChange('')}>{Localization.selectAll}</button></li>, ...municipalities.map(m => (
                  <li key={m}>
                    <button type="button" onClick={() => this.handleChange(m)}>
                      {m}
                    </button>
                  </li>
                ))]

              )}
            </ul>
          </div>
          {selectValue ? (
            <MeasuringStationsTable
              stations={stations.filter(s => s.municipality === selectValue).sort(this.compareStation)}
              municipality={selectValue}
            />
          ) : (
            municipalities.map(m => (
              <MeasuringStationsTable
                stations={stations.filter(s => s.municipality === m).sort(this.compareStation)}
                municipality={m}
              />
            ))
          )}
        </div>
        <Footer />
      </>
    );
  }
}

export default WeatherstationList;
