import React, { Component } from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import Localization, { setLanguage } from 'languages/components/articles/dynamic-index/DynamicArticleIndexLan';
import './DynamicArticleIndex.scss';

class DynamicArticleIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: true,
    };

    setLanguage();
    this.createIndex = this.createIndex.bind(this);
  }

  componentDidMount() {
    this.createIndex();
  }

  createIndex() {
    const headers = ['h2', 'h3'];
    const root = document.getElementById('articleText');
    const list = [];

    let k = 0;
    for (k = 0; k < root.children.length; k++) {
      const child = root.children[k];

      if (headers.includes(child.localName)) {
        const id = `${child.innerText.replace(/[^A-Z0-9]+/gi, '_')}`;
        child.setAttribute('id', id);
        list.push({
          id,
          text: child.innerText,
        });
      }
    }
    this.setState({ list, loading: false });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    return (
      <div className="c_dynamic-article-index">
        <span>
          {Localization.tableOfContent}
:
        </span>
        <ul>
          {this.state.list.map(l => (
            <li key={l.id}>
              <a itemProp="hasPart" href={`#${l.id}`}>
                {l.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default DynamicArticleIndex;
