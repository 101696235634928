import React from 'react';

import './HeadingWithLine.scss';

const HeadingWithLine = props => (
  <div>
    <h1 className={`heading-with-line ${props.extraClass ? `${props.extraClass}` : ''}`}>
      <span className="heading__small">{props.smallHeading}</span>
      {props.children}
    </h1>
  </div>
);

export default HeadingWithLine;
