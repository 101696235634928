import React from 'react';
import './LogInForm.scss';
import ButtonPrimary from 'components/elements/button/button-primary/ButtonPrimary';
import Spinner from 'components/elements/spinner/Spinner';
import Localization, { setLanguage } from 'languages/components/admin/AdminLan';

class LogInForm extends React.Component {
  constructor(props) {
    super(props);

    setLanguage();
    this.username = React.createRef();
    this.password = React.createRef();
    this.userDiv = React.createRef();
    this.passDiv = React.createRef();
    this.onLoginClick = this.onLoginClick.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
  }

  onLoginClick() {
    // e.preventDefault();
    try {
      this.props.onLogInClick({
        username: this.username.current.value,
        password: this.password.current.value,
      }, this.onLoginFailed);
    } catch (e) {
      // TBD this should be handled
    }
  }

  onLoginFailed() {
    this.username.current.value = '';
    this.password.current.value = '';
    this.userDiv.current.classList.add('input--error');
    this.passDiv.current.classList.add('input--error');
    // Alert the user somehow that username and password was wrong.
  }

  render() {
    return (
      <div className={this.props.isLoading ? 'c_log-in-form c_log-in-form--loading' : 'c_log-in-form'}>
        {this.props.isLoading && <Spinner />}
        <h1>{Localization.loginHeader}</h1>
        <form action="" className="form">
          <div className="input input--square" ref={this.userDiv}>
            <label htmlFor="email">{Localization.loginUsername}</label>
            <input name="email" id="login-email" type="text" ref={this.username} placeholder={Localization.loginUsername} />
            <span className="error__message">{Localization.loginError}</span>
          </div>
          <div className="input input--square" ref={this.passDiv}>
            <label htmlFor="password">{Localization.loginPassword}</label>
            <input name="password" id="login-password" type="password" placeholder={Localization.loginPassword} ref={this.password} />
            <span className="error__message">{Localization.loginError}</span>
          </div>
          <div className="form--log-in__buttons">
            <ButtonPrimary onClick={() => this.onLoginClick()}>{Localization.loginButtonLogin}</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  }
}

export default LogInForm;
