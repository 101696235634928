import React, { Component } from 'react';
import Footer from 'components/elements/footer/Footer';
import HeadingWithLine from 'components/elements/heading-with-line/HeadingWithLine';

import PlotsSwitcherWeatherStationMuncipality from 'components/plots/plots-switcher/PlotsSwitcherWeatherStationMuncipality';
import Header from 'components/elements/header/Header';
import ButtonReturn from 'components/elements/button/button-return/ButtonReturn';
import { trackPageView } from 'js/TrackPage.js'; // TBD track
import Localization, {
  setLanguage,
} from 'languages/screens/weatherstation/WeatherstationMunicipalityLan';
import { Helmet } from 'react-helmet';


class WeatherStationMunicipality extends Component {
  constructor(props) {
    super(props);
    this.language = setLanguage();
    this.history = props.history;
    this.onBackClick = this.onBackClick.bind(this);
  }


  onBackClick() {
    this.history.goBack();
  }


  render() {
    const title = `${this.props.match.params.municipality} kommune – målt luftkvalitet`;

    return (
      <div className="t_page-wrapper t_weather-station">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Header />
        <div className="g_main-content">
          <div className="g_center-content">
            <ButtonReturn onClick={this.onBackClick}>
              {Localization.buttonBack}
            </ButtonReturn>
            <HeadingWithLine>
              {Localization.municipalityStations(this.props.match.params.municipality)}
            </HeadingWithLine>
          </div>
          <div className="g_center-content">
            <div className="c_map-plot-switcher">
              <div className="c_white-box">
                <div className="c_map-plot-switcher__content">
                  <PlotsSwitcherWeatherStationMuncipality municipality={this.props.match.params.municipality} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default WeatherStationMunicipality;
