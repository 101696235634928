import React, { Component } from 'react';
import './AdminHeader.scss';
import LuftkvalitetLogo from 'components/elements/header/luftkvalitet-logo/LuftkvalitetLogo';
import MainMenu from 'components/elements/main-menu/MainMenu';
import Localization, { setLanguage } from 'languages/components/admin/AdminLan';

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { userDropdownOpen: false };
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }

  toggleUserMenu() {
    this.setState(prevState => ({ userDropdownOpen: !prevState.userDropdownOpen }));
  }

  render() {
    setLanguage();
    return (
      <header className={`c_admin-header ${this.props.loggedIn ? 'c_admin-header--logged-in' : ''}`}>
        <div className="g_center-content">
          <LuftkvalitetLogo />
          {this.props.loggedIn
          && (
          <div className="c_admin-header__profile">
            <div className={`e_dropdown-menu profile__user-menu  ${this.state.userDropdownOpen ? 'e_dropdown-menu--open' : ''}`}>
              <button type="button" className="e_dropdown-menu__toggle" onClick={() => this.toggleUserMenu()}>
                {this.props.user}
              </button>
              <ul className="e_dropdown-menu__options">
                <li>
                  <button type="button" onClick={this.props.onLogOutClick}>
                    {Localization.logoutButton}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          )}
          <MainMenu />
        </div>
      </header>
    );
  }
}


export default AdminHeader;
