import React from 'react';
import { Link } from 'react-router-dom';
import './LuftkvalitetLogo.scss';
import Localization, { setLanguage } from 'languages/components/elements/ElementsLan';

const LuftkvalitetLogo = (props) => {
  // <button type="button" onClick={props.onClick} className={`button button--delete ${props.extraClass ? `${props.extraClass}` : ''}`}>{props.children}</button>
  setLanguage();
  return (
    <Link to="/" className="e_luftkvalitet-logo">
      <h1 className="e_luftkvalitet-logo__header e_luftkvalitet-logo__header--has-slogan">
        <span className="header__small" dangerouslySetInnerHTML={{__html: Localization.headerHtml}}/>
        <span className="header__slogan">{Localization.slogan}</span>
      </h1>
    </Link>
  );
};

export default LuftkvalitetLogo;
