import React, { Component } from 'react';
import Select from 'react-select'; // https://react-select.com/home#getting-started
import './CreateNewWarning.scss';
import { confirmAlert } from 'react-confirm-alert';
import ButtonPrimary from 'components/elements/button/button-primary/ButtonPrimary';
import ButtonSecondary from 'components/elements/button/button-secondary/ButtonSecondary';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { withRouter } from 'react-router-dom';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import Localization, { setLanguage } from 'languages/components/admin/AdminLan';

class CreateNewWarning extends Component {
  constructor(props) {
    super(props);

    setLanguage();
    this.refForm = React.createRef();
    this.refSelectMunicipality = React.createRef();
    this.refText = React.createRef();
    this.refUrl = React.createRef();
    this.sendForm = this.sendForm.bind(this);
    this.clickGoBack = this.clickGoBack.bind(this);

    this.dateFrom = props.editForecast ? new Date(props.editForecast.startTime) : new Date();
    this.dateTo = props.editForecast ? new Date(props.editForecast.endTime) : new Date();
    this.textareaOnInput = this.validateTextArea.bind(this);

    this.maxTextLength = 350;

    this.state = {
      lettersRemaining: this.maxTextLength,
      fromDateError: false,
      toDateError: false,
      alertUserError: false,
      errorMessage: {
        header: '',
        text: '',
      },
    };
  }

  componentDidMount() {
    const edit = this.props.editForecast;

    if (edit) {
      this.refSelectMunicipality.current.select.setValue(
        { value: edit.municipality, label: edit.municipalityName },
      );
      this.refText.current.value = edit.description;
      this.refUrl.current.value = edit.url;
      this.validateTextArea();
    }
  }

  sendForm(f) {
    f.preventDefault();

    if (this.state.fromDateError || this.state.toDateError) {
      this.setState({
        alertUserError: true,
        errorMessage: {
          header: Localization.errorInvalidDateHeader,
          text: Localization.errorInvalidDateText,
        },
      });
      return;
    }
    if (this.refForm.current.checkValidity()
        && this.refSelectMunicipality.current.state.value) {
      let obj = {};
      try {
        obj = {
          municipality: this.refSelectMunicipality.current.state.value.value,
          description: this.refText.current.value,
          startTime: this.dateFrom.toISOString(),
          endTime: this.dateTo.toISOString(),
          url: this.refUrl.current.value,
        };
      } catch (e) {
        this.setState({
          alertUserError: true,
          errorMessage: {
            header: Localization.errorFormHeader,
            text: Localization.errorFormText,
          },
        });
        return;
      }

      this.props.sendForm(obj);
    } else {
      this.setState({
        alertUserError: true,
        errorMessage: {
          header: Localization.errorFormHeader,
          text: Localization.errorFormText,
        },
      });
    }
    return false;
  }

  clickGoBack() {
    confirmAlert({
      title: Localization.confirmTitle,
      message: Localization.confirmMessage,
      buttons: [
        {
          label: Localization.confirmYes,
          onClick: this.props.goBack,
        },
        {
          label: Localization.confirmNo,
          onClick: () => {},
        },
      ],
    });
  }

  updateFrom(e) {
    if (typeof e === 'string') {
      // Invalid date
      this.dateFrom = null;
      this.setState({ fromDateError: true });
      return;
    }
    this.dateFrom = new Date(e._d);
    this.setState({ fromDateError: false });
  }

  updateTo(e) {
    if (typeof e === 'string') {
      // Invalid date
      this.dateTo = null;
      this.setState({ toDateError: true });
      return;
    }
    this.dateTo = new Date(e._d);
    this.setState({ toDateError: false });
  }

  validateTextArea() {
    const textLength = this.refText.current.value.length;

    if (textLength > this.maxTextLength) {
      this.refText.current.value = this.refText.current.value.substring(0, this.maxTextLength);
      this.setState({ lettersRemaining: 0 });
      return;
    }

    this.setState({ lettersRemaining: this.maxTextLength - textLength });
  }

  render() {
    return (
      <div className="c_create-new-warning">
        <h1>{Localization.formHeader}</h1>
        {this.state.alertUserError
          ? <ErrorMessage header={this.state.errorMessage.header} text={this.state.errorMessage.text} />
          : ''}
        <form className="form form--create-new-warning" onSubmit={f => this.sendForm(f)} method="post" ref={this.refForm}>
          <div className="input input--select input--square">
            <label htmlFor="select-municipality">{Localization.formChooseMunicipality}</label>
            <Select ref={this.refSelectMunicipality} options={this.props.list} />
          </div>
          <div className="input input--square">
            <label htmlFor="text-warning">{Localization.formForecastText}</label>
            <textarea name="text-warning" id="" cols="30" rows="10" ref={this.refText} onInput={() => this.validateTextArea()} required />
            <span className="remaining-characters">
              {this.state.lettersRemaining}
              {' '}
              {Localization.formLettersRemaining}
            </span>
          </div>
          <div className="input input--square">
            <label htmlFor="text-url">{Localization.formUrlText}</label>
            <input name="text-url" ref={this.refUrl} type="text" />
          </div>
          <div className={`input input--square input--date${this.state.fromDateError ? ' input--error' : ''}`}>
            <label htmlFor="date-from">
              {Localization.formFrom}
              {' '}
*
            </label>
            <Datetime
              dateFormat="D.M.YYYY"
              defaultValue={this.dateFrom}
              timeFormat="HH:mm:ss"
              name="date-from"
              id="date-from"
              onChange={e => this.updateFrom(e)}
            />
          </div>
          <div className={`input input--square input--date${this.state.toDateError ? ' input--error' : ''}`}>
            <label htmlFor="date-to">
              {Localization.formTo}
              {' '}
*
            </label>
            <Datetime
              dateFormat="D.M.YYYY"
              defaultValue={this.dateTo}
              timeFormat="HH:mm:ss"
              name="date-to"
              id="date-to"
              onChange={e => this.updateTo(e)}
            />
          </div>
          <div className="form--create-new-warning__buttons">
            <ButtonSecondary onClick={() => this.clickGoBack()}>{Localization.formBack}</ButtonSecondary>
            <ButtonPrimary onClick={f => this.sendForm(f)}>{Localization.formPublish}</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(CreateNewWarning);
