import React, { Component } from 'react';
import './Frontpage.scss';

import PlaceSearch from 'components/places/search/Search';
import TwoDayForecast from 'components/forecast/two-day-forecast/TwoDayForecast';
import PollutionLevel from 'components/info/pollution-level/PollutionLevel';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import ArticleCategoryList from 'components/articles/ArticleCategoryList';
import CancelableFetchPromise from 'js/CancelablePromise';
import StatusBox from 'components/status/StatusBox';
import { trackPageView } from 'js/TrackPage.js';

import {
  JSONLD,
  Graph,
  Product,
  AggregateRating,
  GenericCollection,
  ItemReviewed,
  Review,
  Author,
  Location,
  Rating,
  Generic
} from 'react-structured-data';
import NoWarnedWeather from 'components/info/no-warned-weather/NoWarnedWeather';
import { setLanguage } from 'languages/screens/frontpage/FrontpageLan';

class Frontpage extends Component {
  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.language = setLanguage();
    this.getTableData = this.getTableData.bind(this);
    this.taskGetTableData = null;
    this.taskConvertSearch = null;

    this.state = {
      table: [],
      error: false,
      staleData: false
    };
  }

  componentDidMount() {
    this.getTableData();
    trackPageView('Screen / Frontpage', this.props.location.pathname);
  }

  componentWillUnmount() {
    if (this.taskGetTableData) {
      this.taskGetTableData.cancel();
    }
    if (this.taskConvertSearch) {
      this.taskConvertSearch.cancel();
    }
  }

  async getTableData() {
    try {
      this.taskGetTableData = CancelableFetchPromise(
        fetch(`${process.env.REACT_APP_API}/api/forecast/topcities`, {
          headers: {
            'Accept-Language': this.language
          }
        })
      );

      const res = await this.taskGetTableData.promise;

      if (res.status !== 200) {
        this.setState({ table: [], error: true });
        return;
      }

      const data = [];
      res.body.areas.forEach(r => {
        data.push({
          city: {
            name: r.name,
            county: r.fylke,
            lat: r.latitude,
            long: r.longitude,
            zone: r.desc
          },
          today: {
            color: r.forecast.today.color,
            text: r.forecast.today.text,
            class: r.forecast.today.aqi
          },
          restOfDay: {
            startHour: r.forecast.restOfDay.startHour,
            endOfDay: r.forecast.restOfDay.endHour,
            color: r.forecast.restOfDay.color,
            text: r.forecast.restOfDay.text,
            class: r.forecast.restOfDay.aqi
          },
          tomorrow: {
            color: r.forecast.tomorrow.color,
            text: r.forecast.tomorrow.text,
            class: r.forecast.tomorrow.aqi
          }
        });
      });

      this.setState({
        table: data,
        error: false,
        staleData: res.body.dataIsStale
      });
    } catch (e) {
      this.setState({ table: [], error: true });
    }
  }

  async search(search) {
    this.taskConvertSearch = CancelableFetchPromise(
      fetch(
        `${process.env.REACT_APP_API}/api/places/geoencode?areaclass=${search.zone}&lat=${search.latitude}&lon=${search.longitude}`,
        {
          headers: {
            'Accept-Language': this.language
          }
        }
      )
    );

    const res = await this.taskConvertSearch.promise;

    const place = {
      county: encodeURIComponent(res.body.fylkeName),
      municipality: encodeURIComponent(res.body.kommuneName),
      part: res.body.delomradeName
        ? encodeURIComponent(res.body.delomradeName)
        : null,
      area: res.body.grunnkretsName
        ? encodeURIComponent(res.body.grunnkretsName)
        : null
    };

    let url = `/varsling/${place.county}/${place.municipality}`;
    if (place.part) {
      url += `/${place.part}`;

      if (place.area) {
        url += `/${place.area}`;
      }
    }
    this.props.history.push(url);
  }

  render() {
    return (
      <div className='t_page-wrapper t_frontpage t_no-location'>
        <StatusBox />
        <Header />
        <div className='g_main-content'>
          <div className='g_center-content'>
            <PlaceSearch search={this.search} />
          </div>
          <div className='g_center-content'>
            {this.state.staleData ? (
              <NoWarnedWeather />
            ) : (
              <TwoDayForecast
                history={this.props.history}
                tableData={this.state.table}
                error={this.state.error}
              />
            )}
            <PollutionLevel />
            <ArticleCategoryList />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Frontpage;
