export default function MetaDescription(str) {
    var maxLimit = 150;
    if (!str) {
        return '';
    }
    if (str.length < maxLimit) {
        return str;
    }
    else {
        return str.substr(0, maxLimit - 3) + "...";
    }
}