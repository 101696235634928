import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    errorMessageHeader: 'Feil',
    errorMessageText: 'Kunne ikke hente data.',
    smallHeading: 'Varslet luftforurensning for:',
    areasNearby: 'Områder i nærheten',
    stationsNearby: 'Målestasjoner forrige hele time',
    stationsNearbyMicrotag: 'Målt luftforurensning forrige hele time',
  },
  en: {
    errorMessageHeader: 'Error',
    errorMessageText: 'Could not load data.',
    smallHeading: 'Pollution forecast for:',
    areasNearby: 'Areas nearby',
    stationsNearby: 'Measurement stations previous hour',
    stationsNearbyMicrotag: 'Measured air pollution previous hour',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
