import React, { Component } from 'react';
import ButtonSmall from 'components/elements/button/button-small/ButtonSmall';
import ButtonLink from 'components/elements/button/button-link/ButtonLink';
import PollutionLevelExplanation from 'components/info/pollution-level-explanation/PollutionLevelExplanation';
import { Link } from 'react-router-dom';
import ArticleInfo from 'json/Artikler.json';
import './MapPlotSwitcher.scss';
import Localization, { setLanguage } from 'languages/components/map/MapLan';
import { isMobile } from 'react-device-detect';

class MapPlotSwitcherMeasuringStation extends Component {
  constructor(props) {
    super(props);
    setLanguage();
    this.state = {
      viewGraph: !this.props.viewMap,
      mapIsClicked: false || !isMobile,
      buttonText: Localization.mapPlotSwitcherActivateButton,
    };
    this.mapPlotSwitcherHeader = this.mapPlotSwitcherHeader.bind(this);
    this.toggleMapFunctions = this.toggleMapFunctions.bind(this);
  }

  mapPlotSwitcherHeader() {
    return (
      <div className="c_map-plot-switcher__buttons">
        <ButtonSmall
          active={this.state.viewGraph}
          onClick={() => this.setState({ viewGraph: true })}
        >
          {Localization.mapPlotSwitcherPlotSingleText}
        </ButtonSmall>
        <ButtonSmall
          active={!this.state.viewGraph}
          onClick={() => this.setState({ viewGraph: false })}
        >
          {Localization.mapPlotSwitcherMapSingleText}
        </ButtonSmall>
      </div>
    );
  }

  toggleMapFunctions() {
    if (this.state.mapIsClicked) {
      this.setState({ mapIsClicked: false, buttonText: Localization.mapPlotSwitcherActivateButton });
    } else {
      this.setState({ mapIsClicked: true, buttonText: Localization.mapPlotSwitcherLockButton });
    }
  }

  render() {
    const MapPlotSwitcherHeader = this.mapPlotSwitcherHeader;
    const basePlotSwitcherClasses = 'c_map-plot-switcher__content c_map-plot-switcher__content--map';
    const clickedPlotSwitcherClasses = `${basePlotSwitcherClasses} clicked`;
    const notClickedPlotSwitcherClasses = basePlotSwitcherClasses + (isMobile ? ' touch-pan-disabled' : '');
    if (this.state.viewGraph) {
      return (
        <div className="c_map-plot-switcher">
          <h2 className="only-for-screen-readers">{Localization.mapPlotSwitcherScreenReader}</h2>
          <MapPlotSwitcherHeader />
          <div className="c_white-box">
            <div className="c_map-plot-switcher__content">
              {this.props.children[0]}
              {this.props.components ? <PollutionLevelExplanation data={this.props.components[0].aqiRange.ranges} direction="horizontal" /> : ''}
            </div>
            <div className="c_map-plot-switcher__extra">
              <Link to={ArticleInfo.helserad}>{Localization.healthArticleText}</Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="c_map-plot-switcher">
        <h2 className="only-for-screen-readers">{Localization.mapPlotSwitcherScreenReader}</h2>
        <MapPlotSwitcherHeader />
        <div className="c_white-box">
          <div className={this.state.mapIsClicked ? clickedPlotSwitcherClasses : notClickedPlotSwitcherClasses} role="presentation">
            {isMobile && <button className="activate-map button button--no-button" onClick={() => this.toggleMapFunctions()} type="button">{this.state.buttonText}</button>}
            {this.props.children[1]}
          </div>
          <div className="c_map-plot-switcher__extra c_map-plot-switcher__extra--map">
            {this.props.hideExplanation ? '' : <h2>{Localization.mapPlotSwitcherPollution}</h2>}
            {this.props.hideExplanation ? '' : <PollutionLevelExplanation data={this.props.components[0].aqiRange.ranges} direction="horizontal" />}
            {this.props.hideExplanation ? '' : <Link className="c_map-plot-switcher__extra__article-link" to={ArticleInfo.helserad}>{Localization.healthArticleText}</Link>}
            <ButtonLink url={`/kart/${this.props.latitude}/${this.props.longitude}/14/${this.props.layer}`}>{Localization.mapPlotSwitcherMapChoice}</ButtonLink>
          </div>
        </div>
      </div>
    );
  }
}

export default MapPlotSwitcherMeasuringStation;
