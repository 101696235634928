import React from 'react';
import './SpecifiedLocation.scss';
import { connect } from 'react-redux';

import Spinner from 'components/elements/spinner/Spinner';
import PlaceSearch from 'components/places/search/Search';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import ArticleCategoryList from 'components/articles/ArticleCategoryList';
import ServerScreenState from 'js/ServerScreenState';
import CancelableFetchPromise from 'js/CancelablePromise';
import { getMostVisitedPlaces, addPlaceVisit, getMostVisitedWeatherStations } from 'js/CookieHandler';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import NoWarnedWeather from 'components/info/no-warned-weather/NoWarnedWeather';
import Localization, { setLanguage } from 'languages/screens/forecast/ForecastLan';
import { trackPageView, trackSPAException } from 'js/TrackPage.js';
import { Helmet } from 'react-helmet';
import  MetaDescription  from 'js/MetaDescription';
import SpecifiedLocationInformation from './SpecifiedLocationInformation';

class SpecifiedLocation extends React.Component {
  static calculateState(state, county) {
    let name = state.location.name;
    if (state.locationParent) {
      name += `, ${state.locationParent.name} `;
    }
    const mun = state.municipality.name;

    const breadcrumbs = {
      grunnkrets: null,
      delomrade: null,
      kommune: mun,
      fylke: county,
    };

    if (state.location.name !== mun) {
      if (state.locationParent) {
        breadcrumbs.grunnkrets = state.location.name;
        breadcrumbs.delomrade = state.locationParent.name;
      } else {
        breadcrumbs.delomrade = state.location.name;
      }
    }

    return {
      place: state,
      breadcrumbs,
      placeName: {
        name,
        municipality: mun,
      },
      subplaces: null,
    };
  }

  constructor(props) {
    super(props);

    this.language = setLanguage();

    this.searchClick = this.searchClick.bind(this);
    this.searchLocation = this.searchLocation.bind(this);
    this.searchScreen = this.searchScreen.bind(this);

    this.taskFetchPlace = null;
    this.taskFetchSubplace = null;
    this.taskStationsNearbyData = null;
    this.taskConvertSearch = null;
    this.mostVisitedPlaces = null;
    this.errorResponse = null;
    this.state = {
      areaClass: this.props.match.params.part ? '' : 'kommune',
      nearbyStations: [],
    };
  }

  componentDidMount() {
    this.searchScreen();
  }

  componentDidUpdate(prevProps) {
    const oldParams = prevProps.match.params;
    const newParams = this.props.match.params;
    if (oldParams.county !== newParams.county
      || oldParams.municipality !== newParams.municipality
      || oldParams.part !== newParams.part
      || oldParams.area !== newParams.area) {
      setTimeout(() => {
        window && window.scrollTo(0, 0);
        this.searchScreen();
      }, 50);
    }
  }

  componentWillUnmount() {
    if (this.taskFetchPlace) {
      this.taskFetchPlace.cancel();
    }
    if (this.taskFetchSubplace) {
      this.taskFetchSubplace.cancel();
    }
    if (this.taskConvertSearch) {
      this.taskConvertSearch.cancel();
    }
    if (this.taskStationsNearbyData) {
      this.taskStationsNearbyData.cancel();
    }
  }

  searchScreen() {
    const serverState = ServerScreenState();

    if (serverState) {
      SpecifiedLocation.calculateState(serverState, '');
      return;
    }
    const search = {
      county: this.props.match.params.county,
      municipality: this.props.match.params.municipality,
      part: this.props.match.params.part,
      area: this.props.match.params.area,
    };
    this.props.dispatchSearching();
    this.searchLocation(search);
  }

  async searchClick(search) {
    this.taskConvertSearch = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/places/geoencode?areaclass=${search.zone}&lat=${search.latitude}&lon=${search.longitude}`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));

    const res = (await this.taskConvertSearch.promise);

    const place = {
      county: encodeURIComponent(res.body.fylkeName),
      municipality: encodeURIComponent(res.body.kommuneName),
      part: res.body.delomradeName ? encodeURIComponent(res.body.delomradeName) : null,
      area: res.body.grunnkretsName ? encodeURIComponent(res.body.grunnkretsName) : null,
    };

    let url = `/varsling/${place.county}/${place.municipality}`;
    if (place.part) {
      url += `/${place.part}`;

      if (place.area) {
        url += `/${place.area}`;
      }
    }
    this.props.history.push(url);
  }

  async searchLocation(search) {
    let returnObject = {};
    try {
      let hierarchy = `fylke=${encodeURIComponent(search.county)}&kommune=${encodeURIComponent(search.municipality)}`;
      let zone = 'kommune';
      if (search.part) {
        hierarchy += `&delomrade=${encodeURIComponent(search.part)}`;
        zone = '';
        if (search.area) {
          hierarchy += `&grunnkrets=${encodeURIComponent(search.area)}`;
        }
      }

      this.setState({ areaClass: zone });

      const placeUri = `${process.env.REACT_APP_API}/api/forecast/place/alert?${hierarchy}`;
      this.taskFetchPlace = CancelableFetchPromise(fetch(placeUri,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));

      const subplaceUri = `${process.env.REACT_APP_API}/api/forecast/place/subplace/alert?${hierarchy}`;

      this.taskFetchSubplace = CancelableFetchPromise(fetch(subplaceUri,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));
      let state = (await this.taskFetchPlace.promise).body;

      this.errorResponse = state;
      returnObject = SpecifiedLocation.calculateState(state, this.props.match.params.county);
      state = (await this.taskFetchSubplace.promise).body;
      returnObject.subplaces = state;
    } catch (e) {
      this.props.dispatchActionFailed();
      returnObject.subplaces = null;
      trackSPAException('Screen / Forecast', e, {
        status: e.status,
        body: e.body,
        message: e.message,
      });
      return;
    }

    trackPageView('Screen / Forecast', this.props.location.pathname,
      {
        placeName: returnObject.placeName.name,
        latitude: returnObject.place.location.latitude,
        longitude: returnObject.place.location.longitude,
        zone: returnObject.place.location.desc,
        county: search.county,
        municipality: search.municipality,
        part: search.part,
        area: search.area,
      });
    addPlaceVisit({
      place: `${returnObject.placeName.name}(${returnObject.placeName.municipality})`,
      search,
    });

    this.mostVisitedPlaces = getMostVisitedPlaces();
    this.getNearbyTableData(returnObject.place.location.latitude, returnObject.place.location.longitude);
    this.props.dispatchAction(returnObject);
  }

  async getNearbyTableData(lat, long) {
    try {
      this.taskStationsNearbyData = CancelableFetchPromise(fetch(
        `${process.env.REACT_APP_API}/api/measuringstation/${lat}/${long}/nearbystations/latest`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        },
      ));
      const nearbyJson = (await this.taskStationsNearbyData.promise).body;
      this.setState({
        nearbyStations: nearbyJson,
      });
    } catch (e) {
      // this.setState({ isLoading: false });
      trackSPAException('Screen / Forecast - nearby', e, {
        status: e.status,
        body: e.body,
        message: e.message,
      });
    }
  }

  render() {
    let page;

    const {
      municipality, part, area,
    } = this.props.match.params;
    let title = ' – varslet luftkvalitet';
    if (area) {
      title = `${area}, ${municipality}${title}`;
    } else if (part) {
      title = `${part}, ${municipality}${title}`;
    } else {
      title = `${municipality}${title}`;
    }
    if (this.props.isLoading) {
      page = (
        <div className="g_center-content">
          <Spinner />
        </div>
      );
    } else if ((this.props.fetchLocation.place && this.props.fetchLocation.place.dataIsStale)
      || (this.props.fetchLocation.place && this.props.fetchLocation.subplaces.dataIsStale)) {
      page = (
        <div className="g_center-content">
          <NoWarnedWeather lat={this.props.match.params.lat} long={this.props.match.params.long} zoom="14" />
        </div>
      );
    } else if (this.props.error) {
      page = (<div className="g_center-content"><ErrorMessage header={Localization.errorMessageHeader} text={this.errorResponse !== null ? this.errorResponse : Localization.errorMessageText} /></div>);
    } else {
      page = (
        <SpecifiedLocationInformation
          breadcrumbs={this.props.fetchLocation.breadcrumbs}
          localization={Localization}
          areaname={this.props.fetchLocation.placeName.name}
          municipality={this.props.fetchLocation.placeName.municipality}
          latitude={parseFloat(this.props.fetchLocation.place.location.latitude)}
          longitude={parseFloat(this.props.fetchLocation.place.location.longitude)}
          components={this.props.fetchLocation.place.componentValues}
          nearbyplaces={this.props.fetchLocation.subplaces}
          nearbyStations={this.state.nearbyStations}
          alert={this.props.fetchLocation.place.alertTile}
          mostVisitedPlaces={this.mostVisitedPlaces}
          mostVisitedStations={getMostVisitedWeatherStations()}
          nowIndicator={this.props.fetchLocation.place.nowIndicatorTime}
          history={this.props.history}
          areaClass={this.state.areaClass}
        />
      );
    }
    return (
      <div className="t_page-wrapper t_frontpage t_no-location">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={MetaDescription(Localization.smallHeading + ' ' + this.props.fetchLocation.placeName.name)} />
            
        </Helmet>
        <Header />
        <div className="g_main-content">
          <div className="g_center-content">
            <PlaceSearch search={this.searchClick} />
          </div>

          {page}

          <div className="g_center-content">
            <ArticleCategoryList />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchLocation: state.locationReducer.location,
    isLoading: state.locationReducer.isLoading,
    error: state.locationReducer.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAction: (loc) => {
      dispatch({ type: 'DISPATCH_LOCATION_SEARCH_DONE', location: loc });
    },
    dispatchSearching: () => {
      dispatch({ type: 'DISPATCH_LOCATION_SEARCH_STARTED' });
    },
    dispatchActionFailed: () => {
      dispatch({ type: 'DISPATCH_LOCATION_SEARCH_FALIED' });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpecifiedLocation);
