import { call, put, takeEvery } from 'redux-saga/effects';

function* makeMessage() {
  try {
    const response = yield call(fetch, 'http://quotesondesign.com/wp-json/posts?filter[orderby]=rand&filter[posts_per_page]=1');
    const res = yield call([response, 'json']);

    yield put({ type: 'DISPATCH_EXAMPLE_ACTION_SAGA', text: `${res[0].title} - ${res[0].content}` });
  } catch (err) {
    yield put({ type: 'DISPATCH_EXAMPLE_ACTION_FAILED', message: err.message });
  }
}

function* getStations() {
  try {
    const resp = yield call(fetch, 'https://api.nilu.no/lookup/stations');
    const res = yield call([resp, 'json']);

    yield put({ type: 'MAP_GET_STATIONS_SUCCESS_SAGA', list: res });
  } catch (err) {
    yield put({ type: 'MAP_GET_STATIONS_FAILED_SAGA', message: err.message });
  }
}


function* exampleSaga() {
  yield takeEvery('DISPATCH_EXAMPLE_ACTION', makeMessage);
  yield takeEvery('MAP_GET_STATIONS', getStations);
}

export default exampleSaga;
