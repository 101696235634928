import React, { Component } from 'react';
import Localization, { setLanguage } from 'languages/components/map/MapLan';
import ButtonToggle from 'components/elements/button/button-toggle/ButtonToggle';
import './ShowHidePanels.scss';

class ShowHidePanels extends Component {
  constructor(props) {
    super(props);
    setLanguage();
  }


  render() {
    return (
      <div className="c_show-hide-panels">
        <span>
          {Localization.togglePanelsText}
        </span>
        <div className="c_show-hide-panels__buttons">
          <ButtonToggle
            extraClass="button--toggle--show"
            active={!this.props.panelsHidden}
            onClick={this.props.showPanels}
            panelsHidden={this.props.panelsHidden}
          >
            {Localization.togglePanelsShowText}
          </ButtonToggle>
          <ButtonToggle
            extraClass="button--toggle--hide"
            active={this.props.panelsHidden}
            onClick={this.props.hidePanels}
            panelsHidden={this.props.panelsHidden}
          >
            {Localization.togglePanelsHideText}
          </ButtonToggle>
        </div>
      </div>
    );
  }
}

export default ShowHidePanels;
