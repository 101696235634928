import { combineReducers } from 'redux';
import exampleReducer from './exampleReducer';
import mapReducer from './mapReducer';
import myLocationReducer from './myLocationReducer';
import locationReducer from './locationReducer';
import securityReducer from './securityReducer';
import historyReducer from './historyReducer';
import navigationReducer from './navigationReducer';

const rootReducer = combineReducers({
  exampleReducer,
  mapReducer,
  myLocationReducer,
  locationReducer,
  securityReducer,
  historyReducer,
  navigationReducer,
});

export default rootReducer;
