import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  "no-nb": {
    pageTitle: 'Fant ikke siden - 404',
    pageMessage: 'Beklager, men denne siden har visst blåst bort.',
    pageUrlText: 'Trykk her for å komme tilbake til startsiden',
  },
  en: {
    pageTitle: 'Could not find page - 404',
    pageMessage: 'Sorry. It looks like this page has gone with the wind.',
    pageUrlText: 'Click here to go back to the front page',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
