
import React from 'react';

const SuggestionItem = (props) => {
  if (props.suggestionFocus) {
    return (
      <li className="arrow-focus">
        <button type="button" onClick={() => { if (props.type) props.onClick(); }}>
          {`${props.name} (${props.type})`}
        </button>
      </li>
    );
  }
  return (
    <li>
      <button type="button" onClick={() => { if (props.type) props.onClick(); }}>
        {`${props.name} (${props.type})`}
      </button>
    </li>
  );
};

export default SuggestionItem;
