import React, { Component } from 'react';
import ComponentNameConverter, { formatComponent } from 'components/elements/component-name-converter/ComponentNameConverter';

import Localization, { setLanguage } from 'languages/components/plots/PlotsLan';
import LazySuspense from 'components/lazy/LazySuspense';

const AirComponentPlot = React.lazy(() => import('components/plots/aircomponents/AirComponentPlot'));

class PlotSwitcherForecast extends Component {
  constructor(props) {
    super(props);

    setLanguage();
    this.state = { component: this.props.components[0], index: 0, isLoading: true };
    this.selectComponent = this.selectComponent.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState(
      { component: this.props.components[0], index: 0, isLoading: false },
    ), 50); // 50msec to protect against plotly oddeties
  }

  selectComponent(componentIndex) {
    this.setState({ component: this.props.components[componentIndex], index: componentIndex });
  }

  render() {
    let componentName = this.state.component.component.toLowerCase();
    if (componentName === 'aqi') {
      componentName = Localization.aqi;
    }
    const filename = `${Localization.forecastFileName} ${this.props.areaname} (${componentName})`;
    const description = this.props.areaClass === 'kommune' ? Localization.plotMunicipalityText : '';
    const subHeader = Localization.ComponentInfo.twoDays[this.state.component.component.toLowerCase()];
    return (
      <div>
        <div className="tab-buttons">
          {this.props.components.map((c, index) => (
            <button
              className={`button ${index === this.state.index ? 'active' : ''}`}
              type="button"
              key={c.component}
              onClick={() => this.selectComponent(index)}
            >
              <ComponentNameConverter>{c.component}</ComponentNameConverter>
            </button>
          ))}
        </div>
        <h3>
          <ComponentNameConverter>{this.state.component.component}</ComponentNameConverter>
          {subHeader}
        </h3>
        <span className="sub-heading">
          {description}
        </span>
        <LazySuspense>
          <AirComponentPlot
            header={formatComponent(this.state.component.component, true, true)}
            subHeader={subHeader}
            description={description}
            plotFileName={filename}
            nowIndicator={this.props.nowIndicator}
            isLoading={this.state.isLoading}
            plotType="line"
            componentData={this.state.component}
          />
        </LazySuspense>
      </div>
    );
  }
}

export default PlotSwitcherForecast;
