import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    creativeCommonsTitle: 'Klikk her for å lese om Creative Commons',
    creativeCommonsText: 'Arbeidet er lisensiert under en {0} Lisens',
    creativeCommonsLinkText: 'Creative Commons Attribution 4.0',
    noWarnedWeatherText: 'For øyeblikket er ikke Varslet luftforurensning (beregnet) tilgjengelig. Vi beklager dette, prøv igjen senere.',
    creativeCommonsPrivacy: 'Personvern',
    creativeCommonsPrivacyLink: 'https://www.miljodirektoratet.no/personvernerklaring/',
    noWarnedWeatherMap: 'Kartvisning',
    pollutionLevelErrorHeader: 'Feil',
    pollutionLevelErrorText: 'Kunne ikke hente fargekoding for forurensning.',
    pollutionLevelExplanationNoData: 'Ingen data',
    pollutionSource: 'Varselet',
    pollutionSourceText: '{0} er basert på beregninger utført av Meteorologisk institutt. Data for "I morgen" vises først etter at varslet er oppdatert (oppdateres hver morgen).',
    levelHeader: 'Luftforurensning',
    pollutionLevelH2: 'Forurensningsnivå',
    pollutionLevelP1: 'Forurensningsnivåene er vist ved hjelp av fargekoder (forurensningsklasser).',
    pollutionLevelP2: 'Lær mer om hva de ulike forurensningsklassene betyr og hvilke betydning forurensningsnivået har for din helse. {0}.',
    pollutionLevelURLText: 'Les om helseråd og forurensningsklasser her',
  },
  en: {
    creativeCommonsTitle: 'Click here to read more about Creative Commons',
    creativeCommonsText: 'The work is licensed under a {0} License',
    creativeCommonsLinkText: 'Creative Commons Attribution 4.0',
    noWarnedWeatherText: 'There is no modelled forecast available at the moment. Please try again later.',
    creativeCommonsPrivacy: 'Privacy',
    creativeCommonsPrivacyLink: 'https://www.miljodirektoratet.no/personvernerklaring/',
    noWarnedWeatherMap: 'Map view',
    pollutionLevelErrorHeader: 'Error',
    pollutionLevelErrorText: 'Could not retrieve colour codes for pollution.',
    pollutionLevelExplanationNoData: 'No data',
    pollutionSource: 'The forecast',
    pollutionSourceText: '{0} is based on modelling done by the Norwegian Meteorological Institute and is updated four times a day.',
    levelHeader: 'Air pollution',
    pollutionLevelH2: 'Pollution level',
    pollutionLevelP1: 'The pollution levels are shown through colour codes (pollution classes).',
    pollutionLevelP2: 'Learn more about what the different pollution classes mean and which effect the pollution level has for your health. {0}.',
    pollutionLevelURLText: 'Read about health advice and pollution classes here.',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
