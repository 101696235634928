import React, { Component } from 'react';
import ComponentNameConverter from 'components/elements/component-name-converter/ComponentNameConverter';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';

import './ShowExplanation.scss';
import Localization, {
  setLanguage
} from 'languages/components/map/MapLan';

class ShowExplanation extends Component {
  constructor(props) {
    super(props);

    setLanguage();
    this.toggleOpenMobileClass = this.toggleOpenMobileClass.bind(this);
    this.state = {
      /* Only show initially if not on mobile screen  */
      ShowExplanation: window.innerWidth > 768
    };
  }

  toggleOpenMobileClass() {
    this.setState(prevState => ({
      ShowExplanation: !prevState.ShowExplanation
    }));
  }

  render() {
    const { component } = this.props;
    if (this.props.error) {
      return (
        <ErrorMessage
          header={Localization.showExplanationErrorHeader}
          text={Localization.showExplanationErrorText}
          extraClass='c_error-message--no-component-explanation'
        />
      );
    }
    // If we don't have a component then show nothing.
    if (!component || !component.component) {
      return <div className='c_show-explanation c_show-explanation--hidden' />;
    }
    return (
      <div
        className={`c_show-explanation ${this.props.component.component &&
          this.props.component.component.toUpperCase() !== 'AQI' &&
          'c_show-explanation--gradient'} c_white-box ${
          this.props.filterMenuOpen ? 'c_show-explanation--open' : ' '
          }`}
      >
        {component.component && component.component.toLowerCase() === 'aqi' ? (
          <h3>{Localization.showExplanationPollutionClasses}</h3>
        ) : (
            <h3>
              {Localization.showExplanationMeasuredConcentration}{' '}
              <ComponentNameConverter>
                {component.component}
              </ComponentNameConverter>{' '}
              {component.unit}
            </h3>
          )}
        {component.component.toLowerCase() === 'aqi' ? (
          <ul>
            <li className='status--low'>
              <span />
              {component.ranges[0].textNo}
            </li>
            <li className='status--moderate'>
              <span />
              {component.ranges[1].textNo}
            </li>
            <li className='status--high'>
              <span />
              {component.ranges[2].textNo}
            </li>
            <li className='status--very-high'>
              <span />
              {component.ranges[3].textNo}
            </li>
            <li className='status--no-data'>
              <span />
              {Localization.showExplanationNoData}
            </li>
          </ul>
        ) : (
            <ul>
              <li className='status--low'>
                <span
                  style={{
                    background: `linear-gradient(to right, ${component.ranges[0].mapColorStart}, ${component.ranges[0].mapColorEnd})`
                  }}
                />
                {component.ranges[0].fromValue} - {component.ranges[0].toValue}
              </li>
              <li className='status--moderate'>
                <span
                  style={{
                    background: `linear-gradient(to right, ${component.ranges[1].mapColorStart}, ${component.ranges[1].mapColorEnd})`
                  }}
                />
                {component.ranges[1].fromValue} - {component.ranges[1].toValue}
              </li>
              <li className='status--high'>
                <span
                  style={{
                    background: `linear-gradient(to right, ${component.ranges[2].mapColorStart}, ${component.ranges[2].mapColorEnd})`
                  }}
                />
                {component.ranges[2].fromValue} - {component.ranges[2].toValue}
              </li>
              <li className='status--very-high'>
                <span
                  style={{
                    background: `linear-gradient(to right, ${component.ranges[3].mapColorStart}, ${component.ranges[3].mapColorEnd})`
                  }}
                />
                &#62; {component.ranges[3].fromValue}
              </li>
              <li className='status--no-data'>
                <span />
                {Localization.showExplanationNoData}
              </li>
            </ul>
          )}
      </div>
    );
  }
}

export default ShowExplanation;
