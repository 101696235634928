const initialState = {
  list: [],
  wmts: '',
  err: false,
  errmsg: '',
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case 'MAP_GET_STATIONS_SUCCESS_SAGA':
      return Object.assign({}, state, { list: action.list, err: false, errmsg: '' });
    case 'MAP_GET_STATIONS_FAILED_SAGA':
      return Object.assign({}, state, { list: [], err: true, errmsg: action.message });
    case 'DISPATCH_GET_WMTSLAYER_SUCCESS_SAGA':
      return Object.assign({}, state, { wmts: action.wmts, err: false, errmsg: '' });
    case 'DISPATCH_GET_WMTSLAYER_FAILED_SAGA':
      return Object.assign({}, state, { wmts: '', err: true, errmsg: action.message });
    default:
      return state;
  }
}
