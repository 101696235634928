import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const initialState = {
  initialLength: history.length,
};

export default function historyReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
