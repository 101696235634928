import React from 'react';
import CreativeCommons from 'components/info/creative-commons/CreativeCommons';
import Logos from './Logos';
import './Footer.scss';

const Footer = props => (
  <footer className="c_footer">
    <div className="g_center-content">
      <Logos includePublisherMicrodata={props.includePublisherMicrodata} />
    </div>
    <CreativeCommons
      extraClass="c_creative-commons--fullwidth"
      privacyLink
    />
  </footer>
);

export default Footer;
