import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated === true
        ? <Component {...props} />
        : (
          <Redirect
            push
            exact
            to={{
              pathname: '/admin/login',
              state: { from: props.location },
            }}
          />
        )
    )}
  />
);


function mapStateToProps(state) {
  return {
    isAuthenticated: state.securityReducer.isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps, {})(PrivateRoute));
