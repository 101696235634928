import React from 'react';

import './ButtonToggle.scss';

const ButtonToggle = (props) => {
  let classes = `button button--toggle ${props.extraClass ? `${props.extraClass}` : ''}`;
  if (props.active) {
    classes += ' active';
  }
  return (
    <button type="button" className={classes} onClick={props.onClick}>{props.children}</button>
  );
};

export default ButtonToggle;
