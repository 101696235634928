import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    myPosition: 'Min posisjon',
    searchFieldText: 'Søk etter tettsted, by, område etc',
    plotJan: 'Jan',
    plotFeb: 'Feb',
    plotMar: 'Mar',
    plotApri: 'Apri',
    plotMay: 'Mai',
    plotJun: 'Jun',
    plotJul: 'Jul',
    plotAug: 'Aug',
    plotSep: 'Sep',
    plotOct: 'Okt',
    plotNov: 'Nov',
    plotDec: 'Des',
    plotKl: 'Kl. ',
    plotNow: '(Nå)',
    last2Days: 'Siste 2 døgn',
    last7Days: 'Siste 7 dager',
    last14Days: 'Siste 14 dager',
    NoDayMean: 'Det finnes ikke forurensningsklasse for døgnmiddel for {0}. Derfor synes ikke fargeskalaen i bakgrunnen på denne grafen.',
    plotMunicipalityText: 'Grafen viser den høyeste varslede luftforurensningen for et enkelt område innenfor kommunen. Se på kartet eller velg et mer spesifikt sted.',
    ComponentInfo: {
      twoDays: {
        o3: 'Gjennomsnittlig konsentrasjon av bakkenær ozon i mikrogram per kubikkmeter per time (timesmiddel)',
        no2: 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per time (timesmiddel)',
        pm10: 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per time (timesmiddel)',
        'pm2.5': 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per time (timesmiddel)',
        so2: 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per time (timesmiddel)',
      },
      fourteenDays: {
        o3: 'Gjennomsnittlig konsentrasjon av bakkenær ozon i mikrogram per kubikkmeter per døgn (døgnmiddel)',
        no2: 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per døgn (døgnmiddel)',
        pm10: 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per døgn (døgnmiddel)',
        'pm2.5': 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per døgn (døgnmiddel)',
        so2: 'Gjennomsnittlig konsentrasjon i mikrogram per kubikkmeter per døgn (døgnmiddel)',
      },
    },
    downloadImage: 'Last ned bilde',
    downloadExcel: 'Last ned Excel',
    forecastFileName: 'Varslet luftkvalitet for',
    aqi: 'Samlet luftforurensning',
  },
  en: {
    myPosition: 'My position',
    searchFieldText: 'Search for village, city, area, etc.',
    plotJan: 'Jan',
    plotFeb: 'Feb',
    plotMar: 'Mar',
    plotApri: 'Apri',
    plotMay: 'May',
    plotJun: 'Jun',
    plotJul: 'Jul',
    plotAug: 'Aug',
    plotSep: 'Sep',
    plotOct: 'Oct',
    plotNov: 'Nov',
    plotDec: 'Dec',
    plotKl: '',
    plotNow: '(Now)',
    last2Days: 'Previous 2 days',
    last7Days: 'Previous 7 days',
    last14Days: 'Previous 14 days',
    NoDayMean: 'There is no pollution class for daily average for {0}. That is why there is no colour scale in the background of the graphs.',
    plotMunicipalityText: 'The graph shows the forecast for the highest polluted single area within the municipality. Visit the map or choose a more specific area.',
    ComponentInfo: {
      twoDays: {
        o3: 'Average concentration of ground level ozone in micrograms per cubic metre per hour (hourly average).',
        no2: 'Average concentration in micrograms per cubic metre per hour (hourly average).',
        pm10: 'Average concentration in micrograms per cubic metre per hour (hourly average).',
        'pm2.5': 'Average concentration in micrograms per cubic metre per hour (hourly average).',
        so2: 'Average concentration in micrograms per cubic metre per hour (hourly average).',
      },
      fourteenDays: {
        o3: 'Average concentration of ground level ozone in micrograms per cubic metre per day (daily average).',
        no2: 'Average concentration in micrograms per cubic metre per day (daily average).',
        pm10: 'Average concentration in micrograms per cubic metre per day (daily average).',
        'pm2.5': 'Average concentration in micrograms per cubic metre per day (daily average).',
        so2: 'Average concentration in micrograms per cubic metre per day (daily average).',
      },
    },
    downloadImage: 'Download image',
    downloadExcel: 'Download Excel',
    forecastFileName: 'Air quality forecast for',
    aqi: 'Total air pollution',
  },

});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
