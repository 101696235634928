import React from 'react';

import AdminHeader from 'components/admin/admin-header/AdminHeader';
import TextWarningList from 'components/admin/text-warning-list/TextWarningList';
import './Admin.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CancelableFetchPromise from 'js/CancelablePromise';
import { confirmAlert } from 'react-confirm-alert';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import { trackPageView } from 'js/TrackPage.js';
import Spinner from 'components/elements/spinner/Spinner';
import Localization, { setLanguage } from 'languages/screens/admin/AdminLan';
import { NavLink } from 'react-router-dom';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.language = setLanguage();

    this.onLogOutClick = this.onLogOutClick.bind(this);
    this.getForecastList = this.getForecastList.bind(this);

    this.taskGetForecastList = null;
    this.taskCopyForecast = null;
    this.eventHub = props.eventHub;
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.copyForecast = this.copyForecast.bind(this);
    this.deleteForecast = this.deleteForecast.bind(this);
    this.taskDeleteForecast = null;
    this.state = {
      showWarningList: true,
      forecastList: [],
      isLoading: true,
      userDropdownOpen: true,
      error: false,
      errorMessage: {
        header: '',
        text: '',
      },
    };
  }

  componentDidMount() {
    this.getForecastList();
    // trackPageView('Screen / Admin', this.props.location.pathname);
  }

  componentWillUnmount() {
    if (this.getForecastList) {
      this.taskGetForecastList.cancel();
    }
    if (this.taskCopyForecast) {
      this.taskCopyForecast.cancel();
    }
    if (this.taskDeleteForecast) {
      this.taskDeleteForecast.cancel();
    }
  }


  onLogOutClick() {
    this.props.dispatchLogout();
  }

  onCopyClick(forecast) {
    confirmAlert({
      title: Localization.confirmCopyTitle,
      message: Localization.formatString(Localization.confirmCopyMessage, forecast.description, forecast.municipality, forecast.user),
      buttons: [
        {
          label: Localization.confirmYes,
          onClick: () => this.copyForecast(forecast),
        },
        {
          label: Localization.confirmNo,
          onClick: () => {},
        },
      ],
    });
  }

  onDeleteClick(forecast) {
    confirmAlert({
      title: Localization.confirmDeleteTitle,
      message: Localization.formatString(Localization.confirmDeleteMessage, forecast.description, forecast.municipality, forecast.user),
      buttons: [
        {
          label: Localization.confirmYes,
          onClick: () => this.deleteForecast(forecast.id),
        },
        {
          label: Localization.confirmNo,
          onClick: () => {},
        },
      ],
    });
  }

  async getForecastList() {
    this.setState({ isLoading: true });

    let json = null;

    try {
      const headers = {

        'Accept-Language': this.language,
      };
      if (this.props.token) { headers.Authorization = `Bearer ${this.props.token}`; }
      this.taskGetForecastList = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/forecasts`,
        {
          headers,
        }));
      json = await this.taskGetForecastList.promise;
    } catch (e) {
      this.setState({
        error: true,
        isLoading: false,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json ? json.status : 'Ukjent feil'),
          text: Localization.errorGetMessage,
        },
      });
    }
    if (json && json.status === 401) {
      this.eventHub.emit('logout');
    }

    if (!json || json.status !== 200) {
      this.setState({
        error: true,
        isLoading: false,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json ? json.status : 'Ukjent feil'),
          text: Localization.errorGetMessage,
        },
      });
    }
    json = json ? json.body : null;
    if (json) { this.setState({ forecastList: json, error: false, isLoading: false }); }
  }

  async copyForecast(forecast) {
    forecast.municipality = forecast.municipalityId;
    this.taskCopyForecast = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/forecast`,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
          'Accept-Language': this.language,
        },
        method: 'POST',
        body: JSON.stringify(forecast),
      }), false);

    const json = await this.taskCopyForecast.promise;

    if (json.status === 401) {
      this.props.dispatchLogout();
      return;
    }

    if (json.status !== 200) {
      this.setState({
        error: true,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json.status),
          text: Localization.errorSendMessage,
        },
      });
      return;
    }

    this.setState({ error: false });

    // Entry copied. Update list.
    this.getForecastList();
  }

  async deleteForecast(id) {
    this.taskGetForecastList = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/forecast/${id}`,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
          'Accept-Language': this.language,
        },
        method: 'DELETE',
      }), false);
    const json = await this.taskGetForecastList.promise;

    if (json.status === 401) {
      this.props.dispatchLogout();
      return;
    }

    if (json.status !== 200) {
      this.setState({
        error: true,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json.status),
          text: 'Kunne ikke slette varsling.',
        },
      });
      return;
    }

    this.setState({ error: false });

    // Entry deleted. Update list.
    this.getForecastList();
  }

  render() {
    return (
      <div className="t_admin">

        {this.state.error
          && (
          <div className="g_center-content">
            <ErrorMessage
              header={this.state.errorMessage.header}
              text={this.state.errorMessage.text}
            />
          </div>
          )
        }

        {/* Etter at man har logget inn */}
        {this.state.showWarningList
        && (
          <div className="g_center-content">
            {this.state.isLoading
              ? <Spinner />
              : (
                <TextWarningList
                  localization={Localization}
                  list={this.state.forecastList}
                  onCreateNewWarningClick={() => this.props.history.push('/admin/forecast/create')}
                  onClickCopy={this.onCopyClick}
                  onClickDelete={this.onDeleteClick}
                />
              )
            }
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.securityReducer.token,
    user: state.securityReducer.username,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogin: login => dispatch({ type: 'SECURITY_LOGIN', login }),
    dispatchLogout: () => dispatch({ type: 'SECURITY_LOGOUT' }),
  };
}


// export default connect(mapStateToProps, mapDispatchToProps)(Admin);
export default withRouter(Admin);
