import CancelableFetchPromise from 'js/CancelablePromise';


export function getMetsWmtsPromise(language, layerGroups, layerName, map, callback) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/mapcapabilities/mets`,
    {
      headers: {
        'Accept-Language': language,
      },
    }), false, true);
  wrappedPromise.promise.then((result) => {
    const layerConfig = layerGroups.filter(l => l.groupName === layerName)[0].layers.map(l => ({
      name: l.name,
      show: l.show,
    }));
    const metsWmts = result.body;
    const times = map.addMetsWmts(layerConfig, metsWmts);
    if (callback) {
      callback(times);
    }
  });
  return wrappedPromise;
}

export function getMetsHaloWmtsPromise(layerGroups, layerName, map) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/mapcapabilities/mets`), false, true);
  wrappedPromise.promise.then((result) => {
    const layerConfig = layerGroups.filter(l => l.groupName === layerName)[0].layers.map(l => ({
      name: l.name,
      show: l.show,
    }));
    const metsWmts = result.body;
    map.addMetsWms(layerConfig, metsWmts);
  });
  return wrappedPromise;
}

export function getCamerasPromise(map, popup, callback) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/cctvs`));
  wrappedPromise.promise.then((result) => {
    const cameras = result.body;
    map.addCameras(cameras);
    const overlay = map.addOverlayToMap(popup);
    callback(overlay);
  });
  return wrappedPromise;
}

export function getAQIPromise(language, callback) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/forecast/aqi`,
    {
      headers: {
        'Accept-Language': language,
      },
    }));
  wrappedPromise.promise.then((result) => {
    const aqis = result.body;
    const complist = [];
    aqis.forEach((c) => {
      const name = c.component;
      const obj = {
        component: name,
        unit: c.unit,
        ranges: c.ranges,
      };

      complist.push(obj);
    });

    callback(complist);
  });
  return wrappedPromise;
}

export function getGeoDataWmtsPromise(language, map) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/mapcapabilities/geodata`,
    {
      headers: {
        'Accept-Language': language,
      },
    }), false, true);
  wrappedPromise.promise.then((result) => {
    const wmts = result.body;
    map.addGeoDataWmts(wmts);
  });
  return wrappedPromise;
}

export function getStationsPromise(language, map, showStations, callback) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/measuringstation/all`,
    {
      headers: {
        'Accept-Language': language,
      },
    }));
  wrappedPromise.promise.then((result) => {
    const stations = result.body;
    map && map.addStationsToMap(stations, showStations);

    if (callback) {
      callback(stations);
    }
  });
  return wrappedPromise;
}

export function getAdminStationsPromise(language, map, showStations, callback) {
  const wrappedPromise = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/measuringstation/alladminmap`,
    {
      headers: {
        'Accept-Language': language,
      },
    }));
  wrappedPromise.promise.then((result) => {
    const stations = result.body;
    map && map.addStationsToMap(stations, showStations);

    if (callback) {
      callback(stations);
    }
  });
  return wrappedPromise;
}

export function getFirstActiveLayerWithComponent(layerGroups, selectedLayers) {
  const allLayers = layerGroups.reduce((arr, elem) => [...arr, ...elem.layers], []);
  const activeLayersWithComponent = allLayers.filter(l => selectedLayers.some(x => x === l.name) && l.component);
  if (activeLayersWithComponent.length > 0) {
    return activeLayersWithComponent[0];
  }

  return null;
}
