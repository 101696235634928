import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  "no-nb": {
    adminHeaderTitle: 'Luftkvalitet',
    adminHeaderMyPage: 'Min side',
    adminHeaderLogout: 'Logg ut',
    adminWarningListHeader: 'Tekstvarsler',
    adminWarningListForecast: 'Varsel',
    adminWarningListPeriod: 'Periode',
    adminWarningListMunicipality: 'Kommune',
    adminWarningListCopy: 'Kopier',
    adminWarningListDelete: 'Slett',
    adminWarningListCopyLine: 'Kopier linje',
    adminWarningListDeleteLine: 'Slett linje',
    adminWarningListCreate: 'Opprett nytt varsel',
    confirmCopyTitle: 'Kopier varsel',
    confirmCopyMessage: 'Er du sikker på at du vil kopiere {0} i {1} lagd av {2}?',
    confirmYes: 'Ja',
    confirmNo: 'Nei',
    confirmDeleteTitle: 'Slett varsel',
    confirmDeleteMessage: 'Er du sikker på at du vil slette {0} i {1} lagd av {2}?',
    errorHeader: '{0} Feil',
    errorGetMessage: 'Kunne ikke hente data.',
    errorSendMessage: 'Kunne ikke sende form.',
    errorDeleteMessage: 'Kunne ikke slette varsling.',
    errorGetMunicipalitiesMessage: 'Kunne ikke hente kommune liste.',
  },
  en: {
    adminHeaderTitle: 'Air quality',
    adminHeaderMyPage: 'My page',
    adminHeaderLogout: 'Log out',
    adminWarningListHeader: 'Text forecasts',
    adminWarningListForecast: 'Forecast',
    adminWarningListPeriod: 'Period',
    adminWarningListMunicipality: 'Municipality',
    adminWarningListCopy: 'Copy',
    adminWarningListDelete: 'Delete',
    adminWarningListCopyLine: 'Copy row',
    adminWarningListDeleteLine: 'Delete row',
    adminWarningListCreate: 'Create new forecast',
    confirmCopyTitle: 'Copy forecast',
    confirmCopyMessage: 'Are you sure you want to copy {0} in {1} by {2}?',
    confirmYes: 'Yes',
    confirmNo: 'No',
    confirmDeleteTitle: 'Delete forecast',
    confirmDeleteMessage: 'Are you sure you want to delete {0} in {1] by {2}?',
    errorHeader: '{0} Error',
    errorGetMessage: 'Could not retrieve data.',
    errorSendMessage: 'Could not send form.',
    errorDeleteMessage: 'Could not delete forecast.',
    errorGetMunicipalitiesMessage: 'Could not retrieve municipality list.',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
