import React from 'react';
import { Link } from 'react-router-dom';
import './TextWarningList.scss';
import ButtonPrimary from 'components/elements/button/button-primary/ButtonPrimary';
import ButtonDelete from 'components/elements/button/button-delete/ButtonDelete';
import ButtonCopy from 'components/elements/button/button-copy/ButtonCopy';

const MaxDescriptionLength = 120;
const TextWarningList = props => (
  <div className="c_text-warning-list">
    <h1>{props.localization.adminWarningListHeader}</h1>
    <div className="c_text-warning-list__first-button">
      <ButtonPrimary onClick={props.onCreateNewWarningClick}>Opprett nytt varsel</ButtonPrimary>
    </div>
    <table className="table table--responsive">
      <thead>
        <tr>
          <th className="warning-title">{props.localization.adminWarningListForecast}</th>
          <th className="warning-date">{props.localization.adminWarningListPeriod}</th>
          <th className="warning-municipality">{props.localization.adminWarningListMunicipality}</th>
          <th className="warning-copy">{props.localization.adminWarningListCopy}</th>
          <th className="warning-delete">{props.localization.adminWarningListDelete}</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map(forecast => (
          <tr key={forecast.id}>
            <td data-th="Tittel">
              <Link to={`/admin/forecast/edit/${forecast.id}`} title="Se eller rediger dette varselet">
                {(forecast.description.length > MaxDescriptionLength
                  ? `${forecast.description.substring(0, MaxDescriptionLength)}...`
                  : forecast.description)}
              </Link>
            </td>
            <td data-th="Periode">
              {new Date(forecast.startTime).toLocaleString('nb-NO')}
              {' '}
                -
              {' '}
              {new Date(forecast.endTime).toLocaleString('nb-NO')}
            </td>
            <td data-th="Kommune">{forecast.municipality}</td>
            <td data-th="Kopier">
              <ButtonCopy
                onClick={() => props.onClickCopy(forecast)}
              >
                {props.localization.adminWarningListCopyLine}
              </ButtonCopy>
            </td>
            <td data-th="Slett">
              <ButtonDelete
                onClick={() => props.onClickDelete(forecast)}
              >
                {props.localization.adminWarningListDeleteLine}
              </ButtonDelete>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="c_text-warning-list__last-button">
      <ButtonPrimary onClick={props.onCreateNewWarningClick}>{props.localization.adminWarningListCreate}</ButtonPrimary>
    </div>
  </div>
);
export default TextWarningList;
