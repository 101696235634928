import React from 'react';
import Localization, { setLanguage } from 'languages/components/places/PlacesLan';
import SuggestionItem from './SuggestionItem';
import MyLocationSuggestionItem from './MyLocationSuggestionItem';

const Suggestions = (props) => {
  setLanguage();
  return (
    <div className="list__results">
      <ul className="reults__places">
        <MyLocationSuggestionItem
          myLocation={props.myLocation}
          onClick={props.myPositionClick}
          isLoading={props.myPositionLoading}
          suggestionFocus={props.focusIndex === 0}
        />

        {props.suggestions.length > 0 && <li className="results__header results__header--places">{Localization.placesHeader}</li>}
        {props.suggestions.map((val, index) => (
          <SuggestionItem
            key={val.name + val.type}
            name={val.name}
            type={val.type}
            suggestionFocus={index === props.focusIndex - 1}
            onClick={() => props.suggestionClick({ latitude: val.latitude, longitude: val.longitude, zone: val.zone }, `${val.name} (${val.type})`)}
          />
        ))}
      </ul>
      <ul className="results__municipalities">
        {props.municipalities.length > 0 && <li className="results__header">{Localization.municipalitiesHeader}</li>}
        {props.municipalities.map((val, index) => (
          <SuggestionItem
            key={val.name + val.type}
            name={val.name}
            type={val.county}
            suggestionFocus={index + props.suggestions.length === props.focusIndex - 1}
            onClick={() => props.suggestionClick({ latitude: val.latitude, longitude: val.longitude, zone: val.zone }, `${val.name} (${val.type})`)}
          />
        ))}
      </ul>
    </div>);
};

export default Suggestions;
