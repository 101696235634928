import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './MostVisitedPlaces.scss';
import Localization, { setLanguage } from 'languages/components/forecast/ForecastLan';

class MostVisitedPlaces extends Component {
  static createPlaceLink(place) {
    let url = `/varsling/${place.county}/${place.municipality}`;
    if (place.part) {
      url += '/' + place.part;

      if (place.area) {
        url += '/' + place.area;
      }
    }

    return url;
  }

  constructor(props) {
    super(props);
    setLanguage();
  }

  render() {
    return (
      <div className="c_most-visited-places e_link-list">
        <h3 className="header">{Localization.mostVisitedPlaces}</h3>
        <ul>
          {this.props.mostVisitedPlaces && this.props.mostVisitedPlaces.map(visit => (
            <li key={visit[1].place}>
              <Link to={MostVisitedPlaces.createPlaceLink(visit[1].search)}>{visit[1].place}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MostVisitedPlaces;
