import React, { Component } from 'react';
import PollutionLevelExplanation from 'components/info/pollution-level-explanation/PollutionLevelExplanation';
import CancelableFetchPromise from 'js/CancelablePromise';
import Spinner from 'components/elements/spinner/Spinner';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import info from 'json/Artikler.json';
import './PollutionLevel.scss';
import Localization, { setLanguage } from 'languages/components/info/InfoLan';

class PollutionLevel extends Component {
  constructor(props) {
    super(props);

    this.language = setLanguage();
    this.getPollutionLevels = this.getPollutionLevels.bind(this);
    this.taskGetPollutionLevels = null;
    this.state = { pollutionLevels: [], error: false };
  }

  componentDidMount() {
    this.getPollutionLevels();
  }

  componentWillUnmount() {
    if (this.taskGetPollutionLevels) {
      this.taskGetPollutionLevels.cancel();
    }
  }

  async getPollutionLevels() {
    try {
      this.taskGetPollutionLevels = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/forecast/aqi`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));
      const res = (await this.taskGetPollutionLevels.promise).body;

      this.setState({ pollutionLevels: res[0].ranges, error: false });
    } catch (e) {
      // console.log('Failed to fetch api values', e);
      this.setState({ pollutionLevels: [], error: true });
    }
  }


  render() {
    let comp = <Spinner />;

    if (this.state.error) {
      comp = <ErrorMessage header={Localization.pollutionLevelErrorHeader} text={Localization.pollutionLevelErrorText} />;
    } else if (this.state.pollutionLevels.length > 0) {
      comp = <PollutionLevelExplanation data={this.state.pollutionLevels} direction="vertical" />;
    }

    return (
      <div className="c_pollution-level c_white-box">
        <div>
          <div>
            <h2>{Localization.pollutionLevelH2}</h2>
            <p>{Localization.pollutionLevelP1}</p>
            <p>
              {Localization.formatString(Localization.pollutionLevelP2, <a href={info.helserad}>{Localization.pollutionLevelURLText}</a>)}
            </p>
          </div>
        </div>
        <div>
          <h3>{Localization.levelHeader}</h3>
          {comp}
        </div>
      </div>
    );
  }
}

export default PollutionLevel;
