import React, { Component } from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import CancelableFetchPromise from 'js/CancelablePromise';
import Localization, { setLanguage } from 'languages/components/forecast/ForecastLan';
import './ExpertQuote.scss';


class ExpertQuote extends Component {
  static transformStringToURL(text) {
    const regex = /(https?:\/\/[^\s]+)/g;

    const textArray = text.split(/\s/g);
    const newText = textArray.map((t, i) => {
      if (t.match(regex)) {
        // Ensure no comma or punctuation at the end.
        if (t.substring(t.length - 1) === ',' || t.substring(t.length - 1) === '.') {
          return <a key={i} href={t.substring(0, t.length - 1)}>{t.substring(0, t.length - 1)}</a>;
        }
        return <a key={t} href={t}>{t}</a>;
      }
      return `${t} `;
    });

    return newText;
  }

  constructor(props) {
    super(props);

    this.language = setLanguage();
    this.state = { isLoading: true, data: null };
    this.getExpertQuote = this.getExpertQuote.bind(this);
    this.taskFetchExpertQuote = null;
  }

  componentDidMount() {
    this.getExpertQuote();
  }

  componentWillUnmount() {
    if (this.taskFetchExpertQuote) {
      this.taskFetchExpertQuote.cancel();
    }
  }


  async getExpertQuote() {
    this.taskFetchExpertQuote = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/forecasts/${encodeURIComponent(this.props.county)}/${encodeURIComponent(this.props.municipality)}`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));
    const json = (await this.taskFetchExpertQuote.promise).body;

    this.setState({ isLoading: false, data: json });
  }


  render() {
    return (
      <div>
        {this.state.isLoading ? <Spinner />
          : (
            <div>
              {this.state.data.length > 0
                ? (
                  <div className="c_expert-quote">
                    <div className="c_expert-quote__quote e_white-box">
                      {this.state.data.map(data => (
                        <div key={data.id}>
                          <p>{ExpertQuote.transformStringToURL(data.description)}</p>
                          { data.url && data.url.length > 0 && (
                          <p>
                            <a href={data.url} target="_blank" rel="noopener noreferrer">
                              {' '}
                              {Localization.manualForecastUrlText}
                              {' '}
                            </a>
                          </p>
                          )}
                          <span>
                            {data.user}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : ''}
            </div>
          )}
      </div>
    );
  }
}

export default ExpertQuote;
