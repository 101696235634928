import React from 'react';
import Localization, { setLanguage } from 'languages/components/weatherstation/WeatherStationLan';
import './WeatherStationSource.scss';

const WeatherStationSource = () => {
  setLanguage();
  return (
    <div className="c_weather-station-source">
      <span>
        {Localization.source}
      </span>
    </div>
  );
};

export default WeatherStationSource;
