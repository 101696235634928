/* Eslint hints: */
/* global navigator   */
/* TBD test on lots of browsers!! */
const GetMyPosition = async (cb, cberr) => {
  /* Anders: cb2 er et forsøk på at fixe: 5:31  error
   'cb' is already declared in the upper scope  no-shadow */
  /* Please doublecheck, og giv et sigende navn! */
  function errorHandling(err, cbError) {
    let error = 'Feil oppstod ved henting av lokasjon.';
    switch (err.code) {
      case err.PERMISSION_DENIED:
        error = 'Brukeren godtok ikke geolokasjon.';
        break;
      case err.POSITION_UNAVAILABLE:
        error = 'Fant ikke geo posisjon.';
        break;
      case err.TIMEOUT:
        error = 'Forespørsel om lokasjon utløp.';
        break;
      case err.UNKNOWN_ERROR:
        error = 'En ukjent feil oppstod.';
        break;
      default:
        error = 'En ukjent feil oppstod.';
        break;
    }
    cbError(error);
  }


  let ret = '';
  if (navigator.geolocation) {
    ret = await navigator.geolocation.getCurrentPosition(
      (pos) => {
        cb({ latitude: pos.coords.latitude.toFixed(5), longitude: pos.coords.longitude.toFixed(5) });
      }, (err) => { errorHandling(err, cberr); },
    );
  } else {
    ret = 'Din nettleser støtter ikke geolokasjon.';
  }
  return ret;
};


export default GetMyPosition;
