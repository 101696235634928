import React from 'react';
import './Breakpoints.scss';

const Font = () => (
  <div className="c_styleguide__item c_styleguide__item--breakpoints">
    <h2>Brytningspunkter</h2>
    <p>Eller breakpoints som det også kalles. Det er breddene som er satt for når designet skal endres. Brukes for å få et responsivt design, slik at den samme nettsiden passer på både smale mobiler og store skjermer. Vi starter på de minste breddene, og etterhvert som det trengs tillegsstyling legges det på "oppover i bredden".</p>
    <p>Vi har brukt følgende bredder:</p>
    <ul>
      <li>Fra 500px</li>
      <li>Fra 768px</li>
      <li>Fra 1024px</li>
      <li>Fra 1200px</li>
    </ul>

  </div>
);

export default Font;
