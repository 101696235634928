import React, { Component } from 'react';
// import Localization, {
//   setLanguage,
// } from 'languages/components/elements/ElementsLan';
import { setLanguage } from 'languages/components/elements/ElementsLan';
import CancelableFetchPromise from 'js/CancelablePromise';
import './StatusBox.scss';

class StatusBox extends Component {
  constructor(props) {
    super(props);

    this.lan = setLanguage();

    this.state = {
      message: '',
      hasStatus: false,
    };
    this.taskGetStatus = null;
  }

  async componentDidMount() {
    this.taskGetStatus = CancelableFetchPromise(
      fetch(`${process.env.REACT_APP_API}/api/cms/status`, {
        headers: {
          'Accept-Language': this.language,
        },
      }),
    );

    try {
      const res = await this.taskGetStatus.promise;
      this.setState({
        hasStatus: res.body.statusExists,
        message: { __html: res.body.message },
      });
    } catch (e) {
      // Error!
    }
  }

  componentWillUnmount() {
    if (this.taskGetStatus) {
      this.taskGetStatus.cancel();
    }
  }

  render() {
    if (!this.state.hasStatus) {
      return '';
    }

    return (
      <div className="c_status">
        <div
          className="g_center-content"
          dangerouslySetInnerHTML={this.state.message}
        />
      </div>
    );
  }
}

export default StatusBox;
