import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    buttonBack: 'Tilbake',
    municipalityStations: municipality => `Målestasjoner i ${municipality} kommune`,
  },
  en: {
    buttonBack: 'Back',
    municipalityStations: municipality => `Measuringstations in ${municipality} municipality`,
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
