import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    errorMessageHeader: 'Innlastningsfeil',
    errorMessageText: 'Kunne ikke laste inn artikler.',
    articleListHeader: 'Lær mer om lokal luftkvalitet',
    aboutListHeader: 'Om tjenesten',
  },
  en: {
    errorMessageHeader: 'Loading error',
    errorMessageText: 'Could not load articles.',
    articleListHeader: 'Learn more about local air quality',
    aboutListHeader: 'About',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
