
import React from 'react';
import Breadcrumbs from 'components/elements/breadcrumbs/Breadcrumbs';
import MapPlotSwitcher from 'components/map/map-plot-switcher/MapPlotSwitcher';
import PlotSwitcherForecast from 'components/plots/plots-switcher/PlotSwitcherForecast';
import AirPollutionBox from 'components/forecast/air-pollution/AirPollutionBox';
import AirPollutionSource from 'components/info/air-pollution-source/AirPollutionSource';
import LazySuspense from 'components/lazy/LazySuspense';
import NearbySubPlacesTable from 'components/forecast/nearby-subplaces/NearbySubPlacesTable';
import MostVisitedPlaces from 'components/forecast/most-visited-places/MostVisitedPlaces';

const OLMapForecast = React.lazy(() => import('components/map/ol-map/ol-map-forecast/OLMapForecast'));
const OLMapWeatherStation = React.lazy(() => import('components/map/ol-map/ol-map-weatherstation/OLMapWeatherstation'));

const SpecifiedLocationInformation = props => (
  <div className="g_center-content c_specified-location-information">
    <Breadcrumbs smallHeading={props.localization.smallHeading} breadcrumbs={props.breadcrumbs} latitude={props.latitude} longitude={props.longitude} />
    <AirPollutionBox
      alert={props.alert}
      municipality={props.municipality}
      county={props.breadcrumbs.fylke}
      areaClass={props.areaClass}
    />
    <AirPollutionSource alert={props.alert} />
    <MapPlotSwitcher
      components={props.components}
      layer="aqi"
      latitude={props.latitude}
      longitude={props.longitude}
      history={props.history}
      viewMap
    >
      <PlotSwitcherForecast
        areaname={props.areaname}
        components={props.components}
        nowIndicator={props.nowIndicator}
        areaClass={props.areaClass}
      />

      <LazySuspense>
        <OLMapForecast
          latitude={props.latitude}
          longitude={props.longitude}
          areaClass={props.areaClass}
        />
      </LazySuspense>

      <LazySuspense>
        <OLMapWeatherStation
          history={props.history}
          longitude={props.longitude}
          latitude={props.latitude}
          areaClass={props.areaClass}
        />
      </LazySuspense>

    </MapPlotSwitcher>

    {props.nearbyplaces.areas.length > 0 && (
    <div className="c_white-box">
      <div className="c_table-and-list">
        <h2>{props.localization.areasNearby}</h2>
        <NearbySubPlacesTable data={props.nearbyplaces} />
        <MostVisitedPlaces mostVisitedPlaces={props.mostVisitedPlaces} />
      </div>
    </div>
    )}
  </div>

);

export default SpecifiedLocationInformation;
