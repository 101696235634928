import React from 'react';
import { Link } from 'react-router-dom';
import Localization, { setLanguage } from 'languages/components/weatherstation/WeatherStationLan';

import './MostVisitedWeatherStations.scss';

const MostVisitedWeatherStations = (props) => {
  setLanguage();
  return (
    <div className="c_most-visited-places e_link-list">
      <h3 className="caps-heading">{Localization.mostVisited}</h3>
      <ul>
        {props.mostVisitedStations && props.mostVisitedStations.map(visit => (
          <li key={visit[1].station}>
            <Link to={`/maalestasjon/${visit[1].station}`}>
              {`${visit[1].station}, ${visit[1].stationArea} (${visit[1].stationMunicipality.toUpperCase()})`}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MostVisitedWeatherStations;
