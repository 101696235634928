import React, { Component } from 'react';
import Spinner from 'components/elements/spinner/Spinner';
// import { setLanguage } from 'languages/screens/module/ModuleLan';
import CancelableFetchPromise from 'js/CancelablePromise';
import { Link } from 'react-router-dom';
import 'screens/forecast/SpecifiedLocation.scss';
import PollutionLevelExplanation from 'components/info/pollution-level-explanation/PollutionLevelExplanation';
import ArticleInfo from 'json/Artikler.json';
import 'components/map/map-plot-switcher/MapPlotSwitcher.scss';
import LazySuspense from 'components/lazy/LazySuspense';
import Localization, { setLanguage } from 'languages/components/map/MapLan';
import ButtonLink from 'components/elements/button/button-link/ButtonLink';

const HeadingWithLine = React.lazy(() => import('components/elements/heading-with-line/HeadingWithLine'));
const OLMapForecast = React.lazy(() => import('components/map/ol-map/ol-map-forecast/OLMapForecast'));
const PlotSwitcherForecast = React.lazy(() => import('components/plots/plots-switcher/PlotSwitcherForecast'));
const ArticleCategoryList = React.lazy(() => import('components/articles/ArticleCategoryList'));
const NearbySubPlacesTable = React.lazy(() => import('components/forecast/nearby-subplaces/NearbySubPlacesTable'));
const AirPollutionBox = React.lazy(() => import('components/forecast/air-pollution/AirPollutionBox'));
const AirPollutionSource = React.lazy(() => import('components/info/air-pollution-source/AirPollutionSource'));
const ErrorMessage = React.lazy(() => import('components/elements/error-message/ErrorMessage'));

class ModuleScreen extends Component {
  static updateModules(modules) {
    const obj = {
      map: false,
      plots: false,
      articles: false,
      nearby: false,
      heading: false,
      pollution: false,
      latitude: null,
      longitude: null,
      mapIsClicked: false,
      buttonText: Localization.mapPlotSwitcherActivateButton,
      name: {
        areaname: '',
        municipality: '',
        county: ''
      },
      plotInfo: {
        components: [],
        error: false,
        now: '',
      },
      nearbyList: [],
      nearbyError: false,
      alert: null,
    };
    obj.isLoading = true;
    if (modules.toLowerCase().includes('map')) {
      obj.map = true;
    }

    if (modules.toLowerCase().includes('plots')) {
      obj.plots = true;
    }

    if (modules.toLowerCase().includes('articles')) {
      obj.articles = true;
    }

    if (modules.toLowerCase().includes('nearby')) {
      obj.nearby = true;
    }

    if (modules.toLowerCase().includes('heading')) {
      obj.heading = true;
    }

    if (modules.toLowerCase().includes('pollution')) {
      obj.pollution = true;
    }

    return obj;
  }

  constructor(props) {
    super(props);
    this.language = setLanguage();
    this.state = ModuleScreen.updateModules(props.match.params.modules);
    this.loadModules = this.loadModules.bind(this);
    this.toggleMapFunctions = this.toggleMapFunctions.bind(this);
    this.taskFetchPlace = null;
    this.taskFetchSubplace = null;
    this.search = {
      zone: props.match.params.part ? '' : 'kommune',
    };
  }

  componentDidMount() {
    this.loadModules();
  }

  componentWillUnmount() {
    if (this.taskFetchPlace) {
      this.taskFetchPlace.cancel();
    }
    if (this.taskFetchSubplace) {
      this.taskFetchSubplace.cancel();
    }
  }

  toggleMapFunctions() {
    if (this.state.mapIsClicked) {
      this.setState({ mapIsClicked: false, buttonText: Localization.mapPlotSwitcherActivateButton });
    } else {
      this.setState({ mapIsClicked: true, buttonText: Localization.mapPlotSwitcherLockButton });
    }
  }

  async loadModules() {
    const search = {
      county: this.props.match.params.county,
      municipality: this.props.match.params.municipality,
      part: this.props.match.params.part || null,
      area: this.props.match.params.area || null,
    };

    if (this.state.plots || this.state.heading || this.state.pollution || this.state.map) {
      let hierarchy = 'fylke=' + encodeURIComponent(search.county) + '&kommune=' + encodeURIComponent(search.municipality);

      if (search.part) {
        hierarchy += '&delomrade=' + encodeURIComponent(search.part);

        if (search.area) {
          hierarchy += '&grunnkrets=' + encodeURIComponent(search.area);
        }
      }
      this.taskFetchPlace = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/forecast/place/alert?${hierarchy}`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));

      try {
        const response = (await this.taskFetchPlace.promise).body;
        this.setState({
          alert: response.alertTile,
          latitude: response.location.latitude,
          longitude: response.location.longitude,
          name: {
            areaname: response.locationParent ? `${response.location.name}, ${response.locationParent.name} ` : `${response.location.name} `,
            municipality: response.municipality.name,
            county: search.county
          },
          plotInfo: {
            components: response.componentValues,
            error: false,
            now: response.nowIndicator,
          },
        });
      } catch (e) {
        this.setState({
          alert: null,
          name: {
            areaname: '',
            municipality: '',
            county: ''
          },
          plotInfo: {
            components: [],
            error: true,
            now: '',
          },
        });
      }
    }

    if (this.state.nearby) {
      let hierarchy = 'fylke=' + encodeURIComponent(search.county) + '&kommune=' + encodeURIComponent(search.municipality);

      if (search.part) {
        hierarchy += '&delomrade=' + encodeURIComponent(search.part);

        if (search.area) {
          hierarchy += '&grunnkrets=' + encodeURIComponent(search.area);
        }
      }

      this.taskFetchSubplace = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/forecast/place/subplace/alert?${hierarchy}`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        }));
      try {
        const response = (await this.taskFetchSubplace.promise).body;
        this.setState({
          nearbyList: response,
          nearbyError: false,
        });
      } catch (e) {
        this.setState({
          nearbyError: true,
        });
      }
    }

    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <div className="t_page-wrapper">
        <div className="g_center-content">
          {this.state.heading
          && (
            <LazySuspense>
              <HeadingWithLine smallHeading="Varslet luftforurensning for:" extraClass="heading-with-line--embed">
                {this.state.name.areaname}
                <span>
                (
                  {this.state.name.municipality}
                )
                </span>
              </HeadingWithLine>
            </LazySuspense>
          )}
          {this.state.pollution
          && (
            <div className="c_white-box">
              <LazySuspense>
                <AirPollutionBox
                  alert={this.state.alert}
                  municipality={this.state.name.municipality}
                  county={this.state.name.county}
                  areaClass={this.search.zone}
                />
                <AirPollutionSource />
              </LazySuspense>
            </div>
          )}
          {this.state.plots
          && (this.state.plotInfo.error ? <ErrorMessage header="Feil" text="Kunne ikke hente data." /> : (
            <div className="c_white-box">
              <div className="c_map-plot-switcher__content">
                <LazySuspense>
                  <PlotSwitcherForecast
                    components={this.state.plotInfo.components}
                    nowIndicator={this.state.plotInfo.now}
                    areaClass={this.search.zone}
                  />
                </LazySuspense>
              </div>
              <h2>{Localization.mapPlotSwitcherPollution}</h2>
              {this.state.plotInfo && this.state.plotInfo.components ? <PollutionLevelExplanation data={this.state.plotInfo.components[0].aqiRange.ranges} direction="horizontal" /> : ''}        
            </div>
          ))}
          {this.state.map
            && this.state.latitude !== null && this.state.longitude !== null && (
              <div className="c_white-box">
                <div className={this.state.mapIsClicked ? 'c_map-plot-switcher__content c_map-plot-switcher__content--map clicked' : 'c_map-plot-switcher__content c_map-plot-switcher__content--map '} role="presentation">
                <button className="activate-map button button--no-button" onClick={() => this.toggleMapFunctions()} type="button">{this.state.buttonText}</button>
                  <LazySuspense>
                    <OLMapForecast
                      latitude={parseFloat(this.state.latitude)}
                      longitude={parseFloat(this.state.longitude)}
                    />
                  </LazySuspense>
                </div>
                <div className="c_map-plot-switcher__extra c_map-plot-switcher__extra--map">
                  <h2>{Localization.mapPlotSwitcherPollution}</h2>
                  {this.state.plotInfo && this.state.plotInfo.components ? <PollutionLevelExplanation data={this.state.plotInfo.components[0].aqiRange.ranges} direction="horizontal" /> : ''}
                  <Link target="_blank" className="c_map-plot-switcher__extra__article-link" to={ArticleInfo.helserad}>{Localization.healthArticleText}</Link>
                  <ButtonLink target="_blank" url={`/kart/${this.state.latitude}/${this.state.longitude}/14/aqi`}>{Localization.mapPlotSwitcherMapChoice}</ButtonLink>
                </div>                
              </div>
            )}
          {this.state.nearby
          && (this.state.nearbyError ? <ErrorMessage header="Feil" text="Kunne ikke hente data." /> : (
            <div className="c_white-box">
              <h2>Områder i nærheten</h2>
              <LazySuspense>
                <NearbySubPlacesTable data={this.state.nearbyList} />
              </LazySuspense>
            </div>
          ))}
          {this.state.articles
          && (
            <LazySuspense>
              <ArticleCategoryList />
            </LazySuspense>
          )}
          <hr />
          <p>Luftkvalitetsvarslet er levert av <a href="https://luftkvalitet.miljodirektoratet.no/">Luftkvalitet i Norge</a></p>
        </div>
      </div>
    );
  }
}

export default ModuleScreen;
