import React from 'react';
import { Link } from 'react-router-dom';
import Localization, { setLanguage } from 'languages/components/weatherstationlist/WeatherStationListLan';
import LocalizationPlot, { setLanguage as setLanguagePlot } from 'languages/components/plots/PlotsLan';

import './MeasuringStation.scss';

const MeasuringStation = (props) => {
  const {
    name, latestAQI, areaType, stationType, latestAQIComponents,
  } = props;


  setLanguage();
  setLanguagePlot();

  const monthArray = [
    LocalizationPlot.plotJan,
    LocalizationPlot.plotFeb,
    LocalizationPlot.plotMar,
    LocalizationPlot.plotApri,
    LocalizationPlot.plotMay,
    LocalizationPlot.plotJun,
    LocalizationPlot.plotJul,
    LocalizationPlot.plotAug,
    LocalizationPlot.plotSep,
    LocalizationPlot.plotOct,
    LocalizationPlot.plotNov,
    LocalizationPlot.plotDec,
  ];

  const hrs = hours => (hours < 10 ? `0${hours}` : hours);
  const time = new Date(latestAQI.time);
  const hours = hrs(time.getHours());
  const nextTime = new Date();
  nextTime.setTime(time.getTime() + 60 * 60 * 1000);
  const nextHours = hrs(nextTime.getHours());
  const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
  let timeStr = `${hours}:${minutes}-${nextHours}:${minutes}`;
  const dayStr = `${monthArray[time.getMonth()]} ${time.getDate()}, `;
  const hrsSinceLastMeasurement = (new Date() - time) / (1000 * 60 * 60);
  if (hrsSinceLastMeasurement > 23) {
    timeStr = dayStr + timeStr;
  }

  if (!latestAQI.time) {
    timeStr = '';
  }

  return (
    <>
      <tbody>
        <tr>
          <td className="name">
            <Link to={`/maalestasjon/${name}`} content={name}>{name}</Link>
          </td>
          <td className="table__status-indicator latestAQI">
            <span style={{ backgroundColor: `${latestAQI.color}` }} />
            {latestAQI.text}
          </td>
          <td style={{ minWidth: '104px' }}>{timeStr}</td>
          <td>{Localization.areaType[areaType] || areaType}</td>
          <td className="stationType">{Localization.stationType[stationType] || stationType}</td>
          <td>
            {latestAQIComponents && Object.keys(latestAQIComponents).join('; ')}
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default MeasuringStation;
