import React, { Component } from 'react';
import './FullscreenMap.scss';

import MapHeader from 'components/map/ol-controls/map-header/MapHeader';
import ShowHidePanels from 'components/map/ol-controls/show-hide-panels/ShowHidePanels';
import PlaceSearch from 'components/places/search/Search';
import ButtonPosition from 'components/elements/button/button-position/ButtonPosition';
import ButtonFilters from 'components/elements/button/button-filters/ButtonFilters';
import ShowExplanation from 'components/map/ol-controls/show-explanation/ShowExplanation';
import { trackPageView } from 'js/TrackPage';
import { setLanguage } from 'languages/screens/fullscreenMap/FullscreenMapLan';
import LazySuspense from 'components/lazy/LazySuspense';
import ComponentNameConverter from 'components/elements/component-name-converter/ComponentNameConverter';
import FilterMenuNoWarnedWeather from 'components/map/ol-controls/filter-menu-no-warned-weather/FilterMenuNoWarnedWeather';
import LocalizationMapLan, { setLanguage as setLanguageMapLan } from 'languages/components/map/MapLan';
import LocalizationElementsLan, { setLanguage as setLanguageElementsLan } from 'languages/components/elements/ElementsLan';
import { getAQIPromise, getFirstActiveLayerWithComponent } from 'js/map/layerHelpers';

const OLMapFull = React.lazy(() => import('components/map/ol-map/ol-map-full/OLMapFull'));

class FullscreenMap extends Component {
  constructor(props) {
    super(props);

    let layer = ['AQI'];
    if (props.match.params.layer) {
      layer = props.match.params.layer.split(';');
      layer.forEach((l, i) => { layer[i] = (l.toLowerCase() === 'aqi' ? 'AQI' : l.toLowerCase()); });
    }

    this.state = {
      error: false,
      filterMenuOpen: false,
      component: null,
      componentList: [],
      panelsHidden: false,
      timesliderHidden: false,
      mapLatLong: [
        props.match.params.lat ? parseFloat(props.match.params.lat) : 65,
        props.match.params.long ? parseFloat(props.match.params.long) : 20,
      ],
      zoom: props.match.params.zoom,
      mapFilter: {
        layer,
        time: this.props.match.params.time,
      },
    };
    this.language = setLanguage();
    setLanguageElementsLan();
    setLanguageMapLan();
    this.toggleFilterMenu = this.toggleFilterMenu.bind(this);
    this.getAQI = this.getAQI.bind(this);
    this.taskGetAQI = null;
    this.selectComponent = this.selectComponent.bind(this);
    this.search = this.search.bind(this);
    this.onLayersChange = this.onLayersChange.bind(this);
    this.onTimeSliderChange = this.onTimeSliderChange.bind(this);
    this.onMapLoaded = this.onMapLoaded.bind(this);
    this.mapClickHandler = this.mapClickHandler.bind(this);

    this.layerGroups = [
      {
        groupName: 'layers',
        groupLabel: LocalizationMapLan.filterMenuMeasureTitle,
        exclusiveGroups: ['stations'],
        permitMultipleLayers: false,
        customRenderer: () => (this.state.staleData ? (
          <FilterMenuNoWarnedWeather />) : null),
        layers: [
          {
            name: 'AQI', label: LocalizationElementsLan.aqi, component: 'aqi', showTimeSlider: true,
          },
          {
            name: 'pm25_concentration', label: <ComponentNameConverter>pm25</ComponentNameConverter>, component: 'pm2.5', showTimeSlider: true,
          },
          {
            name: 'pm10_concentration', label: <ComponentNameConverter>pm10</ComponentNameConverter>, component: 'pm10', showTimeSlider: true,
          },
          {
            name: 'no2_concentration', label: <ComponentNameConverter>no2</ComponentNameConverter>, component: 'no2', showTimeSlider: true,
          },
          {
            name: 'o3_concentration', label: <ComponentNameConverter>o3</ComponentNameConverter>, component: 'o3', showTimeSlider: true,
          },
        ],
      },
      {
        groupName: 'stations',
        exclusiveGroups: ['layers'],
        permitMultipleLayers: false,
        groupLabel: LocalizationMapLan.filterMenuMeasurePollution,
        layers: [
          {
            name: 'stations_aqi', label: LocalizationElementsLan.aqi, component: 'aqi', showTimeSlider: false,
          },
          {
            name: 'stations_pm25_concentration', label: <ComponentNameConverter>pm25</ComponentNameConverter>, component: 'aqi', showTimeSlider: false,
          },
          {
            name: 'stations_pm10_concentration', label: <ComponentNameConverter>pm10</ComponentNameConverter>, component: 'aqi', showTimeSlider: false,
          },
          {
            name: 'stations_no2_concentration', label: <ComponentNameConverter>no2</ComponentNameConverter>, component: 'aqi', showTimeSlider: false,
          },
          {
            name: 'stations_o3_concentration', label: <ComponentNameConverter>o3</ComponentNameConverter>, component: 'aqi', showTimeSlider: false,
          },
          {
            name: 'stations_so2_concentration', label: <ComponentNameConverter>so2</ComponentNameConverter>, component: 'aqi', showTimeSlider: false,
          },
        ],
      },
    ];
    this.showPanels = this.showPanels.bind(this);
    this.hidePanels = this.hidePanels.bind(this);
  }


  componentDidMount() {
    this.getAQI();
    trackPageView('Screen / Fullscreen Map', this.props.location.pathname,
      {
        latitude: this.state.mapLatLong[0],
        longitude: this.state.mapLatLong[1],
        zoom: this.state.zoom,
        filterLayer: this.state.mapFilter.layer,
        filterStations: this.state.mapFilter.stations,
        filterTime: this.state.mapFilter.time,
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.lat !== this.props.match.params.lat
      || prevProps.match.params.long !== this.props.match.params.long) {
      this.setState({
        mapLatLong: [
          this.props.match.params.lat ? parseFloat(this.props.match.params.lat) : 65,
          this.props.match.params.long ? parseFloat(this.props.match.params.long) : 20,
        ],
      });
    }
  }

  componentWillUnmount() {
    if (this.taskGetAQI) {
      this.taskGetAQI.cancel();
    }
  }

  onLayersChange(layers) {
    this.selectExplanationComponent(layers);
    this.setState(prevState => ({
      mapFilter: {
        ...prevState.mapFilter,
        layer: layers,
      },
    }));
  }

  onTimeSliderChange(time) {
    this.setState(prevState => ({
      mapFilter: {
        ...prevState.mapFilter,
        time,
      },
    }));
  }

  onMapLoaded(staleData) {
    if (staleData) {
      this.setState({ staleData: true });
      this.onLayersChange([]);
    } else {
      this.onLayersChange(this.state.mapFilter.layer);
    }
  }

  async getAQI() {
    try {
      this.taskGetAQI = getAQIPromise(this.language, (complist) => {
        this.setState({ componentList: complist, error: false });
        this.selectExplanationComponent(this.state.mapFilter.layer);
      });
    } catch (e) {
      this.setState({ error: true });
    }
  }

  selectExplanationComponent(layers) {
    const l = getFirstActiveLayerWithComponent(this.layerGroups, layers);
    if (l) {
      this.selectComponent(l.component, !l.showTimeSlider);
    } else {
      this.selectComponent(null, true);
    }
  }

  hidePanels() {
    this.setState({
      panelsHidden: true,
    });
  }

  showPanels() {
    this.setState({
      panelsHidden: false,
    });
  }

  mapClickHandler(weatherStation) {
    const { history } = this.props;
    let url = `/kart/${weatherStation.latitude}/${weatherStation.longitude}/${weatherStation.zoom}`;

    url += `/${this.state.mapFilter.layer.join(';')}`;

    if (this.state.mapFilter.time) {
      url += `/${this.state.mapFilter.time}`;
    }

    // If our new url is different, update history. Otherwise we are good.
    if (history.location.pathname !== url) {
      history.push(url);
    }

    history.push(`/maalestasjon/${weatherStation.name}`);
  }

  selectComponent(component, hideTimeSlider) {
    const comp = this.state.componentList.filter(x => component && x.component === component.toUpperCase());

    if (comp.length > 0) {
      this.setState({ component: comp[0], timesliderHidden: hideTimeSlider });
    } else {
      this.setState({ component: null, timesliderHidden: hideTimeSlider });
    }
  }

  toggleFilterMenu() {
    this.setState(prevState => ({
      filterMenuOpen: !prevState.filterMenuOpen,
    }));
  }

  search(search) {
    let url = `/kart/${search.latitude}/${search.longitude}/${this.props.match.params.zoom}`;

    if (this.props.match.params.layer) {
      url += `/${this.props.match.params.layer}`;
    }

    this.props.history.push(url);
    this.setState({
      error: false,
      mapLatLong: [
        search.latitude,
        search.longitude,
      ],
      zoom: 14,
    });
  }

  render() {
    return (
      <div className={`t_fullscreen-map ${!this.state.timesliderHidden ? 't_fullscreen-map--show-slider' : ''} ${this.state.panelsHidden ? 't_fullscreen-map--hide-panels' : ''}`}>
        <MapHeader
          filterMenuOpen={this.state.filterMenuOpen}
          toggleFilterMenu={this.toggleFilterMenu}
        >
          <PlaceSearch search={this.search} />
          <ShowHidePanels
            showPanels={this.showPanels}
            hidePanels={this.hidePanels}
            panelsHidden={this.state.panelsHidden}
          />
        </MapHeader>
        <ButtonFilters
          onClick={this.toggleFilterMenu}
          filterMenuOpen={this.state.filterMenuOpen}
        />
        <ButtonPosition search={this.search} />
        <LazySuspense>
          <OLMapFull
            layerGroups={this.layerGroups}
            longitude={this.state.mapLatLong[1]}
            latitude={this.state.mapLatLong[0]}
            zoom={this.state.zoom}
            layer={this.state.mapFilter.layer}
            onLayersChange={this.onLayersChange}
            timeParam={this.props.match.params.time}
            onTimeSliderChange={this.onTimeSliderChange}
            filterMenuOpen={this.state.filterMenuOpen}
            onMapLoaded={this.onMapLoaded}
            mapClickHandler={this.mapClickHandler}
          />
        </LazySuspense>
        <ShowExplanation
          component={this.state.component}
          error={this.state.error}
          filterMenuOpen={this.state.filterMenuOpen}
        />
      </div>
    );
  }
}

export default FullscreenMap;
