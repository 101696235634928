import React from 'react';

import './TwoDayForecast.scss';
import MostVisitedPlaces from 'components/forecast/most-visited-places/MostVisitedPlaces';
import ButtonLink from 'components/elements/button/button-link/ButtonLink';
import TwoDayAirQuality from 'components/forecast/tables/two-day-air-quality/TwoDayAirQuality';
import Spinner from 'components/elements/spinner/Spinner';
import { getMostVisitedPlaces } from 'js/CookieHandler';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import AirPollutionSource from 'components/info/air-pollution-source/AirPollutionSource';
import Localization, { setLanguage } from 'languages/components/forecast/ForecastLan';

const TwoDayForecast = (props) => {
  setLanguage();
  let table = <TwoDayAirQuality tableData={props.tableData} />;
  if (props.error) {
    table = <ErrorMessage header={Localization.twoDayForecastErrorHeader} text={Localization.twoDayForecastErrorText} />;
  } else if (props.tableData.length === 0) {
    table = <Spinner />;
  }

  return (
    <div className="c_white-box c_two-day-forecast" itemScope itemType="http://schema.org/Table">
      <h2 itemProp="about">{Localization.twoDayForecast}</h2>
      <p className='paragraf--forecast-explanation'>{Localization.twoDayForecastExplanation}</p>
      {table}
      <div className="c_two-day-forecast__right">
        <MostVisitedPlaces mostVisitedPlaces={getMostVisitedPlaces()} />
        <ButtonLink url="/kart/59/10/5/aqi">{Localization.twoDayForecastMap}</ButtonLink>
      </div>
      <AirPollutionSource />
    </div>
  );
};

export default TwoDayForecast;
