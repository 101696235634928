import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CancelableFetchPromise from 'js/CancelablePromise';
import CreateNewWarning from 'components/admin/create-new-warning/CreateNewWarning';
import AdminHeader from 'components/admin/admin-header/AdminHeader';
import Spinner from 'components/elements/spinner/Spinner';
import ErrorMessage from 'components/elements/error-message/ErrorMessage';
import { trackPageView } from 'js/TrackPage.js';
import Localization, { setLanguage } from 'languages/screens/admin/AdminLan';

class AdminCreateForecast extends Component {
  constructor(props) {
    super(props);

    this.language = setLanguage();

    this.getMunicipalityList = this.getMunicipalityList.bind(this);
    this.taskGetMunicipalities = null;
    this.sendForm = this.sendForm.bind(this);
    this.taskSendForm = null;
    this.eventHub = props.eventHub;
    this.state = {
      list: [],
      error: false,
      isLoading: true,
      errorMessage: {
        header: '',
        text: '',
      },
    };
  }

  componentDidMount() {
    trackPageView('Screen / Admin / Create Forecast', this.props.location.pathname);
    this.getMunicipalityList();
  }

  componentWillUnmount() {
    if (this.taskGetMunicipalities) {
      this.taskGetMunicipalities.cancel();
    }
  }

  async getMunicipalityList() {
    this.taskGetForecastList = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/municipalities`,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Accept-Language': this.language,
        },
      }));
    let json = null;
    try {
      json = await this.taskGetForecastList.promise;
    } catch (e) {
      this.setState({
        error: true,
        isLoading: false,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json.status),
          text: Localization.errorGetMunicipalitiesMessage,
        },
      });
      return;
    }

    if (json.status === 401) {
      this.eventHub.emit('logout');
      return;
    }

    if (json.status !== 200) {
      this.setState({
        error: true,
        isLoading: false,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json.status),
          text: Localization.errorGetMunicipalitiesMessage,
        },
      });
      return;
    }
    json = json.body;

    const selectDataset = [];

    json.forEach((val) => {
      selectDataset.push({
        value: val.areacode,
        label: `${val.name} - ${val.county}`,
      });
    });

    this.setState({ isLoading: false, list: selectDataset });
  }

  async sendForm(obj) {
    this.taskSendForm = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/forecast`,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
          'Accept-Language': this.language,
        },
        method: 'POST',
        body: JSON.stringify(obj),
      }), false);

    const json = await this.taskSendForm.promise;

    if (json.status === 401) {
      this.props.dispatchLogout();
      return;
    }

    if (json.status !== 200) {
      this.setState({
        error: true,
        isLoading: false,
        errorMessage: {
          header: Localization.formatString(Localization.errorHeader, json.status),
          text: Localization.errorSendMessage,
        },
      });
      return;
    }

    this.props.history.push('/');
  }

  render() {
    return (
      <div className="t_admin">
        <div className="g_center-content">
          {this.state.error
            && (
            <ErrorMessage
              header={this.state.errorMessage.header}
              text={this.state.errorMessage.text}
            />
            )
          }
          {this.state.isLoading ? <Spinner />
            : (
              <CreateNewWarning
                list={this.state.list}
                sendForm={this.sendForm}
                goBack={() => this.props.history.push('/')}
              />
            )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.securityReducer.token,
    user: state.securityReducer.username,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogout: () => dispatch({ type: 'SECURITY_LOGOUT' }),
  };
}


// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminCreateForecast));
export default withRouter(AdminCreateForecast);
