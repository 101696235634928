import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    myPosition: 'Min posisjon',
    searchFieldText: 'Søk etter tettsted, by, område etc',
    placesHeader: 'Steder',
    municipalitiesHeader: 'Kommuner',
    searchFieldHeader: 'Se varslet luftkvalitet der du er',
  },
  en: {
    myPosition: 'My position',
    searchFieldText: 'Search for village, city, area etc',
    placesHeader: 'Places',
    municipalitiesHeader: 'Municipalities',
    searchFieldHeader: 'See air quality forecasts where you are',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
