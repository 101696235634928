import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';


const Localization = new LocalizedStrings({
  'no-nb': {
    title: 'Luftkvalitet i Norge',
  },
  en: {
    title: 'Air quality in Norway',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
