import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';
import metLogo from './met-logo-hvit.svg';
import metLogoEng from './met-logo-hvit-eng.svg';
import vegvesenLogo from './svv-logo-hvit.svg';
import vegvesenLogoEng from './svv-logo-hvit-eng.svg';
import fhiLogo from './fhi-logo-hvit.svg';
import fhiLogoEng from './fhi-logo-hvit-eng.svg';
import mdirLogo from './mdir-logo-hvit.svg';
import mdirLogoEng from './mdir-logo-hvit-eng.svg';
import helsedirLogo from './hdir-logo-hvit.svg';
import helsedirLogoEng from './hdir-logo-hvit-eng.svg';

const Localization = new LocalizedStrings({
    'no-nb': {
        metLogo: metLogo,
        vegvesenLogo: vegvesenLogo,
        fhiLogo: fhiLogo,
        mdirLogo: mdirLogo,
        helsedirLogo: helsedirLogo
    },
    en: {
        metLogo: metLogoEng,
        vegvesenLogo: vegvesenLogoEng,
        fhiLogo: fhiLogoEng,
        mdirLogo: mdirLogoEng,
        helsedirLogo: helsedirLogoEng
    }
});

export const setLogoLanguage = () => {
    const lan = getCookieLanguage();
    Localization.setLanguage(lan);
    return lan;
};

export default Localization;