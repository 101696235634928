import Cookies from 'js-cookie';

const COOKIE_WEATHER_STATION = 'WeatherStationVisits';
const COOKIE_PLACE = 'PlaceVisit';
const COOKIE_LANGUAGE = 'AirQualityLanguage';
const DEFAULT_LANGUAGE = 'no-nb';
const COOKIE_DASHBOARD_STATION = 'DashboardStation';


function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function setCookieValue(name, value) {
  Cookies.set(name, value);
}

function getCookieValue(name, json = true) {
  try {
    const val = Cookies.get(name);

    if (json) {
      return JSON.parse(val);
    }
    return val;
  } catch (e) {
    return null;
  }
}

function findInArray(name, property, array) {
  let i = 0;
  for (i = 0; i < array.length; i++) {
    if (array[i][property] === name) {
      return i;
    }
  }
  return -1;
}

export function getMostVisitedWeatherStations() {
  const cookie = getCookieValue(COOKIE_WEATHER_STATION);

  if (cookie === null) {
    return null;
  }

  if (!cookie.version) return [];

  let items = Object.keys(cookie.stations).map(key => [
    key,
    cookie.stations[key]
  ]);
  items.sort((first, second) => second[1].visits - first[1].visits);

  items = items.slice(0, 3);
  return items;
}

export function addWeatherStationVisit(station) {
  const cookie = getCookieValue(COOKIE_WEATHER_STATION);

  let obj = cookie || {
    version: '3',
    stations: []
  };

  if (!obj.version) {
    obj = {
      version: '3',
      stations: []
    };
  }

  const stations = obj.stations;

  const i = findInArray(station.station, 'station', stations);

  if (i > -1) {
    const val = stations[i];
    val.visits += 1;
    stations.push(val);
    stations.splice(i, 1);
  } else {
    station.visits = 1;
    stations.push(station);
  }

  if (stations.length > 10) stations.splice(0, 1);

  setCookieValue(COOKIE_WEATHER_STATION, obj);
}

export function getMostVisitedPlaces() {
  const cookie = getCookieValue(COOKIE_PLACE);

  if (cookie === null) {
    return null;
  }

  if (!cookie.version) return [];

  let items = Object.keys(cookie.places).map(key => [key, cookie.places[key]]);
  items.sort((first, second) => second[1].visits - first[1].visits);

  items = items.slice(0, 3);
  return items;
}

export function addPlaceVisit(place) {
  const cookie = getCookieValue(COOKIE_PLACE);

  let obj = cookie || {
    version: '3',
    places: []
  };

  if (!obj.version) {
    obj = {
      version: '3',
      places: []
    };
  }

  const places = obj.places;

  const i = findInArray(place.place, 'place', places);

  if (i > -1) {
    const val = places[i];
    val.visits += 1;
    places.push(val);
    places.splice(i, 1);
  } else {
    places.push({
      place: place.place,
      search: place.search,
      visits: 1
    });
  }

  if (places.length > 10) places.splice(0, 1);

  setCookieValue(COOKIE_PLACE, obj);
}

export function setCookieLanguage(language) {
  setCookieValue(COOKIE_LANGUAGE, language);
}

export function getCookieLanguageInt() {

  const cookie = getCookieValue(COOKIE_LANGUAGE, false);
  
  if (!cookie) {
    setCookieValue(COOKIE_LANGUAGE, DEFAULT_LANGUAGE);
    return DEFAULT_LANGUAGE;
  }

  return cookie;
}

export function getCookieLanguage() {
  let cookie = getCookieLanguageInt();

  let queryLang = getUrlParameter("sprok");
  if (queryLang == 'no') {
    queryLang = 'no-nb';
  }
  if (queryLang == 'no-nb' || queryLang == 'en') {
    if (queryLang != cookie) {
      setCookieLanguage(queryLang);
      cookie = queryLang;
    }
  }

  return cookie;
}


export function setCookieDashboardStation(obj) {
  setCookieValue(COOKIE_DASHBOARD_STATION, obj);
}

export function setCookieDashboardStationParam(lat, lng, name, eoi) {
  const obj = {
    latitude: lat,
    longitude: lng,
    eoi,
    station: name
  };

  setCookieDashboardStation(obj);
}

export function getCookieDashboardStation() {
  const cookie = getCookieValue(COOKIE_DASHBOARD_STATION);
  if (!cookie) {
    return null;
  }

  return cookie;
}
