import React from 'react';
import './Articles.scss';

import Spinner from 'components/elements/spinner/Spinner';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import ArticleRootList from 'components/articles/ArticleRootList';
import Localization, { setLanguage } from 'languages/components/articles/ArticlesLan';
import { trackPageView } from 'js/TrackPage.js';

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.language = setLanguage();
  }

  componentDidMount() {
    trackPageView('Screen / Articles', this.props.location.pathname);
  }

  render() {
    let page;
    if (this.props.isLoading) {
      page = (
        <div className="g_center-content">
          <Spinner />
        </div>
      );
    } else {
      page = (
        <ArticleRootList category="articles" header={Localization.articleListHeader} />
      );
    }
    return (
      <div className="t_page-wrapper t_frontpage t_no-location">

        <Header />
        <div className="g_center-content  g_main-content">
          {page}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Articles;
