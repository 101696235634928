import React from 'react';

import './PollutionLevelExplanation.scss';
import Localization, { setLanguage } from '../../../languages/components/info/InfoLan';

const PollutionLevelExplanation = (props) => {
  setLanguage();
  // Will probably be solved a little different.
  // Send in 'vertical' or 'horizontal' to tell direction
  const tempClass = `c_pollution-level-explanation c_pollution-level-explanation--${props.direction}`;

  const ColorNoData = '#cfd8dc';

  return (
    <div className={tempClass}>
      <ul>
        <li>
          <span style={{ backgroundColor: props.data[3].color }} />
          {props.data[3].textNo}
        </li>
        <li>
          <span style={{ backgroundColor: props.data[2].color }} />
          {props.data[2].textNo}
        </li>
        <li>
          <span style={{ backgroundColor: props.data[1].color }} />
          {props.data[1].textNo}
        </li>
        <li>
          <span style={{ backgroundColor: props.data[0].color }} />
          {props.data[0].textNo}
        </li>
        <li>
          <span style={{ backgroundColor: ColorNoData }} />
          {Localization.pollutionLevelExplanationNoData}
        </li>
      </ul>
    </div>
  );
};

export default PollutionLevelExplanation;
