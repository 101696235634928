import React from 'react';
import './SearchField.scss';
import { connect } from 'react-redux';
import GetMyPosition from 'js/GetMyPosition';
import Suggestions from './Suggestions';
import Localization, { setLanguage } from '../../../languages/components/places/PlacesLan';

class SearchField extends React.Component {
  constructor(props) {
    super(props);

    setLanguage();
    this.state = {
      isFocused: false,
      searchValue: '',
      suggestionFocus: -1,
      myPositionLoading: false,
    };

    this.suggestionMyPosition = this.suggestionMyPosition.bind(this);
    this.myLocationSuccess = this.myLocationSuccess.bind(this);
    this.myLocationError = this.myLocationError.bind(this);

    this.inputChange = this.inputChange.bind(this);
    this.suggestionClick = this.suggestionClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.suggestionList = React.createRef();
  }

  componentDidUpdate() {
    if (document.activeElement === this.hasFocusAfterSearch) {
      if (!this.state.isFocused) {
        this.setFocus();
      }
    }
  }

  onKeyUp(e) {
    const UP_ARROW = 38;
    const DOWN_ARROW = 40;
    const ENTER = 13;

    const x = e.which || e.keyCode;
    if (x === UP_ARROW || x === DOWN_ARROW) {
      let totalSuggestions = 1;
      if (this.props.suggestions) {
        totalSuggestions += this.props.suggestions.length;
      }

      if (this.props.municipalities) {
        totalSuggestions += this.props.municipalities.length;
      }

      this.setState((prevState) => {
        let val = prevState.suggestionFocus;
        if (x === UP_ARROW) {
          val--;
        }
        if (x === DOWN_ARROW) {
          val++;
        }

        if (val > totalSuggestions - 1) {
          val = 0;
        }
        if (val < 0) {
          val = totalSuggestions - 1;
        }
        return { suggestionFocus: val };
      });
    }

    // Enter go to result.
    if (x === ENTER) {
      const i = this.state.suggestionFocus;

      if (i === 0 && !this.props.myLocation.error) {
        this.suggestionMyPosition();
        this.setState({ suggestionFocus: -1 });
      }

      if (i > 0 && this.props.suggestions && this.props.municipalities) {
        let suggestion = null;

        if (i > this.props.suggestions.length) {
          suggestion = this.props.municipalities[i - this.props.suggestions.length - 1];
        } else {
          suggestion = this.props.suggestions[i - 1];
        }

        this.suggestionClick({
          latitude: suggestion.latitude,
          longitude: suggestion.longitude,
          zone: suggestion.zone,
        });
        this.setState({ suggestionFocus: -1 });
      }
    }
  }

  onKeyDown(e) {
    const UP_ARROW = 38;
    const DOWN_ARROW = 40;

    const x = e.which || e.keyCode;
    // Prevent input field navigation with up and down arrows.
    if (x === UP_ARROW || x === DOWN_ARROW) {
      e.preventDefault();
    }
  }

  // Adds and remove class to search-field__list on focus
  setFocus() {
    this.setState({ isFocused: true });
  }


  inputChange(evt) {
    this.setState({ searchValue: evt.target.value });
    if (this.props.onInputChange) {
      this.props.onInputChange(evt.target.value);
    }
  }

  removeFocus() {
    this.setState({ isFocused: false });
  }

  suggestionClick(suggestion, name = '') {
    if (name.length > 0) {
      this.setState({ searchValue: '' });
    } else {
      this.setState({ searchValue: '' });
    }
    if (this.props.onSearchClick) {
      suggestion.name = name;
      this.props.onSearchClick(suggestion);
    }
    this.removeFocus();
  }

  suggestionMyPosition() {
    this.setState({ myPositionLoading: true });
    if (this.props.myLocation.initialized) {
      const search = this.props.myLocation.coordinates;
      search.zone = 'grunnkrets';
      this.suggestionClick(search, 'Min posisjon');
      this.setState({ myPositionLoading: false });
    } else {
      GetMyPosition(this.myLocationSuccess, this.myLocationError);
    }
  }

  async myLocationSuccess(res) {
    this.props.dispatchSuccess(res);
    const search = this.props.myLocation.coordinates;
    search.zone = 'grunnkrets';
    this.suggestionClick(search, 'Min posisjon');
    this.setState({ myPositionLoading: false });
  }

  async myLocationError(res) {
    this.props.dispatchError(res);
    this.setState({ myPositionLoading: false });
  }

  render() {
    return (
      <div className="c_search-field">
        <div
          onBlur={this.removeFocus.bind(this)}
          onFocus={this.setFocus.bind(this)}
          className="c_search-field__wrapper"
        >
          <h2>{Localization.searchFieldHeader}</h2>
          <div role="search" className={this.state.isFocused ? 'c_search-field__list c_search-field__list--focus' : 'c_search-field__list'}>
            <div className="input input--search">
              <label htmlFor="search-location">{Localization.searchFieldText}</label>
              <input
                className="input"
                autoComplete="off"
                placeholder={Localization.searchFieldText}
                type="text"
                id="search-location"
                name="search-location"
                value={this.state.searchValue}
                onChange={this.inputChange}
                onKeyUp={this.onKeyUp}
                onKeyDown={this.onKeyDown}
                ref={(input) => { this.hasFocusAfterSearch = input; }}
              />
            </div>
            <Suggestions
              myPositionLoading={this.state.myPositionLoading}
              myLocation={this.props.myLocation}
              myPositionClick={this.suggestionMyPosition}
              suggestionClick={this.suggestionClick}
              suggestions={this.props.suggestions}
              municipalities={this.props.municipalities}
              focusIndex={this.state.suggestionFocus}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myLocation: state.myLocationReducer.myLocation,
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    dispatchSuccess: (coordinates) => { dispatch({ type: 'DISPATCH_MYLOCATION_SUCCESS', coordinates }); },
    dispatchError: (errorMessage) => { dispatch({ type: 'DISPATCH_MYLOCATION_ERROR', errorMessage }); },
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
