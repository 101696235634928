import React from 'react';
import './Colors.scss';

const Colors = () => (
  <div className="c_styleguide__item c_styleguide__item--colors">
    <h2>Farger</h2>
    <div className="colors__color colors__color--dark-blue">
      <h3>Mørkeblå</h3>
      <span>Mørkeblå #06538b</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Logo</li>
        <li>Rammer og linjer</li>
      </ul>
    </div>

    <div className="colors__color colors__color--link-blue">
      <h3>Blå</h3>
      <span>Blå #0975c3</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Lenker</li>
        <li>Knapper</li>
      </ul>
    </div>

    <div className="colors__color colors__color--black">
      <h3>Svart</h3>
      <span>Svart #000</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Tekst</li>
      </ul>
    </div>

    <div className="colors__color colors__color--status-low">
      <h3>Grønn</h3>
      <span>Grønn #3f9f41</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Statusfarge for lav luftforurensning</li>
      </ul>
    </div>

    <div className="colors__color colors__color--status-moderate">
      <h3>Gul</h3>
      <span>Gul #ffcb00</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Statusfarge for moderat luftforurensning</li>
      </ul>
    </div>

    <div className="colors__color colors__color--status-high">
      <h3>Rød</h3>
      <span>Rød #c13500</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Statusfarge for høy luftforurensning</li>
      </ul>
    </div>

    <div className="colors__color colors__color--status-very-high">
      <h3>Lilla</h3>
      <span>Lilla #4900ac</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Statusfarge for veldig høy luftforurensning</li>
      </ul>
    </div>

    <div className="colors__color colors__color--status-no-data">
      <h3>Grå</h3>
      <span>Grå #cfd8dc</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Ingen data funnet</li>
      </ul>
    </div>

    <div className="colors__color colors__color--grey-light">
      <h3>Lys grå</h3>
      <span>Lys grå #f1f3f5</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Bakgrunner</li>
        <li>Rammer og linjer</li>
      </ul>
    </div>

    <div className="colors__color colors__color--grey-dark">
      <h3>Mørk grå</h3>
      <span>Mrøk grå #ccc</span>
      <p>Blant annet brukt på:</p>
      <ul>
        <li>Bakgrunner</li>
        <li>Rammer og linjer</li>
      </ul>
    </div>

  </div>
);

export default Colors;
