import React from 'react';

import './ButtonSmall.scss';

const ButtonSmall = (props) => {
  let classes = `button button--small ${props.extraClass ? `${props.extraClass}` : ''}`;
  if (props.active) {
    classes += ' active';
  }
  return (<button type="button" onClick={props.onClick} className={classes}>{props.children}</button>);
};

export default ButtonSmall;
