import React from 'react';
import { Link } from 'react-router-dom';

import './ButtonLink.scss';

const ButtonLink = props => (
  <Link
    to={props.url}
    target={props.target
      ? props.target : undefined}
    className={`button button--link ${props.extraClass ? `${props.extraClass}` : ' '}`}
  >
    {props.children}

  </Link>
);

export default ButtonLink;
