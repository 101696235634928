import React from 'react';
import {
  Route, Switch, BrowserRouter as Router,
} from 'react-router-dom';
import PrivateRoute from 'components/security/PrivateRoute';

/* Final screens */
import { Helmet } from 'react-helmet';
import Localization, { setLanguage } from 'languages/components/routing/Routing';
import Frontpage from './screens/frontpage/Frontpage';
import Article from './screens/article/Article';
import SpecifiedLocation from './screens/forecast/SpecifiedLocation';
import WeatherStation from './screens/weatherstation/WeatherStation';
import WeatherStationMunicipality from './screens/weatherstation/WeatherStationMunicipality';
import Admin from './screens/admin/Admin';
import AdminLogin from './screens/admin/login/AdminLogin';
import DashboardContainer from './screens/admin/dashboard/DashboardContainer';
import AdminCreateForecast from './screens/admin/create/AdminCreateForecast';
import AdminEditForecast from './screens/admin/edit/AdminEditForecast';
import FullscreenMap from './screens/map/FullscreenMap';
import PageNotFound from './screens/page-not-found/PageNotFound';
import ModuleScreen from './screens/module/ModuleScreen';
import ModuleWizard from './screens/module/ModuleWizard';
import Articles from './screens/articles/Articles';
import About from './screens/about/About';
import Styleguide from './screens/styleguide/Styleguide';
import Demoscreen from './screens/demoscreen/Demoscreen';
import WeatherstationList from './screens/weatherstationlist/WeatherstationList';

const defaultTitle = Component => (props) => {
  setLanguage();
  return (
    <>
      <Helmet>
        <title>{Localization.title}</title>
        <meta name="description" content="Sjekk varslet og målt luftkvalitet i hele Norge. Få informasjon om luftforurensning. Et samarbeid mellom Miljødirektoratet, Meteorologisk institutt, Statens vegvesen, Helsedirektoratet og Folkehelseinstituttet."/>
      </Helmet>
      <Component {...props} />
    </>
  );
};

const routes = (
  <Router>
    <Switch>    
      {/* Final Routes */}
      <Route exact path="/" component={defaultTitle(Frontpage)} />
      <Route exact path="/artikkel/:id+" component={defaultTitle(Article)} />
      <Route exact path="/varsling/:county/:municipality/:part?/:area?" component={SpecifiedLocation} />
      <Route exact path="/maalestasjon/:stasjon" component={WeatherStation} />
      <Route exact path="/maalestasjon/kommune/:municipality" component={WeatherStationMunicipality} />
      <Route exact path="/laer-mer" component={defaultTitle(Articles)} />
      <Route exact path="/om-tjenesten" component={defaultTitle(About)} />
      <PrivateRoute exact path="/admin" component={defaultTitle(Admin)} />
      <PrivateRoute exact path="/admin/forecast/create" component={defaultTitle(AdminCreateForecast)} />
      <PrivateRoute exact path="/admin/forecast/edit/:id" component={defaultTitle(AdminEditForecast)} />
      <Route exact path="/admin/dashboard" component={defaultTitle(DashboardContainer)} />
      <Route exact path="/admin/login" component={defaultTitle(AdminLogin)} />
      <Route exact path="/kart/:lat/:long/:zoom/:layer?/:time?" component={defaultTitle(FullscreenMap)} />
      <Route exact path="/module/:modules/:county/:municipality/:part?/:area?" component={defaultTitle(ModuleScreen)} />
      <Route exact path="/module/wizard" component={ModuleWizard} />
      <Route exact path="/module/demo" component={Demoscreen} />
      <Route exact path="/styleguide" component={defaultTitle(Styleguide)} />
      <Route exact path="/maalestasjoner" component={defaultTitle(WeatherstationList)} />
      <Route exact path="/maalestasjoner/:stasjon" component={defaultTitle(WeatherstationList)} />
      {/* 404 route */}
      <Route component={PageNotFound} />
    </Switch>
  </Router>
);
export default routes;
