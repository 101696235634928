import React, { Component } from 'react';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import Footer from 'components/elements/footer/Footer';
import Header from 'components/elements/header/Header';
import { trackPageView } from 'js/TrackPage.js';
import Localization, { setLanguage } from 'languages/screens/pageNotFound/PageNotFoundLan';

// import Spinner from 'components/elements/spinner/Spinner';

class PageNotFound extends Component {
  constructor(props) {
    super(props);
    setLanguage();
  }

  componentDidMount() {
    trackPageView('Screen / 404', this.props.location.pathname);
  }

  render() {
    return (
      <div className="t_page-wrapper t_page-not-found">
        <Header />
        <div className="g_center-content  g_main-content">
          <div className="sorry-message">
            <h1>{Localization.pageTitle}</h1>
            <p>{Localization.pageMessage}</p>
            <Link to="/">{Localization.pageUrlText}</Link>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PageNotFound;
