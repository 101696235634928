import React, { Component } from 'react';
import Localization, { setLanguage } from 'languages/components/elements/ElementsLan';
import ArticleList from './article-list/ArticleList';
import { Link } from 'react-router-dom';
import articlesLogo from './luftkvalitet_icon_lar-mer_hvit.svg'
import aboutLogo from './luftkvalitet_icon_about_hvit.svg'

class ArticleCategoryList extends Component {

  constructor(props) {
    super(props);
    this.language = setLanguage();
   
  }

  render() {
    return (
      <ArticleList >
         <li
            className="article-list__item category-left"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
      >
      <Link to="/laer-mer" className="item__direct-link e_white-box" title={Localization.articlesMenuItem}>
        <span className="item__icon">
          <img src={articlesLogo} alt="" />
        </span>
        <span className="item__text">
          <h3>{Localization.articlesMenuItem}</h3>
        </span>
      </Link>
      </li>
      <li
        className="article-list__item category-right"
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem"
      >
      <Link to="/om-tjenesten" className="item__direct-link e_white-box" title={Localization.aboutMenuItem}>
        <span className="item__icon">
          <img src={aboutLogo} alt="" />
        </span>
        <span className="item__text">
          <h3>{Localization.aboutMenuItem}</h3>
        </span>
      </Link>
      </li>
           </ArticleList>
    );
  }
}

export default ArticleCategoryList;
