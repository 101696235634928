import React, { Component } from 'react';
import './Article.scss';

import ButtonReturn from 'components/elements/button/button-return/ButtonReturn';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DynamicArticleIndex from 'components/articles/dynamic-index/DynamicArticleIndex';
import Header from 'components/elements/header/Header';
import Footer from 'components/elements/footer/Footer';

import Spinner from 'components/elements/spinner/Spinner';
import { Helmet } from 'react-helmet';
import MetaDescription from 'js/MetaDescription';
import { trackPageView } from 'js/TrackPage.js';
import Localization, {
  setLanguage,
} from 'languages/screens/article/ArticleLan';

class Article extends Component {
  constructor(props) {
    super(props);

    this.language = setLanguage();

    this.history = props.history;
    this.onBackClick = this.onBackClick.bind(this);
    this.articleId = props.match.params.id;
    this.state = { article: null, notFound: false };
  }

  async componentDidMount() {
    try {
      let articlePath;
      const isUrlPath = !this.isNumeric(this.articleId);
      if (isUrlPath) {
        articlePath = `/url?url=${encodeURIComponent(this.articleId)}`;
      } else {
        articlePath = `/${this.articleId}`;
      }
      let res = await fetch(
        `${process.env.REACT_APP_API}/api/cms/article${articlePath}`,
        {
          headers: {
            'Accept-Language': this.language,
          },
        },
      );
      res = await res.json();
      if (res.status == 404) {
        this.setState({ notFound: true });
        trackPageView('Screen / Article', this.props.location.pathname, {
          found: false,
        });
        return;
      }
      if (!isUrlPath) {
        let uri = window.location.href.replace(window.location.origin, '');
        uri = uri.replace(`/artikkel/${this.articleId}`, `/artikkel${res.url}`);
        this.history.push(uri);
      } else {
        // Handle forinstance /artikkel/ENGLISHURL (but requested language no) -> redirect -> /artikkel/NORSKURL
        const uriOrg = window.location.href.replace(window.location.origin, '');
        let uri = window.location.href.replace(window.location.origin, '');
        uri = uri.replace(this.articleId, res.url).replaceAll('//', '/');
        if (uri != uriOrg) {
          this.history.push(uri);
        }
      }
      this.props.dispatchNavigationAreaChanged(res.category);
      this.setState(
        {
          article: {
            title: res.name,
            pageTitle: res.title,
            ingress: res.ingress,
            category: res.category,
            created: res.created,
            changed: res.changed,
            seoDescription: res.seoDescription,
            seoTitle: res.seoTitle,
            text: { __html: res.descriptionHtml },
            hideTableOfContents: res.hideTableOfContents,
          },
        },
        () => {
          // Waiting for data to be fetched and dom to be updated, before moving to the hash.
          if (this.props.location.hash) {
            const e = document.getElementById(
              this.props.location.hash.substring(1),
            );
            if (e) {
              e.scrollIntoView();
            }
          }
        },
      );

      trackPageView('Screen / Article', this.props.location.pathname, {
        found: true,
      });
    } catch (e) {
      this.setState({ notFound: true });
      trackPageView('Screen / Article', this.props.location.pathname, {
        found: false,
      });
    }
  }


  isNumeric(str) {
    return /^\d+$/.test(str);
  }

  componentWillUnmount() {
    this.props.dispatchNavigationAreaChanged('');
  }

  onBackClick() {
    if (this.props.activeNavigationArea === 'about') {
      this.history.push('/om-tjenesten');
    } else if (this.props.activeNavigationArea === 'articles') {
      this.history.push('/laer-mer');
    } else {
      this.history.push('/');
    }
  }

  render() {
    if (this.state.article === null && this.state.notFound === false) {
      return <Spinner />;
    }

    return (
      <div
        className="t_page-wrapper t_article"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Header />
        <div className="g_center-content g_main-content">
          <ButtonReturn onClick={this.onBackClick}>
            {Localization.buttonBackText}
          </ButtonReturn>
          {this.state.notFound ? (
            <div className="t_article__article-not-found">
              <h2>{Localization.notFoundTitle}</h2>
              <Link to="/">{Localization.notFoundLink}</Link>
            </div>
          ) : (

            <div className="article-content">
              <Helmet>
                <title>{this.state.article.pageTitle}</title>
                <meta name="description" content={this.state.article.seoDescription} />
                <meta name="og:title" content={this.state.article.seoTitle} />
              </Helmet>

              <h1 itemProp="headLine">{this.state.article.title}</h1>
              <meta itemProp="author" content="Miljødirektoratet" />
              <meta
                itemProp="datePublished"
                content={this.state.article.created}
              />
              <meta
                itemProp="dateModified"
                content={this.state.article.changed}
              />
              <p itemProp="description" className="article-content__ingress">
                {this.state.article.ingress}
              </p>
              {!this.state.article.hideTableOfContents ? (
                <DynamicArticleIndex />
              ) : (
                undefined
              )}
              <div
                id="articleText"
                itemProp="articleBody"
                dangerouslySetInnerHTML={this.state.article.text}
              />
            </div>
          )}
        </div>
        <Footer includePublisherMicrodata />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    historyLength: state.historyReducer.initialLength,
    activeNavigationArea: state.navigationReducer.activeNavigationArea,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNavigationAreaChanged: (area) => {
      dispatch({ type: 'DISPATCH_NAVIGATION_AREA_CHANGED', area });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Article);
