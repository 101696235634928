import React from 'react';

import './MeasuredAirPollution.scss';
import Localization, { setLanguage } from 'languages/components/weatherstation/WeatherStationLan';

const MeasuredAirPollution = (props) => {
  setLanguage();
  return (
    <div
      itemScope=""
      itemType="http://schema.org/Table"
      className="c_measured-air-pollution"
    >
      <meta itemProp="about" content={Localization.aboutTable} />
      <table className="table table--measured-air-pollution table--status table--responsive" cellSpacing="0">
        <thead>
          <tr>
            <th>{Localization.measuredTableComponent}</th>
            <th>{Localization.measuredTableTimemean}</th>
            <th>{Localization.measuredTableValue}</th>
            <th>{Localization.measuredTableDaymean}</th>
            <th>{Localization.measuredTableValue}</th>
          </tr>
        </thead>
        <tbody>
          {props.data ? props.data.map((comp) => {
            const hrs = (hours) => hours < 10 ? `0${hours}` : hours;
            const time = new Date(comp.timeLatest);
            const timeMean = new Date(comp.date24HMean);
            const hours = hrs(time.getHours());
            const nextTime = new Date();
            nextTime.setTime(time.getTime() + 60 * 60 * 1000);
            const nextHours = hrs(nextTime.getHours());
            const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
            const day = timeMean.getDate();
            const month = timeMean.getMonth() + 1; // getMonth() returns 0-11. We have no month 0.
            const year = timeMean.getFullYear();

            return (
              <tr key={comp.component}>
                <td itemProp="name" data-th={Localization.measuredTableComponent}>{comp.component}</td>
                <td data-th={Localization.measuredTableTimemean}>{`${hours}:${minutes}-${nextHours}:${minutes}`}</td>
                <td data-th={Localization.measuredTableValue} className="table__status-indicator">
                  <span style={{ backgroundColor: comp.colorLatest }} />
                  {`${parseFloat(comp.valueLatest).toFixed(1)} ${comp.unit}`}
                </td>
                <td data-th={Localization.measuredTableDaymean}>{comp.date24HMean === null ? '' : `${day}.${month}.${year}`}</td>
                <td data-th={Localization.measuredTableValue} className="table__status-indicator">
                  <span style={{ backgroundColor: comp.color24hMean ? comp.color24hMean : '' }} />
                  {comp.value24hMean === null ? '' : `${parseFloat(comp.value24hMean).toFixed(1)} ${comp.unit}`}
                </td>
              </tr>
            );
          })
            : <tr><td>{Localization.measuredTableNoData}</td></tr>
          }
        </tbody>
      </table>

    </div>
  );
};

export default MeasuredAirPollution;
