const initialState = {
  isAuthenticated: false,
  username: '',
  token: null,
};

export default function securityReducer(state = initialState, action) {
  switch (action.type) {
    case 'SECURITY_LOGIN':
      return Object.assign({}, state, {
        isAuthenticated: true,
        username: action.login.username,
        token: action.login.token,
      });
    case 'SECURITY_LOGOUT':
      return Object.assign({}, state, {
        isAuthenticated: false,
        username: '',
        token: null,
      });
    default:
      return state;
  }
}
