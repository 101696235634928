import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    errorInvalidDateHeader: 'Ugyldig dato',
    errorInvalidDateText: 'En ugyldig fra eller til dato har blitt valgt. Vennligst prøv igjen med kalenderen som kommer opp.',
    errorFormHeader: 'Kunne ikke opprette form',
    errorFormText: 'En av verdiene i formen er ugyldig. Se over at alt stemmer før du sender på nytt.',
    confirmTitle: 'Gå tilbake',
    confirmMessage: 'Endringer gjort vil ikke bli lagret. Vil du gå tilbake?',
    confirmYes: 'Ja',
    confirmNo: 'Nei',
    formHeader: 'Skriv tekstvarsel',
    formChooseMunicipality: 'Velg kommune',
    formForecastText: 'Tekstvarsel',
    formUrlText: 'Lenke',
    formLettersRemaining: 'tegn igjen',
    formFrom: 'Fra',
    formTo: 'Til',
    formBack: 'Tilbake',
    formPublish: 'Publiser',
    loginHeader: 'Innlogging',
    loginUsername: 'Brukernavn',
    loginPassword: 'Passord',
    loginError: 'Feil brukernavn eller passord.',
    loginButtonLogin: 'Logg inn',
    logoutButton: 'Logg ut',
    adminHeaderTitle: 'Luftkvalitet',
    user: 'Bruker',
  },
  en: {
    errorInvalidDateHeader: 'Invalid date',
    errorInvalidDateText: 'An invalid from or to date was chosen. Please try again with the pop-up calendar.',
    errorFormHeader: 'Could not create form',
    errorFormText: 'One of the form values is invalid. Please make sure everything is correct before trying again.',
    confirmTitle: 'Go back',
    confirmMessage: 'Changes made will not be saved. Are you sure you want to go back?',
    confirmYes: 'Yes',
    confirmNo: 'No',
    formHeader: 'Write forecast',
    formChooseMunicipality: 'Choose municipality',
    formForecastText: 'Forecast text',
    formUrlText: 'Link',
    formLettersRemaining: 'letters left',
    formFrom: 'From',
    formTo: 'To',
    formBack: 'Back',
    formPublish: 'Publish',
    loginHeader: 'Log in',
    loginUsername: 'Username',
    loginPassword: 'Password',
    loginError: 'Wrong username or password.',
    loginButtonLogin: 'Log In',
    logoutButton: 'Log out',
    adminHeaderTitle: 'Air quality',
    user: 'User',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
