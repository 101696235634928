/* Eslint hints: */
/* eslint react/jsx-filename-extension: "off" */
/* global document  */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppInsights } from 'applicationinsights-js';
import './index.scss';
import Routes from './Routing';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import configureStore from './redux/store/configureStore';

import 'isomorphic-fetch';

import 'core-js/stable';
import 'regenerator-runtime/runtime';


AppInsights.downloadAndSetup({ instrumentationKey: process.env.REACT_APP_APPINSIGHT ? process.env.REACT_APP_APPINSIGHT : '' });

const store = configureStore();

ReactDOM.render(<Provider store={store}>{Routes}</Provider>, document.getElementById('root'));
// registerServiceWorker();
unregister();
