import React from 'react';
import Spinner from 'components/elements/spinner/Spinner';
import ComponentNameConverter, { formatComponent } from 'components/elements/component-name-converter/ComponentNameConverter';
import './PlotsContainer.scss';
import Localization, { setLanguage } from 'languages/components/plots/PlotsLan';
import LazySuspense from 'components/lazy/LazySuspense';

const AirComponentPlot = React.lazy(() => import('components/plots/aircomponents/AirComponentPlot'));

const PlotsContainer = (props) => {
  setLanguage();
  return (
    <div className="c_plots-container">
      {props.isLoading
        ? <Spinner />
        : (
          <div>
            {props.plotsInfo.componentValues.map((data) => {
              const title = Localization.ComponentInfo[props.type === 'ws2' ? 'twoDays' : 'fourteenDays'][data.component.toLowerCase()];
              const filename = `${title} (${data.component}) ${props.station}`;
              if (data.component && data.values && data.aqiRange) {
                return (
                  <div key={data.component.toLowerCase()}>
                    <h3>
                      <ComponentNameConverter>{data.component}</ComponentNameConverter>
                      {title}
                    </h3>
                    <LazySuspense>
                      <AirComponentPlot
                        header={formatComponent(data.component, true, true)}
                        subHeader={title}
                        plotFileName={filename}
                        plotType={props.plotType}
                        componentData={data}
                        isLoading={props.isLoading}
                        type={props.type ? props.type : ''}
                      />
                    </LazySuspense>
                    {(props.type === 'ws14' || props.type === 'ws7') && data.aqiRange && !data.aqiRange.ranges
                  && (
                    <div>
                      {Localization.formatString(Localization.NoDayMean, <ComponentNameConverter>{data.component}</ComponentNameConverter>)}
                    </div>
                  )}
                  </div>
                );
              }
              return '';
            })}
          </div>
        )
      }
    </div>

  );
};

export default PlotsContainer;
