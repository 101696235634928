import React from 'react';
import Localization, { setLanguage } from 'languages/components/map/MapLan';
import icon from './icon-warning.png';
import './FilterMenuNoWarnedWeather.scss';

const FilterMenuNoWarnedWeather = () => {
  setLanguage();
  return (
    <div className="c_filter-menu-no-warned-weather">
      <p>
        <img src={icon} alt="Varselikon" />
        {Localization.FilterMenuNoWarnedWeather}
      </p>
    </div>
  );
};

export default FilterMenuNoWarnedWeather;
