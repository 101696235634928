import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import AdminHeader from 'components/admin/admin-header/AdminHeader';
import LogInForm from 'components/admin/log-in-form/LogInForm';
import CancelableFetchPromise from 'js/CancelablePromise';
import { connect } from 'react-redux';
import '../Admin.scss';
import { trackPageView } from 'js/TrackPage.js';
import { NavLink } from 'react-router-dom';

class AdminLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.onLogInClick = this.onLogInClick.bind(this);
    this.taskLogin = null;
    this.state = { isLoading: false };
  }

  componentWillUnmount() {
    trackPageView('Screen / Admin / Login', this.props.location.pathname);
    if (this.taskLogin) {
      this.taskLogin.cancel();
    }
  }

  async onLogInClick(login, cb) {
    this.setState({ isLoading: true });
    try {
      this.taskLogin = CancelableFetchPromise(
        fetch(`${process.env.REACT_APP_API}/api/manualforecast/admin/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(login),
        }),
      );
      const result = await this.taskLogin.promise;

      if (result.status === 200) {
        this.props.dispatchLogin({
          username: login.username,
          token: result.body.token,
        });

        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        cb();
      }
    } catch (e) {
      this.setState({ isLoading: false });
      cb();
    }
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirect } = this.props;

    from = '/admin/dashboard';
    if (redirect === true) {
      return (<Redirect push exact to={from} />);
    }
    return (
      <div className="t_admin">
        <AdminHeader loggedIn={false} />
        <div className="g_center-content">
          <LogInForm
            onLogInClick={this.onLogInClick}
            isLoading={this.state.isLoading}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    redirect: state.securityReducer.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogin: login => dispatch({ type: 'SECURITY_LOGIN', login }),
    dispatchLoginFail: () => dispatch({ type: 'SECURITY_LOGOUT' }),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminLogin),
);
