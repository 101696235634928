import React, { Component } from 'react';
import AreaPicker from 'components/admin/area-picker/AreaPicker';
import CancelableFetchPromise from 'js/CancelablePromise';
import ButtonReturn from 'components/elements/button/button-return/ButtonReturn';
import ButtonPrimary from 'components/elements/button/button-primary/ButtonPrimary';
import 'screens/forecast/SpecifiedLocation.scss';
import './ModuleWizard.scss';
import EmbedMap from './embed-kart2.png';
import EmbedPlots from './embed-graf.png';
import EmbedWarning from './embed-warning.png';

class ModuleWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: {
        county: null,
        municipality: null,
        part: null,
        area: null,
      },
      preview: false,
      map: false,
      plots: false,
      articles: false,
      nearby: false,
      heading: true,
      pollution: false,
      height: 900,
      width: '100%',
      style: { border: '1px solid lightgrey' },
      title: 'Innebygget innhold fra luftkvalitet.miljodirektoratet.no',
    };

    this.search = this.search.bind(this);
    this.createEmbedScript = this.createEmbedScript.bind(this);
    this.areaPickerChange = this.areaPickerChange.bind(this);
    this.validateSelection = this.validateSelection.bind(this);
    this.taskConvertSearch = null;
  }

  componentWillUnmount() {
    if (this.taskConvertSearch) {
      this.taskConvertSearch.cancel();
    }
  }

  async search(search) {
    this.taskConvertSearch = CancelableFetchPromise(fetch(`${process.env.REACT_APP_API}/api/places/geoencode?areaclass=${search.zone}&lat=${search.latitude}&lon=${search.longitude}`,
      {
        headers: {
          'Accept-Language': this.language,
        },
      }));

    const res = (await this.taskConvertSearch.promise);
    this.setState({
      location: {
        county: encodeURIComponent(res.body.fylkeName),
        municipality: encodeURIComponent(res.body.kommuneName),
        part: res.body.delomradeName ? encodeURIComponent(res.body.delomradeName) : null,
        area: res.body.grunnkretsName ? encodeURIComponent(res.body.grunnkretsName) : null,
        name: search.name,
      },
    });
  }

  areaPickerChange(areas) {
    this.setState({
      location: {
        county: areas.county,
        municipality: areas.municipality,
        part: areas.subarea,
        area: areas.unit,
      },
    });
  }

  validateSelection() {
    if (this.state.location.county === null || this.state.location.municipality === null) {
      return false;
    }

    return true;
  }

  createEmbedScript(component = false) {
    const modules = [];
    if (this.state.heading) modules.push('heading');
    if (this.state.pollution) modules.push('pollution');
    if (this.state.plots) modules.push('plots');
    if (this.state.map) modules.push('map');
    if (this.state.nearby) modules.push('nearby');
    if (this.state.articles) modules.push('articles');

    let param = modules.join(',');

    if (param === '') param = 'none';

    let url = `${window.location.origin}/module/${param}/${encodeURIComponent(this.state.location.county)}/${encodeURIComponent(this.state.location.municipality)}`;
    if (this.state.location.part) {
      url += `/${encodeURIComponent(this.state.location.part)}`;
    }
    if (this.state.location.area) {
      url += `/${encodeURIComponent(this.state.location.area)}`;
    }

    if (component) {
      return <iframe title={this.state.title} src={url} height={this.state.height} width={this.state.width} />;
    }

    return `<iframe title="${this.state.title}" src="${url}" height="${this.state.height}" width="${this.state.width}"></iframe>`;
  }

  render() {
    if (this.state.preview) {
      return (
        <div className="g_center-content">
          <div className="c_white-box c_module-wizard">
            <h1>Forhåndsvisning valgt komponent:</h1>
            {this.createEmbedScript(true)}
            <div className="input">
              <h2>Lim inn koden under i ditt nettsted</h2>
              <textarea cols="20" rows="3" value={this.createEmbedScript()} readOnly />
            </div>
            <ButtonReturn onClick={() => this.setState({ preview: false })}>Tilbake</ButtonReturn>
          </div>
        </div>
      );
    }

    return (
      <div className="g_center-content">
        <div className="c_white-box c_module-wizard">
          <h1>Bygg din embedkode</h1>
          <div className="c_module-wizard__choice c_module-wizard__choice--place">
            <h2>1. Velg sted</h2>
            <AreaPicker onChange={this.areaPickerChange} defaultValues={this.state.location} />
          </div>
          <div className="c_module-wizard__choice c_module-wizard__choice--components">
            <h2>2. Hvilke komponenter ønsker du å ha med i innbyggingskoden?</h2>
            <div className="components__item">
              <h3>Kart</h3>
              <figure className="image">
                <img src={EmbedMap} alt="Screenshot av kart" />
                <figcaption>Skjermbilde av hvordan kartet kan se ut.</figcaption>
              </figure>
              {this.state.map
                ? <button type="button" className="button clicked" onClick={() => this.setState({ map: false })}>Kart</button>
                : <button type="button" className="button" onClick={() => this.setState({ map: true })}>Velg kart</button>}

            </div>

            <div className="components__item">
              <h3>Graf</h3>
              <figure className="image">
                <img src={EmbedPlots} alt="Screenshot av kart" />
                <figcaption>Skjermbilde av hvordan grafen kan se ut.</figcaption>
              </figure>
              {this.state.plots
                ? <button type="button" className="button clicked" onClick={() => this.setState({ plots: false })}>Graf</button>
                : <button type="button" className="button" onClick={() => this.setState({ plots: true })}>Velg graf</button>}
            </div>

            <div className="components__item">
              <h3>Luftkvalitetsvarsel</h3>
              <figure className="image">
                <img src={EmbedWarning} alt="Screenshot av automatisk varsling" />
                <figcaption>Skjermbilde av hvordan automatisk varsling kan se ut.</figcaption>
              </figure>
              {this.state.pollution
                ? <button type="button" className="button clicked" onClick={() => this.setState({ pollution: false })}>Luftkvalitetsvarsel</button>
                : <button type="button" className="button" onClick={() => this.setState({ pollution: true })}>Velg luftkvalitetsvarsel</button>}
            </div>
          </div>

          <div className="c_module-wizard__choice c_module-wizard__choice--screensize">
            <h2>3. Skjermstørrelser</h2>
            <div className="input">
              <label htmlFor="height">Høyde (i pixler)</label>
              <input type="text" id="height" defaultValue={this.state.height} onChange={e => this.setState({ height: e.target.value })} />
            </div>
            <p>
              Skriv inn hvor høy du vil ha iframen din. Den vil uansett fylle hele bredden der du legger den inn. Om det ikke blir nok plass i høyden, vil du få en scrollbar for se resten av innholdet. For enkelt å justere høyden, så kan du endre tallet der det står
              {' '}
              <code>height="900px"</code>
              {' '}
              i koden.
            </p>
          </div>

          <div className="c_module-wizard__choice c_module-wizard__choice--show-code">
            {this.validateSelection() && <ButtonPrimary onClick={() => this.setState({ preview: true })}>Vis innbyggingskode</ButtonPrimary>}
          </div>

        </div>
      </div>
    );
  }
}

export default ModuleWizard;
