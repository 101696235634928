import LocalizedStrings from 'react-localization';
import { getCookieLanguage } from 'js/CookieHandler';

const Localization = new LocalizedStrings({
  'no-nb': {
    mostVisited: 'Mine mest besøkte målestasjoner',
    nearbyPlaces: 'Steder',
    nearbyType: 'Type',
    nearbyNow: 'Nå',
    nearbyNoData: 'Fant ingen data.',
    measuredTableComponent: 'Komponent',
    measuredTableTimemean: 'Timesmiddel',
    measuredTableValue: 'Verdi',
    measuredTableDaymean: 'Døgnmiddel',
    measuredTableNoData: 'Fant ingen data.',
    source: 'Kilde: Miljødirektoratet, Statens Vegvesen, kommuner og Nilu. Lisens: Norsk lisens for offentlige data (NLOD).',
    statusStatus: 'Status',
    statusOwner: 'Eiet av',
    statusSource: 'Kilde',
    statusSources: 'Miljødirektoratet, Statens Vegvesen, kommuner og Nilu.',
    statusLicense: 'Lisens',
    statusLicensed: 'Norsk lisens for offentlige data (NLOD).',
    aboutTable: 'Målt luftforurensning forrige hele time gruppert på forurensningsstoff',
  },
  en: {
    mostVisited: 'My most visited measurement stations',
    nearbyPlaces: 'Places',
    nearbyType: 'Type',
    nearbyNow: 'Now',
    nearbyNoData: 'Could not load data.',
    measuredTableComponent: 'Component',
    measuredTableTimemean: 'Time mean',
    measuredTableValue: 'Value',
    measuredTableDaymean: 'Day mean',
    measuredTableNoData: 'Could not load data.',
    source: 'Source: Norwegian Environment Agency, Norwegian Public Roads Administration, municipalities and Norwegian Institute for Air Research. License: Norwegian Licence for Open Government Data (NLOD).',
    statusStatus: 'Status',
    statusOwner: 'Owner',
    statusSource: 'Source',
    statusSources: 'Norwegian Environment Agency, Norwegian Public Roads Administration, municipalities and Norwegian Institute for Air Research.',
    statusLicense: 'License',
    statusLicensed: 'Norwegian Licence for Open Government Data (NLOD).',
    aboutTable: 'Measured air pollution previous hour grouped per contaminant',
  },
});

export const setLanguage = () => {
  const lan = getCookieLanguage();
  Localization.setLanguage(lan);
  return lan;
};

export default Localization;
