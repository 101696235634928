import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './TwoDayAirQuality.scss';
import RestOfDayLabel from 'components/forecast/tables/two-day-air-quality/RestOfDayLabel';
import Localization, {
  setLanguage,
} from 'languages/components/forecast/ForecastLan';

class TwoDayAirQuality extends Component {
  constructor(props) {
    super(props);
    this.language = setLanguage();

    this.state = {
      showRestOfDay: this.props.tableData[0].restOfDay.startHour > 0,
    };
  }

  render() {
    const { startHour, endOfDay } = this.props.tableData[0].restOfDay;
    return (
      // Tabellen som vises på forsiden, to dagers varsel
      <table
        className="table table--two-day-air-quality table--status"
        cellSpacing="0"
      >
        <thead>
          <tr>
            <th>{Localization.twoDayPlaces}</th>
            <th>{Localization.twoDayNow}</th>
            {this.state.showRestOfDay
              ? (
                <RestOfDayLabel
                  language={this.language}
                  startHour={startHour}
                  endOfDay={endOfDay}
                />

              )
              : ''
            }
            <th>{Localization.twoDayTomorrow}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.tableData.map(data => (
            <tr key={data.city.name}>
              <td
                itemProp="contentLocation"
                itemScope
                itemType="http://schema.org/AdministrativeArea"
              >
                <Link
                  to={`/varsling/${data.city.county}/${data.city.name}`}
                  itemProp="name"
                  content={data.city.name}
                >
                  {data.city.name}
                </Link>
              </td>
              <td className="table__status-indicator">
                <span style={{ backgroundColor: data.today.color }} />
                {`${data.today.text}`}
              </td>

              {this.state.showRestOfDay
                ? (
                  <td className="table__status-indicator">
                    <span style={{ backgroundColor: data.restOfDay.color }} />
                    {`${data.restOfDay.text}`}
                  </td>
                )
                : ''}

              <td className="table__status-indicator">
                <span style={{ backgroundColor: data.tomorrow.color }} />
                {`${data.tomorrow.text}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default TwoDayAirQuality;
