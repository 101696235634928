import React from 'react';
import './Font.scss';

const Font = () => (
  <div className="c_styleguide__item c_styleguide__item--font">
    <h2>Fonter</h2>
    <p>Fonten som er brukt heter Open Sans. Den lastes inn med Google Fonts. Brødteksten på siden er 14px.</p>
    <span className="separator-line" />
    <h4>Fontstørrelser i artikkelmalen</h4>
    <h1 style={{ fontSize: '28px', fontWeight: 'normal' }}>Overskrift 1: 28px, font-weight: normal</h1>
    <h2 style={{ fontSize: '24px', fontWeight: '600' }}>Overskrift 2, 24px, font-weight: 600</h2>
    <h3 style={{ fontSize: '20px', fontWeight: '600' }}>Overskrift 3, 20px, font-weight: 600</h3>
    <h4 style={{ fontSize: '16px', fontWeight: '600' }}>Overskrift 4, 16px, font-weight: 600</h4>
    <span className="separator-line" />
    <p>
      Lenker inne i en tekst har blå
      {' '}
      <a href="#">farge og understrek</a>
      .
    </p>
  </div>
);

export default Font;
